import * as React from 'react';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IUserListElement } from '../types/IUserListElement';
import Dropdown from 'react-bootstrap/Dropdown';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';

class UsersListTableConfigurator {
  public getTableColumns = (
    items: IComponentTable<IUserListElement>,
    openCreditorsAssignmentModal: (userUId: string, fullName: string) => void,
    openConfirmChangeUserLockStatus: (selectedUser: IUserListElement) => void,
    openGroupUsersAssignmentModal: (userUId: string, username: string) => void,
    openResetPasswordModal: (selectedUser: IUserListElement) => void,
    openDeleteUserModal: (selectedUser: IUserListElement) => void,
    openRolesAssignmentModal: (userUId: string, fullName: string) => void
  ) => {
    let columns: IComponentTableColumn[] = [];
    if (items != null) {
      columns = items.columns;
      this.configureDateColumns(columns);
      this.configureIsLockedColumn(columns);
      this.configureActions(
        columns,
        openCreditorsAssignmentModal,
        openConfirmChangeUserLockStatus,
        openGroupUsersAssignmentModal,
        openResetPasswordModal,
        openDeleteUserModal,
        openRolesAssignmentModal
      );

      return columns;
    }

    return columns;
  };

  private configureDateColumns = (columns: IComponentTableColumn[]) => {
    const dateColumns = ['createDate'];
    const cols = columns.filter((col) => dateColumns.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      if (value !== null && value !== '') {
        return DateTimeParser.toLocaleDateTimeString(value);
      }
      return '';
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private configureIsLockedColumn = (columns: IComponentTableColumn[]) => {
    const formatterFunc = (value: any, row: IUserListElement) => {
      if (value !== null && value !== '') {
        return (
          <span className={`badge ${row.isLocked ? 'badge-danger' : 'badge-success'}`}>
            {TranslationService.translateModule(row.isLocked ? 'Locked' : 'Unlocked', ModuleNamesList.PortalUsersList)}
          </span>
        );
      }
      return '';
    };

    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'isLocked');
    if (column) {
      column.headerStyle = () => {
        return { width: '90px' };
      };
      column.formatter = formatterFunc;
    }
  };

  private configureActions = (
    columns: IComponentTableColumn[],
    openCreditorsAssignmentModal: (userUId: string, fullName: string) => void,
    openConfirmChangeUserLockStatus: (selectedUser: IUserListElement) => void,
    openGroupUsersAssignmentModal: (userUId: string, username: string) => void,
    openResetPasswordModal: (selectedUser: IUserListElement) => void,
    openDeleteUserModal: (selectedUser: IUserListElement) => void,
    openRolesAssignmentModal: (userUId: string, fullName: string) => void
  ) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
    if (column) {
      column.headerStyle = () => {
        return { width: '90px' };
      };
      column.formatter = (value: any, item: IUserListElement) =>
        this.actionsRowData(
          value,
          item,
          openCreditorsAssignmentModal,
          openConfirmChangeUserLockStatus,
          openGroupUsersAssignmentModal,
          openResetPasswordModal,
          openDeleteUserModal,
          openRolesAssignmentModal
        );
    }
  };

  private actionsRowData = (
    value: any,
    item: IUserListElement,
    openCreditorsAssignmentModal: (userUId: string, fullName: string) => void,
    openConfirmChangeUserLockStatus: (selectedUser: IUserListElement) => void,
    openGroupUsersAssignmentModal: (userUId: string, username: string) => void,
    openResetPasswordModal: (selectedUser: IUserListElement) => void,
    openDeleteUserModal: (selectedUser: IUserListElement) => void,
    openRolesAssignmentModal: (userUId: string, fullName: string) => void
  ) => {
    return (
      <Dropdown alignRight className="button-table">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {TranslationService.translateModule('Actions', ModuleNamesList.PortalUsersList)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              openCreditorsAssignmentModal(item.uId, item.firstname + ' ' + item.lastname);
            }}
          >
            <i className="fas fa-user-tie" />{' '}
            {TranslationService.translateModule('ActionCreditorsAssignment', ModuleNamesList.PortalUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openRolesAssignmentModal(item.uId, item.firstname + ' ' + item.lastname);
            }}
          >
            <i className="fas fa-address-card" />{' '}
            {TranslationService.translateModule('ActionRolesAssignment', ModuleNamesList.PortalUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openGroupUsersAssignmentModal(item.uId, item.firstname + ' ' + item.lastname);
            }}
          >
            <i className="fas fa-user-friends" />
            {' ' + TranslationService.translateModule('ActionAssignGroupUsers', ModuleNamesList.PortalUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openConfirmChangeUserLockStatus(item);
            }}
          >
            <i className={item.isLocked ? 'fas fa-lock' : 'fas fa-lock-open'} />
            {' ' +
              TranslationService.translateModule(
                item.isLocked ? 'ActionUnLockUser' : 'ActionLockUser',
                ModuleNamesList.PortalUsersList
              )}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openResetPasswordModal(item);
            }}
          >
            <i className="fas fa-key" />{' '}
            {TranslationService.translateModule('ActionResetPassword', ModuleNamesList.PortalUsersList)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              openDeleteUserModal(item);
            }}
          >
            <i className="fas fa-trash-alt" />{' '}
            {TranslationService.translateModule('DeleteUser', ModuleNamesList.PortalUsersList)}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

export default new UsersListTableConfigurator();
