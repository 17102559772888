import * as React from 'react';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { IStandardSearch } from '../types/IStandardSearch';
import RadioSelectBoxes from './RadioSelectBoxes';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { DebtorType } from '../types/DebtorType';

interface IProps {
  searchValues: IStandardSearch;
  updateStandardSearch: (value: IStandardSearch) => void;
}

export class StandardSearchForm extends React.Component<IProps> {
  private debtorTypes: INumberDictionary<string> = {
    [DebtorType.Private]: TranslationService.translateModule(
      'DebtorInfoDebtorTypePrivate',
      ModuleNamesList.CreateNewCase
    ),
    [DebtorType.Business]: TranslationService.translateModule(
      'DebtorInfoDebtorTypeBuisness',
      ModuleNamesList.CreateNewCase
    ),
    [DebtorType.All]: TranslationService.translateModule('DebtorInfoDebtorTypeAll', ModuleNamesList.CreateNewCase),
  };

  public render() {
    return (
      <>
        <div className="row mb-2">
          <div className="col-6">
            <label className="col-form-label">
              {TranslationService.translateModule('DebtorInfoModalFirstName', ModuleNamesList.CreateNewCase)}
            </label>
            <SimpleInput
              id="firstname"
              className="form-control"
              onChange={(value) => {
                this.props.updateStandardSearch({ ...this.props.searchValues, firstname: value });
              }}
              value={this.props.searchValues.firstname}
            />
          </div>
          <div className="col-6">
            <label className="col-form-label">
              {TranslationService.translateModule('DebtorInfoModalLastName', ModuleNamesList.CreateNewCase)}
            </label>
            <SimpleInput
              id="lastName"
              className="form-control"
              onChange={(value) => {
                this.props.updateStandardSearch({ ...this.props.searchValues, lastName: value });
              }}
              value={this.props.searchValues.lastName}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <label className="col-form-label">
              {TranslationService.translateModule('DebtorInfoModalIdentity', ModuleNamesList.CreateNewCase)}
            </label>
            <SimpleInput
              id="identity"
              className="form-control"
              onChange={(value) => {
                const isNumber = /^\d+$/.test(value);
                if (isNumber || value === '') {
                  this.props.updateStandardSearch({ ...this.props.searchValues, identity: value });
                }
              }}
              value={this.props.searchValues.identity}
            />
          </div>
          <div className="col-6">
            <label className="col-form-label">
              {TranslationService.translateModule('DebtorInfoModalDomainId', ModuleNamesList.CreateNewCase)}
            </label>
            <SimpleInput
              id="domainId"
              className="form-control"
              onChange={(value) => {
                this.props.updateStandardSearch({ ...this.props.searchValues, domainId: value });
              }}
              value={this.props.searchValues.domainId}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <label className="col-form-label">
              {TranslationService.translateModule('DebtorInfoHeaderSelectDebtorType', ModuleNamesList.CreateNewCase)}
            </label>
            <RadioSelectBoxes
              elements={this.debtorTypes}
              value={this.props.searchValues.debtorType.toString()}
              onChange={(value) => {
                this.props.updateStandardSearch({ ...this.props.searchValues, debtorType: parseInt(value, 0) });
              }}
              name="searchDebtorType"
            />
          </div>
        </div>
      </>
    );
  }
}
