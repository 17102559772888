import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LocalBootstrapTable } from '../../core/components/BootstrapTable/RemoteBootstrapTable';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import InfoMessageService from '../../core/services/InfoMessageService';
import RouteParamsService from '../../core/services/RouteParamsService';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import InvoiceDetailsMessagesTableConfigurator from './helpers/InvoiceDetailsMessagesTableConfigurator';
import InvoiceDetailsMessagesService from './services/InvoiceDetailsMessagesService';
import { IInvoiceDetailsMessageItemIndexed } from './types/IInvoiceDetailsMessageItem';
import TranslationService from '../../core/services/TranslationService';
import { NewMessageModal } from './components/NewMessageModal';
import UserIdentityService from '../../core/services/UserIdentityService';
import { PermissionNames } from '../../core/helpers/PermissionNames';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';

export const InvoiceDetailsMessages = (props: IExtendedModuleProps) => {
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<IComponentTable<IInvoiceDetailsMessageItemIndexed>>({
    columns: [],
    values: [],
  });
  const [hasAccessToModule, setHasAccessToModule] = useState(true);
  const userIdentityService = new UserIdentityService();

  const module = flatModuleOptions(props.module);

  const fetchInvoiceDetailsMessages = async () => {
    setIsLoading(true);
    try {
      const result = await InvoiceDetailsMessagesService.getInvoiceDetailsMessages({
        moduleInstanceId: props.module.id,
        accountNo: props.accountNo,
        creditorNo: props.creditorNo,
        invoiceNo: props.invoiceNo,
      });

      setDataTable(result);
      setIsInitialDataLoaded(true);
      setHasAccessToModule(true);
    } catch {
      setHasAccessToModule(false);
    }

    setIsLoading(false);
  };

  const addNewMessage = async (msg: string) => {
    const result = await InvoiceDetailsMessagesService.addNewInvoiceMessage({
      moduleInstanceId: props.module.id,
      accountNo: props.accountNo,
      creditorNo: props.creditorNo,
      invoiceNo: props.invoiceNo,
      message: msg,
    });

    InfoMessageService.displayActionStatus(result);
  };

  const handleMessageSubmit = (msg: string) => {
    const handle = async () => {
      await addNewMessage(msg);
      await fetchInvoiceDetailsMessages();
    };

    handle();
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const load = async () => {
      await fetchInvoiceDetailsMessages();
    };

    load();
    // eslint-disable-next-line
  }, [props.routeParameters]);

  if (!hasAccessToModule) return <NoPermissionsComponent />;

  const hasAccessToAddNewMessage = userIdentityService.hasPermission(PermissionNames.CanAddInvoiceMessage);
  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Loader opacity={1} isLoaded={!isLoading} />

      {isInitialDataLoaded && dataTable.columns.length > 0 && (
        <article className="idm">
          <LocalBootstrapTable
            keyField="index"
            wrapperClasses="bt"
            classes="bt__table bt-table idm-table"
            data={dataTable.values}
            columns={InvoiceDetailsMessagesTableConfigurator.getColumns(dataTable)}
          />

          {hasAccessToAddNewMessage && (
            <section className="idm__btn-container">
              <Button
                id="addNewMessageBtn"
                type="button"
                label={TranslationService.translateModule('AddNewMessage', props.module.name)}
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTON_SIZE.MD}
                icon={{ className: 'fas fa-plus', position: '' }}
                onClick={openModal}
              />
            </section>
          )}

          <NewMessageModal
            isOpen={isModalOpen}
            addNewMessage={handleMessageSubmit}
            onClose={onModalClose}
            maxMessageLength={module.MaxMessageLength}
          />
        </article>
      )}
    </>
  );
};

const mapRouteParamsToProps = () => {
  const routeParams = RouteParamsService.getQueryParameters();
  return {
    accountNo: routeParams.accountNo as string,
    creditorNo: routeParams.creditorNo as string,
    invoiceNo: routeParams.invoiceNo as string,
  };
};

export default connect(mapRouteParamsToProps)(InvoiceDetailsMessages);
