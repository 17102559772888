import TimePicker from 'antd/lib/time-picker';
import { Field } from 'formik';
import React from 'react';
import ScheduleReportService from '../ScheduleReportService';
import DatePicker from './../../../core/components/Forms/DatePicker';
import TranslationService from './../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import moment from 'moment';

interface IProps {
  data: any;
  values: IValues;
  parameters: any;
  handleChange: (data: any) => void;
}

interface IState {
  repeatAt: any;
  isLoaded: boolean;
}

interface IValues {
  startTime: string;
  startTimeHours: string;
  startTimeMinutes: string;
}

class Once extends React.Component<IProps, IState> {
  state: IState = {
    repeatAt: '',
    isLoaded: false,
  };

  componentDidMount = () => {
    this.setTimePicker();
  };

  render() {
    const { startTime } = this.props.values;

    return (
      <>
        {this.state.isLoaded && (
          <>
            <div className="c-creator__box">
              <div className="row">
                <div className="col-8">
                  <div className="c-control">
                    <Field name="startTime" validate={this.validationDate}>
                      {({ field, form }: any) => (
                        <div className="form-group row row--aligned">
                          <label className={'col-form-label col-12 col-sm-4 '} htmlFor={field.name}>
                            {TranslationService.translateModule('When', ModuleNamesList.ScheduleReports)}
                          </label>

                          <div className="col-12 col-sm-8 icon-calendar default-form c-control">
                            <DatePicker
                              onChange={(date) => {
                                this.handleDateChange(date, field.name);
                              }}
                              inputId={field.name}
                              inputName={field.name}
                              value={startTime ? new Date(startTime) : ''}
                            />
                          </div>

                          {form.errors[field.name] && form.touched[field.name] && (
                            <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-4">
                  <div className="c-control">
                    <TimePicker format={'HH:mm'} onChange={this.handleTimeChange} defaultValue={this.state.repeatAt} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  setTimePicker = () => {
    const { startTimeHours, startTimeMinutes } = this.props.values;
    const computedTime = ScheduleReportService.setTimePicker(startTimeHours, startTimeMinutes);

    this.handleTimeChange(computedTime);

    this.setState({
      repeatAt: computedTime,
      isLoaded: true,
    });
  };

  validationDate = (value: any) => {
    let error;

    if (!value) {
      error = TranslationService.translate('ValidationMessageRequiredField');
    }

    return error;
  };

  handleDateChange = (data: any, name: string) => {
    this.props.handleChange({
      name: name,
      value: data,
    });
  };

  handleTimeChange = (time: any) => {
    const timeArr = [
      {
        name: 'startTimeHours',
        value: moment(time).hour(),
      },
      {
        name: 'startTimeMinutes',
        value: moment(time).minute(),
      },
    ];

    for (let i = 0; i < timeArr.length; i++) {
      const time = timeArr[i];

      this.props.handleChange({
        name: time.name,
        value: time.value,
      });
    }
  };
}

export default Once;
