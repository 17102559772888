import { IEditableInvoiceListElement } from '../types/InvoiceElement';

export const isPaymentDateValid = (invoices: IEditableInvoiceListElement[], date: Date): boolean => {
  if (!invoices.length) {
    return true;
  }
  const sortedDates = invoices
    .filter((i) => i.regDate)
    .map((i) => i.regDate)
    .sort((a, b) => a!.valueOf() - b!.valueOf());
  if (sortedDates.length) {
    return sortedDates.length
      ? date >= new Date(sortedDates[0]!) && new Date(date.toDateString()) <= new Date()
      : false;
  }

  return false;
};

export const payedAmountMustNotExceedMaximum = (payedAmount: number, maximumAmount: number): boolean =>
  payedAmount <= maximumAmount;

export const payedAmountIsPositive = (payedAmount: number): boolean => payedAmount > 0;

export const invoiceOrdersAreNotDuplicated = (invoices: IEditableInvoiceListElement[]): boolean => {
  const distinct = (value: number, index: number, self: number[]) => {
    return self.indexOf(value) === index;
  };

  const orders = invoices.map((i) => i.order);
  const distinctOrders = orders.filter(distinct);

  return orders.length === distinctOrders.length;
};

export const validatePayment = (
  invoices: IEditableInvoiceListElement[],
  maxAmount: number,
  payedAmount: number,
  date: Date
): string[] => {
  const errors = [];
  if (!payedAmountIsPositive(payedAmount)) {
    errors.push('PaidAmountMustBePositiveError');
  }
  if (!payedAmountMustNotExceedMaximum(payedAmount, maxAmount)) {
    errors.push('PaidAmountMustNotExceedMaximumError');
  }
  if (!isPaymentDateValid(invoices, date)) {
    errors.push('PaymentDateBetweenLowestInvoiceDateAndTodayError');
  }
  if (!invoiceOrdersAreNotDuplicated(invoices)) {
    errors.push('InvoiceOrderNumbersDuplicatedError');
  }

  return errors;
};
