import React, { useEffect, useState } from 'react';
import TranslationService from '../../core/services/TranslationService';
import DateTimeParser from 'core/helpers/DateTimeParser';
import { CaseSummary } from './types/CaseSummary';
import { CaseSummaryModule } from './types/CaseSummaryModule';

type Props = {
  caseSummary: CaseSummary;
  module: CaseSummaryModule;
};

export const CaseSummaryInfo = (props: Props) => {
  const [caseNumbers, setCaseNumbers] = useState<string[]>([props.caseSummary.caseNumber]);

  useEffect(() => {
    let caseNumber: string[] = [];

    if (props.caseSummary.caseNumber && props.caseSummary.caseNumber.length) {
      caseNumber.push(props.caseSummary.caseNumber);
    }

    if (props.caseSummary.refNumber && props.caseSummary.refNumber.length) {
      caseNumber.push(props.caseSummary.refNumber);
    }

    setCaseNumbers(caseNumber);
  }, [props.caseSummary]);

  return (
    <>
      <section className="case-summary__info">
        <div className="c-table c-table--col-2">
          <div className="c-table__row case-summary__row case-summary__row--font-1">
            <div className="c-table__cell font--color-1 font--700">
              {TranslationService.translateModule('CaseNoRefNo', props.module.name)}
            </div>
            <div id="caseSummaryCaseNo" className="c-table__cell color-1 font--color-2 font--700">
              {caseNumbers.join('/')}
            </div>
          </div>

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell font--color-1 font--700">
              {TranslationService.translateModule('Status', props.module.name)}
            </div>
            <div className="c-table__cell font--color-3 font--700">{props.caseSummary.status}</div>
          </div>

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell">{TranslationService.translateModule('RegDate', props.module.name)}</div>
            <div className="c-table__cell">
              {props.caseSummary.registrationDate &&
                DateTimeParser.toLocaleDateString(props.caseSummary.registrationDate)}
            </div>
          </div>

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell">{TranslationService.translateModule('CloseDate', props.module.name)}</div>
            <div className="c-table__cell">
              {props.caseSummary.closeDate && DateTimeParser.toLocaleDateString(props.caseSummary.closeDate)}
            </div>
          </div>

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell">{TranslationService.translateModule('Creditor', props.module.name)}</div>
            <div className="c-table__cell font--color-1 font--700">{props.caseSummary.creditorName}</div>
          </div>

          {props.module.IsCreditorRefNoVisible && props.module.IsCreditorRefNoVisible.toString() === 'true' && (
            <div className="c-table__row case-summary__row">
              <div className="c-table__cell">
                {TranslationService.translateModule('CreditorRef', props.module.name)}
              </div>
              <div className="c-table__cell">{props.caseSummary.creditorRef}</div>
            </div>
          )}

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell">{TranslationService.translateModule('Invoices', props.module.name)}</div>
            <div className="c-table__cell">{props.caseSummary.invoicesNumber}</div>
          </div>

          {props.module.IsCasehandlerVisible && props.module.IsCasehandlerVisible.toString() === 'true' && (
            <div className="c-table__row case-summary__row">
              <div className="c-table__cell">
                {TranslationService.translateModule('CaseHandler', props.module.name)}
              </div>
              <div className="c-table__cell">{props.caseSummary.caseHandler}</div>
            </div>
          )}

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell font--color-1 font--700">
              {TranslationService.translateModule('AccountNo', props.module.name)}
            </div>
            <div className="c-table__cell font--color-3 font--700">{props.caseSummary.accountNumber}</div>
          </div>

          <div className="c-table__row case-summary__row">
            <div className="c-table__cell font--color-1 font--700">
              {TranslationService.translateModule('Kid', props.module.name)}
            </div>
            <div className="c-table__cell font--color-3 font--700">{props.caseSummary.kid}</div>
          </div>
        </div>
      </section>
    </>
  );
};
