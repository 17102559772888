import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { ExternalLoginProviderType } from './types/ExternalLoginProviderType';
import { IModuleProps } from '../../core/types/IModuleProps';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import AddNewProviderModal from './components/AddNewProviderModal';
import EditProviderModal from './components/EditProviderModal';
import InfoMessageService from '../../core/services/InfoMessageService';
import Loader from '../../core/components/Loading/Loader';
import Modal from '../../core/components/Modal/Modal';
import { ModalType, emptyModalType } from './types/ModalType';
import React from 'react';
import TranslationService from '../../core/services/TranslationService';
import ExternalProviderApiClient from './api/ExternalProviderApiClient';
import { IComponentTable, emptyComponentTable } from '../../core/types/IComponentTable';
import LoginProvidersTableConfigurator from './helpers/LoginProvidersTableConfigurator';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import { Status } from 'core/api/Enums/Status';
import ConfirmationModal from '../../core/components/ConfirmationModal/ConfirmationModal';
import { LoginProviderType } from './types/LoginProviderType';

type State = {
  isLoading: boolean;
  providersTable: IComponentTable<ExternalLoginProviderType>;
  providerTypes: LoginProviderType[];
  editModal: ModalType;
  deleteModal: ModalType;
  isNewModalOpened: boolean;
};

const emptyState: State = {
  isLoading: false,
  providersTable: { ...emptyComponentTable },
  providerTypes: [],
  editModal: { ...emptyModalType },
  deleteModal: { ...emptyModalType },
  isNewModalOpened: false,
};

export default class ExternalLoginProviders extends React.Component<IModuleProps, State> {
  public state: State = { ...emptyState };

  public getExternalLoginProviders = async () => {
    this.setState({ isLoading: true });
    const result = await ExternalProviderApiClient.getExternalLoginProviders(this.props.module.id);
    this.setState({
      isLoading: false,
      providersTable: { ...result.providersTable },
      providerTypes: [...result.providersTypes],
    });
  };

  public handleDeleteProvider = async () => {
    if (this.state.deleteModal && this.state.deleteModal.data) {
      const result = await ExternalProviderApiClient.deleteExternalLoginProvider(
        this.state.deleteModal.data.loginProvider
      );
      if (result.status === Status.Success) {
        InfoMessageService.success(
          TranslationService.translateModule('ProviderDeleteSuccess', ModuleNamesList.ExternalLoginProviders)
        );
        this.setState({ deleteModal: { ...emptyModalType } }, async () => await this.getExternalLoginProviders());
      }
    }
  };

  public handleEditProvider = async (dataProvider: ExternalLoginProviderType) => {
    const result = await ExternalProviderApiClient.saveExternalLoginProvider(dataProvider, this.props.module.id);
    if (result.status === Status.Success) {
      InfoMessageService.success(
        TranslationService.translateModule('ProviderEditSuccess', ModuleNamesList.ExternalLoginProviders)
      );
      this.setState({ editModal: { ...emptyModalType } }, async () => await this.getExternalLoginProviders());
    }
  };

  public handleAddProvider = async (dataProvider: ExternalLoginProviderType) => {
    const result = await ExternalProviderApiClient.saveExternalLoginProvider(dataProvider, this.props.module.id);
    if (result.status === Status.Success) {
      InfoMessageService.success(
        TranslationService.translateModule('ProviderAddSuccess', ModuleNamesList.ExternalLoginProviders)
      );
      this.setState({ isNewModalOpened: false }, async () => await this.getExternalLoginProviders());
    }
  };

  public componentDidMount = async () => {
    await this.getExternalLoginProviders();
  };
  public render() {
    return (
      <article className="l-module">
        <section className="l-module__section l-module__section--head">
          <h1>
            {TranslationService.translateModule('TitleExternalLoginProviders', ModuleNamesList.ExternalLoginProviders)}
          </h1>
        </section>

        <section className="l-module__section l-module__section--filter mb-3">
          <Button
            type="button"
            id="externalLoginProvidersAdd"
            className="mr-2"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTON_SIZE.SM}
            label={TranslationService.translateModule(
              'NewExternalLoginProviderBtn',
              ModuleNamesList.ExternalLoginProviders
            )}
            onClick={() => this.setState({ isNewModalOpened: true })}
          />
        </section>

        <section className="l-module__section mt-3">
          {this.state.isLoading ? (
            <Loader opacity={1} />
          ) : (
            <BootstrapTableContainer
              remote={false}
              paginationProps={{
                hideSizePerPage: true,
              }}
              wrapperClasses="bt"
              classes="bt__table bt__table--decor-1 bt-table bt--small bt--selectable"
              keyField="clientId"
              data={this.state.providersTable.values}
              columns={LoginProvidersTableConfigurator.getColumns(
                this.state.providersTable.columns,
                (modalType) => this.setState({ editModal: modalType }),
                (modalType) => this.setState({ deleteModal: modalType })
              )}
            />
          )}
        </section>

        <Modal
          isOpen={this.state.isNewModalOpened}
          onClose={() => this.setState({ isNewModalOpened: false })}
          modalData={{
            headerText: `${TranslationService.translateModule(
              'TitleExternalLoginProviders',
              ModuleNamesList.ExternalLoginProviders
            )}: ${TranslationService.translate('New')}`,
            content: (
              <AddNewProviderModal
                providerTypes={this.state.providerTypes}
                onClose={() => this.setState({ isNewModalOpened: false })}
                onSubmit={this.handleAddProvider}
              />
            ),
          }}
        />

        <Modal
          isOpen={this.state.editModal.isOpen}
          onClose={() => this.setState({ editModal: { ...emptyModalType } })}
          modalData={{
            headerText: `${TranslationService.translateModule(
              'TitleExternalLoginProviders',
              ModuleNamesList.ExternalLoginProviders
            )}: ${TranslationService.translate('Edit')}`,
            content: (
              <EditProviderModal
                data={this.state.editModal.data}
                onClose={() => this.setState({ editModal: { ...emptyModalType } })}
                onSubmit={this.handleEditProvider}
              />
            ),
          }}
        />

        <ConfirmationModal
          isOpen={this.state.deleteModal.isOpen}
          message={TranslationService.translateModule('DeleteModalText', ModuleNamesList.ExternalLoginProviders)}
          onCancel={() => this.setState({ deleteModal: { ...emptyModalType } })}
          onOk={this.handleDeleteProvider}
        />
      </article>
    );
  }
}
