import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { GetInvoiceDetailsNotesRequest } from '../types/GetInvoiceDetailsNotesRequest';
import { InvoiceDetailsNoteItem } from '../types/InvoiceDetailsNoteItem';

class InvoiceDetailsNotesApi {
  public async getInvoiceDetailsNotes(
    request: GetInvoiceDetailsNotesRequest
  ): Promise<IComponentTable<InvoiceDetailsNoteItem>> {
    return await RestApiClient.post('modules/InvoiceDetailsNotesModule', request);
  }
}

export default new InvoiceDetailsNotesApi();
