import React from 'react';
import { SettingsType } from '../../Enums/SettingsType';
import SimpleInput from './SimpleInput';
import SimpleTextArea from './SimpleTextArea';
import { SimpleSelect } from './SimpleSelect';
import { IDictionary, INumberDictionary } from '../../types/IDictionary';
import { ISelectedValue } from './types/ISelectedValue';
import ColorPickerInput from './inputs/ColorPickerInput';
import FileStringInput from './FileStringInput';

type Props = {
  type: SettingsType;
  id: string;
  value?: any;
  name?: string;
  eventTypeChange?: boolean;
  disabled?: boolean;
  options?: IDictionary<any> | INumberDictionary<any>;
  onChange: (val: any) => void;
  onBlur?: (val: any) => void;
  onKeyUp?: (e: any) => void;
  onFocus?: () => void;
};

export const InputContainer = (props: Props) => {
  const getInputComponent = () => {
    switch (props.type) {
      case SettingsType.Select:
        return (
          <SimpleSelect
            id={props.id}
            emptyFirstRow={true}
            name={props.name}
            disabled={props.disabled}
            value={props.value ? props.value.toString() : undefined}
            onChangeHandle={(selectedItem: ISelectedValue) => {
              props.eventTypeChange
                ? props.onChange({
                    target: { id: props.id, name: props.name ? props.name : props.id, value: selectedItem.key },
                  })
                : props.onChange(selectedItem.key);
            }}
            options={props.options}
          />
        );
      case SettingsType.Bool:
        return (
          <label className="c-switch-input">
            <input
              type="checkbox"
              id={props.id}
              name={props.name}
              value={props.value.toString()}
              checked={props.value.toString() === 'true'}
              onChange={(e) => {
                props.onChange(!(e.target.value === 'true'));
              }}
            />
            <div className="c-switch-input__switch"></div>
          </label>
        );
      case SettingsType.ColorPicker:
        return (
          <ColorPickerInput
            name={props.name || ''}
            value={props.value}
            handleChange={(value) => {
              props.onChange(value);
            }}
          />
        );
      case SettingsType.File:
        return (
          <>
            <FileStringInput
              id={props.id}
              name={props.name || ''}
              value={props.value}
              onChange={(value) => {
                props.onChange(value);
              }}
            />
          </>
        );
      case SettingsType.TextArea:
        return (
          <SimpleTextArea
            id={props.id}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
            onFocus={props.onFocus}
            onChange={(value) => {
              props.onChange(value);
            }}
            onBlur={(value) => {
              if (props.onBlur) {
                props.onBlur(value);
              }
            }}
            onKeyUp={(e) => {
              if (props.onKeyUp) {
                props.onKeyUp(e);
              }
            }}
          />
        );
      default:
        return (
          <SimpleInput
            id={props.id}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
            onFocus={props.onFocus}
            onChange={(value) => {
              props.onChange(value);
            }}
            onBlur={(value) => {
              if (props.onBlur) {
                props.onBlur(value);
              }
            }}
            onKeyUp={(e) => {
              if (props.onKeyUp) {
                props.onKeyUp(e);
              }
            }}
          />
        );
    }
  };

  return getInputComponent();
};
