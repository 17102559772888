import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import * as yup from 'yup';
import DatePicker from '../../core/components/Forms/DatePicker';
import React, { useState, useEffect } from 'react';
import RestApiClient from '../../core/api/RestApiClient';
import RouteParamsService from '../../core/services/RouteParamsService';
import TranslationService from '../../core/services/TranslationService';
import { SimulatedBalanceType } from './types/SimulatedBalanceType';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import PermissionsApiClient from 'core/api/PermissionsApiClient';
import Loader from '../../core/components/Loading/Loader';

type PropsType = {
  caseId: string;
};

const SimulatedBalance = (props: PropsType) => {
  const [estimatedDate, setEstimatedDate] = useState<Date>();
  const [hasAccessToModule, setHasAccessToModule] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [simulatedBalance, setSimulatedBalance] = useState<string>('0');
  const validationSchema = yup.object().shape({
    estimatedDate: yup.string().required(TranslationService.translate('ValidationMessageRequiredField')),
  });

  const handleSubmitForm = async () => {
    setIsLoading(true);
    const { simulatedAmount } = await RestApiClient.post<SimulatedBalanceType>(
      'modules/SimulatedBalance/calculateBalance',
      { caseNumber: props.caseId, date: estimatedDate }
    );
    setSimulatedBalance(simulatedAmount.toFixed(2));
    setIsLoading(false);
  };

  const validationDate = (value: any) => {
    let error;

    if (!value) {
      error = TranslationService.translate('ValidationMessageRequiredField');
    }

    return error;
  };

  const checkAccessModule = async () => {
    setIsLoading(true);
    const hasAccess = await PermissionsApiClient.hasAccessToCase(props.caseId);
    setHasAccessToModule(hasAccess);
    setIsLoading(false);
  };

  useEffect(() => {
    checkAccessModule();
    // eslint-disable-next-line
  }, []);

  return (
    <article className="l-box l-box--relative">
      {!hasAccessToModule ? (
        <NoPermissionsComponent />
      ) : (
        <>
          {isLoading && <Loader opacity={0.5} />}
          <div className="l-box__head">
            <h1>{TranslationService.translateModule('SimulatedBalanceHeader', ModuleNamesList.SimulatedBalance)}</h1>
          </div>

          <div className="l-box__body">
            <ul className="c-widget c-widget-list">
              <li className="c-widget-list__item">
                <Formik
                  onSubmit={handleSubmitForm}
                  validationSchema={validationSchema}
                  initialValues={{
                    estimatedDate: '',
                  }}
                >
                  {({ values, handleChange }) => (
                    <Form className="simulated-balance__form">
                      <div className="row align-items-center">
                        <div className="col-4">
                          {TranslationService.translateModule('EstimatedDate', ModuleNamesList.SimulatedBalance)}
                        </div>

                        <div className="col-4">
                          <Field name="estimatedDate" validate={validationDate}>
                            {({ field, form }: any) => (
                              <>
                                <DatePicker
                                  inputId={field.name}
                                  inputName={field.name}
                                  value={values.estimatedDate}
                                  className="simulated-balance__date-picker"
                                  disabledDays={{
                                    before: new Date(),
                                  }}
                                  onChange={(value: Date) => {
                                    const handleChangeEvent = {
                                      target: {
                                        value,
                                        id: field.name,
                                        name: field.name,
                                      },
                                    } as React.ChangeEvent<any>;

                                    handleChange(handleChangeEvent);
                                    setEstimatedDate(value);
                                  }}
                                />

                                {form.errors[field.name] && form.touched[field.name] && (
                                  <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                                )}
                              </>
                            )}
                          </Field>
                        </div>

                        <div className="col-3">
                          <Button
                            type="submit"
                            id="simulateBtn"
                            variant={BUTTON_VARIANTS.PRIMARY}
                            size={BUTTON_SIZE.SM}
                            label={TranslationService.translateModule('Simulate', ModuleNamesList.SimulatedBalance)}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </li>
              <li className="c-widget-list__item">
                {TranslationService.translateModule('BalancePrincipal', ModuleNamesList.SimulatedBalance)}
                <span>{simulatedBalance}</span>
              </li>
            </ul>
          </div>
        </>
      )}
    </article>
  );
};

const mapStateToProps = () => {
  const queryparams = RouteParamsService.getQueryParameters();

  return {
    caseId: queryparams.id,
  };
};

export default connect(mapStateToProps)(SimulatedBalance);
