import RestApiClient from '../../../core/api/RestApiClient';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { GetInvoiceDetailsMessagesRequest } from '../types/GetInvoiceDetailsMessagesRequest';
import { IInvoiceDetailsMessageItemIndexed, IInvoiceDetailsMessageItem } from '../types/IInvoiceDetailsMessageItem';
import { IActionStatus } from '../../../core/api/types/IActionStatus';
import { AddNewInvoiceMessageRequest } from '../types/AddNewInvoiceMessageRequest';

class InvoiceDetailsMessagesApi {
  public async getInvoiceDetailsMessages(
    request: GetInvoiceDetailsMessagesRequest
  ): Promise<IComponentTable<IInvoiceDetailsMessageItemIndexed>> {
    const result = await RestApiClient.post<IComponentTable<IInvoiceDetailsMessageItem>>(
      'modules/InvoiceDetailsMessagesModule',
      request
    );

    return {
      ...result,
      values: result.values.map((item: IInvoiceDetailsMessageItem, index: number) => ({
        ...item,
        index,
        from: item.webUserId ? item.webUserId : item.caseHandler,
      })),
    };
  }

  public async addNewInvoiceMessage(request: AddNewInvoiceMessageRequest): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>('modules/InvoiceDetailsMessagesModule/addNewMessage', request);
  }
}

export default new InvoiceDetailsMessagesApi();
