import * as React from 'react';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Button, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { IUserProperty } from '../types/IUserProperty';

class ManageUserPropsTableConfigurator {
  public getTableColumns = (removeUserProperty: (id: number) => void) => {
    let columns: IComponentTableColumn[] = this.configureColumns([], removeUserProperty);
    return columns;
  };

  private configureColumns = (columns: IComponentTableColumn[], removeUserProperty: (id: number) => void) => {
    columns.push({
      dataField: 'propertyDefinitionDescription',
      sort: false,
      text: TranslationService.translateModule('UserPropsTableDescription', ModuleNamesList.ManageUserProperties),
    });
    columns.push({
      dataField: 'value',
      sort: false,
      text: TranslationService.translateModule('UserPropsTableValue', ModuleNamesList.ManageUserProperties),
    });
    columns.push({
      dataField: 'actions',
      sort: false,
      text: TranslationService.translateModule('UserPropsTableActions', ModuleNamesList.ManageUserProperties),
      headerStyle: () => {
        return { width: '90px' };
      },
      formatter: (value: any, item: IUserProperty, rowIndex: number) =>
        this.actionsRowData(value, item, rowIndex, removeUserProperty),
    });
    return columns;
  };

  private actionsRowData = (
    value: any,
    item: IUserProperty,
    rowIndex: number,
    removeUserProperty: (id: number) => void
  ) => {
    return item.isRemoved ? (
      'To remove'
    ) : (
      <Button
        type="button"
        icon={{ className: 'fas fa-trash', position: 'left' }}
        variant={BUTTON_VARIANTS.DANGER}
        onClick={() => removeUserProperty(rowIndex)}
      />
    );
  };
}

export default new ManageUserPropsTableConfigurator();
