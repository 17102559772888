import TimePicker from 'antd/lib/time-picker';
import { Field } from 'formik';
import React from 'react';
import Checkbox from '../../../core/components/Forms/Checkbox';
import CheckboxGroup from '../../../core/components/Forms/CheckboxGroup';
import ScheduleReportService from '../ScheduleReportService';
import DatePicker from './../../../core/components/Forms/DatePicker';
import TranslationService from './../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import moment from 'moment';

interface IValues {
  startTime: string;
  stopTime: string;
  startTimeHours: string;
  startTimeMinutes: string;
  weekDayNumbers: any[];
}

interface IProps {
  data: any;
  values: IValues;
  handleChange: (data: any) => void;
}

interface IState {
  weekDayNumbers: any[];
  repeatAt: any;
  isLoaded: boolean;
}

class Weekly extends React.PureComponent<IProps, IState> {
  public state: IState = {
    weekDayNumbers: [],
    repeatAt: {},
    isLoaded: false,
  };

  componentDidMount = () => {
    this.setTimePicker();
    this.prepareDate();
  };

  render() {
    const { startTime, stopTime, weekDayNumbers } = this.props.values;

    return (
      <>
        {this.state.isLoaded && (
          <>
            <section className="c-creator__box">
              <div className="row">
                <div className="col-9">
                  <div className="c-control">
                    <Field name="startTime" validate={this.validationDate}>
                      {({ field, form }: any) => (
                        <div className="form-group row row--aligned">
                          <label className={'col-form-label col-12 col-sm-4 '} htmlFor={field.name}>
                            {TranslationService.translateModule('StartDate', ModuleNamesList.ScheduleReports)}
                          </label>

                          <div className="col-12 col-sm-8 icon-calendar default-form c-control">
                            <DatePicker
                              onChange={(date) => {
                                this.onChangeDateHandler(date, field.name);
                              }}
                              inputId={field.name}
                              inputName={field.name}
                              value={startTime ? new Date(startTime) : ''}
                            />
                          </div>

                          {form.errors[field.name] && form.touched[field.name] && (
                            <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-3">
                  <div className="c-control">
                    <TimePicker format={'HH:mm'} onChange={this.handleTimeChange} defaultValue={this.state.repeatAt} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-9">
                  <div className="c-control">
                    <Field name="stopTime">
                      {({ field, form }: any) => (
                        <div className="form-group row row--aligned">
                          <label className={'col-form-label col-12 col-sm-4 '} htmlFor={field.name}>
                            {TranslationService.translateModule('EndDate', ModuleNamesList.ScheduleReports)}
                          </label>

                          <div className="col-12 col-sm-8 icon-calendar default-form c-control">
                            <DatePicker
                              onChange={(date) => {
                                this.onChangeDateHandler(date, field.name);
                              }}
                              inputId={field.name}
                              inputName={field.name}
                              value={stopTime ? new Date(stopTime) : ''}
                            />
                          </div>

                          {form.errors[field.name] && form.touched[field.name] && (
                            <div className="col-12 c-input-error">{form.errors[field.name]}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </section>

            <section className="c-creator__box">
              <h1 className="c-creator__box-title">
                {TranslationService.translateModule('WeeklyLabelRepeatWeekDays', 'ScheduleReport')}
              </h1>

              <CheckboxGroup
                id="weekDayNumbers"
                value={weekDayNumbers}
                error={[]}
                touched={[]}
                onChange={this.handleChangeWeekDays}
              >
                {this.props.data.weekDays.map((day: any, index: string) => (
                  <Field
                    key={index}
                    component={Checkbox}
                    name="weekDayNumbers"
                    value={day.id}
                    id={day.name}
                    label={TranslationService.translate(day.name)}
                  />
                ))}
              </CheckboxGroup>
            </section>
          </>
        )}
      </>
    );
  }

  handleChangeWeekDays = (valueArray: []) => {
    this.props.handleChange({
      name: 'weekDayNumbers',
      value: valueArray,
    });
  };

  setTimePicker = () => {
    const { startTimeHours, startTimeMinutes } = this.props.values;
    const computedTime = ScheduleReportService.setTimePicker(startTimeHours, startTimeMinutes);

    this.handleTimeChange(computedTime);

    this.setState({
      repeatAt: moment().hour(parseInt(startTimeHours)).minute(parseInt(startTimeMinutes)),
      isLoaded: true,
    });
  };

  onChangeDateHandler = (date: any, name: string) => {
    this.props.handleChange({
      name: name,
      value: date,
    });
  };

  validationDate = (value: any) => {
    let error;

    if (!value) {
      error = TranslationService.translate('ValidationMessageRequiredField');
    }

    return error;
  };

  prepareDate = () => {
    const selectedWeekDays = this.props.data.weekDays
      .filter((weekDay: IWeekDays) => {
        return weekDay.selected ? weekDay.id : null;
      })
      .map((weekDay: IWeekDays) => {
        return weekDay.id;
      });

    this.handleChangeWeekDays(selectedWeekDays);
  };

  handleTimeChange = (time: any) => {
    const timeArr = [
      {
        name: 'startTimeHours',
        value: moment(time).hour(),
      },
      {
        name: 'startTimeMinutes',
        value: moment(time).minute(),
      },
    ];

    for (let i = 0; i < timeArr.length; i++) {
      const time = timeArr[i];

      this.props.handleChange({
        name: time.name,
        value: time.value,
      });
    }
  };
}

interface IWeekDays {
  id: number;
  name: string;
  selected: boolean;
}

export default Weekly;
