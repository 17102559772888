import React, { useEffect, useState, useRef } from 'react';
import { ErrorMessage } from 'formik';
import { INumberDictionary, IDictionary } from '../../types/IDictionary';
import TranslationService from '../../../core/services/TranslationService';
import { ISelectedValue } from './types/ISelectedValue';
import classNames from 'classnames';

enum SimpleSelectVarianstType {
  Primary = 'primary',
  Secondary = 'secondary',
  Link = 'link',
}

interface ISimpleSelectProps {
  multiple?: boolean;
  variant?: SimpleSelectVarianstType;
  emptyFirstRow?: boolean;
  emptyFirstRowText?: string;
  emptyFirstRowValue?: string;
  enableFilter?: boolean;
  isNotStandard?: boolean;
  name?: string;
  onBlur?: ((name: string | undefined) => void) | undefined;
  onChange?: (value: string, name: string | undefined) => void | undefined;
  onChangeHandle: (selectedValue: ISelectedValue) => void;
  options: INumberDictionary<string | number> | IDictionary<string | number> | any;
  value?: string;
  id: string;
  disabled?: boolean;
  selected?: any;
  alwaysSelected?: boolean;
  classNames?: string;
}

const SimpleSelect = (props: ISimpleSelectProps) => {
  const node = useRef<HTMLInputElement>(null);
  const [inputVal, setInputValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = useState<any>([]);

  const handleClick = (e: MouseEvent) => {
    if (node.current && node.current.contains(e.target as Node)) {
      return;
    }

    if (props.onBlur) {
      props.onBlur(props.name);
    }

    setOpen(false);
  };

  const handleChange = (
    selectedValue: ISelectedValue,
    preventChange: boolean = false,
    clearInputValues: boolean = false
  ) => {
    if (props.multiple) {
      let result = [];

      if (clearInputValues) {
        result = [selectedValue.key];
      } else if (inputValues.includes(selectedValue.key)) {
        if (selectedValue.value === 'Any' && selectedValue.key === '0') {
          result = [inputValues[1]];
        } else {
          if (props.alwaysSelected && inputValues.length === 1) {
            result = [...inputValues];
          } else {
            result = inputValues.filter((v: any) => {
              if (v === '0') {
                return false;
              }

              return v !== selectedValue.key;
            });
          }
        }
      } else {
        if (selectedValue.value === 'Any' || selectedValue.key === '0') {
          result = props.options.map((o: any) => o.key);
        } else {
          result = [...inputValues, selectedValue.key];
        }
      }

      if (!preventChange) {
        props.onChangeHandle({
          key: result.join(),
          value: '',
        });
      }

      setInputValues(result);
    } else {
      props.onChangeHandle({ value: selectedValue.value, key: selectedValue.key });
      setInputValue(selectedValue.value);
      setOpen(false);
    }

    if (props.onChange && !preventChange) {
      props.onChange(selectedValue.key, props.name);
    }

    setFilterValue('');
  };

  const handleOnClickLabel = (disabled?: boolean) => {
    function isEmpty(obj: any) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }

    if (Array.isArray(props.options) && props.options.length === 0) {
      return;
    } else {
      if (isEmpty(props.options)) {
        return;
      }
    }

    if (!disabled) {
      if (node.current) {
        const dropdownList = node.current.getElementsByClassName('c-select__options')[0] as HTMLElement;
        const dropdownListRect = dropdownList.getBoundingClientRect();
        const dropdownRect = node.current.getBoundingClientRect();

        if (window.innerHeight < dropdownRect.top + dropdownListRect.height) {
          dropdownList.style.top = 'initial';
          dropdownList.style.bottom = '100%';
        }
      }

      setOpen(!open);
    }
  };

  useEffect(() => {
    if (props.multiple) {
      if (props.selected) {
        handleChange(
          {
            key: props.selected,
            value: '',
          },
          true,
          true
        );
      }
    }
    // eslint-disable-next-line
  }, [props.selected]);

  useEffect(() => {
    if (props.value !== undefined) {
      if (Array.isArray(props.options) && props.options.length > 0) {
        const valueSelected = props.options.filter((option: ISelectedValue) => {
          return option.key === props.value;
        });

        if (valueSelected[0]) {
          setInputValue(valueSelected[0].value);
        }
      } else {
        if (props.emptyFirstRow && props.value === props.emptyFirstRowValue) {
          setInputValue(props.emptyFirstRowText || TranslationService.translate('ChooseValue'));
        } else {
          setInputValue(props.options[props.value]);
        }
      }
    } else if (props.emptyFirstRow) {
      setInputValue(props.emptyFirstRowText || TranslationService.translate('ChooseValue'));
    } else {
      setInputValue('');
    }
    // eslint-disable-next-line
  }, [props.value, props.options]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <div
      ref={node}
      id={props.id}
      className={classNames(
        'c-select',
        open && 'c-select--open',
        props.variant && `c-select--${props.variant}`,
        props.classNames
      )}
    >
      <select
        className={`form-control ${props.isNotStandard ? '' : 'standard'}`}
        id={props.id + '-select'}
        value={props.value}
        onChange={(event) => {
          props.onChangeHandle({ value: event.target.value, key: event.target.name });
          if (props.onChange) {
            props.onChange(event.target.value, event.target.name);
          }
        }}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur(props.name);
          }
        }}
        disabled={props.disabled}
        name={props.name}
      >
        {props.emptyFirstRow && (
          <option value={props.emptyFirstRowValue ? props.emptyFirstRowValue : ''}>
            {props.emptyFirstRowText ? props.emptyFirstRowText : TranslationService.translate('ChooseValue')}
          </option>
        )}

        {props.options && typeof props.options === 'object' && props.options.constructor === Object
          ? Object.getOwnPropertyNames(props.options).map((key: string) => {
              return (
                <option key={key} value={key}>
                  {props.options[key]}
                </option>
              );
            })
          : props.options.map((option: any, index: number) => {
              return (
                <option key={index} value={option.code}>
                  {option.name}
                </option>
              );
            })}
      </select>

      <p
        className={`c-select__current ${props.disabled ? 'c-select__current--disabled' : ''}`}
        onClick={() => {
          handleOnClickLabel(props.disabled);
        }}
      >
        {props.multiple
          ? inputValues.length
            ? `${inputValues.length} ${TranslationService.translate('Selected')}`
            : TranslationService.translate('ChooseValue')
          : inputVal || TranslationService.translate('ChooseValue')}
      </p>

      {props.name && <ErrorMessage name={props.name} component="div" className="error error-message" />}

      <div className={`c-select__content ${open ? 'c-select__content--open' : ''}`}>
        {props.enableFilter && (
          <div className="c-select__filter">
            <input
              type="text"
              className="form-control"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </div>
        )}

        <ul className={`c-select__options`} id={props.id}>
          {props.emptyFirstRow && (
            <li
              className="c-select__options-item"
              data-rel={props.emptyFirstRowText ? props.emptyFirstRowText : ''}
              onClick={() =>
                handleChange({
                  key: props.emptyFirstRowValue ? props.emptyFirstRowValue : 'all',
                  value: props.emptyFirstRowText
                    ? props.emptyFirstRowText
                    : TranslationService.translate('ChooseValue'),
                })
              }
            >
              {props.emptyFirstRowText ? props.emptyFirstRowText : TranslationService.translate('ChooseValue')}
            </li>
          )}

          {props.options && typeof props.options === 'object' && props.options.constructor === Object ? (
            Object.getOwnPropertyNames(props.options).map((key: string, index: number) => {
              const option = props.options[key].toLowerCase();

              return (
                option.includes(filterValue.toLowerCase()) && (
                  <li
                    key={index}
                    className={classNames('c-select__options-item', inputValues.indexOf(option.key) > -1 && 'selected')}
                    data-rel={props.options[key]}
                    onClick={() => handleChange({ key, value: props.options[key] })}
                  >
                    {props.options[key]}

                    {props.multiple &&
                      (inputValues.indexOf(key) > -1 ? (
                        <i className="far fa-check-square" />
                      ) : (
                        <i className="far fa-square" />
                      ))}
                  </li>
                )
              );
            })
          ) : (
            <>
              {props.options
                .filter((x: ISelectedValue) =>
                  filterValue ? x.value.toLowerCase().includes(filterValue.toLowerCase()) : true
                )
                .map((option: ISelectedValue, index: number) => {
                  return (
                    <li
                      key={index}
                      className={classNames(
                        'c-select__options-item',
                        inputValues.indexOf(option.key) > -1 && 'selected'
                      )}
                      onClick={() => handleChange(option)}
                    >
                      {option.value}

                      {props.multiple &&
                        (inputValues.indexOf(option.key) > -1 ? (
                          <i className="far fa-check-square" />
                        ) : (
                          <i className="far fa-square" />
                        ))}
                    </li>
                  );
                })}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export { SimpleSelect, SimpleSelectVarianstType };
