import React from 'react';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import { IBaseCreditorDetails } from '../types/IBaseCreditorDetails';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IListInputElement } from '../../../core/components/Forms/types/IListInputElement';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import { Status } from 'core/api/Enums/Status';
import ConfirmationModal from '../../../core/components/ConfirmationModal/ConfirmationModal';
import CreditorsAssignmentApiClient from '../CreditorsAssignmentApiClient';
import ExistingCreditorsTableConfigurator from '../helpers/ExistingCreditorsTableConfigurator';
import InfoMessageService from 'core/services/InfoMessageService';
import ListInputs from '../../../core/components/Forms/ListInputs';
import Loader from '../../../core/components/Loading/Loader';
import TranslationService from '../../../core/services/TranslationService';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

interface IProps {
  availableCreditorsRoles: IListInputElement[];
  creditorsTable: IComponentTable;
  userUId: string;
  removeCreditorAssignment(creditorDomainIds: string[]): Promise<void>;
}

interface IState {
  confrmModalText: string;
  isConfirmModalOpened: boolean;
  isEditModeEnabled: boolean;
  isLoading: boolean;
  selectedCreditors: IBaseCreditorDetails[];
  selectedRoles: string[];
}

class ManageCreditors extends React.Component<IProps> {
  state: IState = {
    confrmModalText: '',
    isConfirmModalOpened: false,
    isEditModeEnabled: false,
    isLoading: false,
    selectedCreditors: [],
    selectedRoles: [],
  };

  render() {
    return (
      <>
        {this.state.isLoading && <Loader opacity={0.6} />}

        <div className="c-assignment-box">
          <div className="c-assignment-box__header">
            <h6 className="mt-2 mb-1 font-weight-bold">
              {TranslationService.translateModule('ManageCreditors', ModuleNamesList.CreditorsAssignment)}
            </h6>
          </div>

          <div className="c-assignment-box__body">
            <div className="c-assignment-box__body-item c-assignment-box__body-item--table">
              {this.props.creditorsTable.columns.length > 0 && (
                <BootstrapTableContainer
                  id="assignedCreditorsTable"
                  remote={false}
                  paginationProps={{
                    hideSizePerPage: true,
                  }}
                  wrapperClasses="bt c-assignment-box__table-wrapper"
                  classes="bt__table bt__table--decor-1 bt-table bt-table--visible-on-hover-button bt--small bt--selectable"
                  keyField="domainId"
                  selectRow={{
                    hideSelectColumn: true,
                    mode: 'checkbox',
                    classes: 'bt bt-table bt-table__row bt-table__row--selected',
                    clickToSelect: true,
                    onSelect: this.onCreditorSelect,
                    selected: this.state.selectedCreditors.map((item) => item.domainId),
                  }}
                  data={this.props.creditorsTable.values}
                  columns={ExistingCreditorsTableConfigurator.getTableColumns(this.props.creditorsTable)}
                />
              )}
            </div>

            <div className="c-assignment-box__body-item">
              <ListInputs
                col={2}
                listInputs={this.props.availableCreditorsRoles}
                allInputsSelected={false}
                selectedInputs={this.state.selectedRoles}
                markWithActiveClass={true}
                disabledOverflowEnabled={!this.state.isEditModeEnabled}
                onSelect={this.onSelectHandler}
              />
            </div>
          </div>

          <div className="c-assignment-box__footer">
            <div className="row">
              <div className="col-auto mr-auto">
                <Button
                  id="clearAssignedSelectionBtn"
                  label={TranslationService.translateModule('ClearSelection', ModuleNamesList.CreditorsAssignment)}
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTON_SIZE.MD}
                  onClick={this.clearSelection}
                />
              </div>

              <div className="col-auto">
                <Button
                  id="updateSelectionBtn"
                  label={TranslationService.translateModule('UpdateCreditor', ModuleNamesList.CreditorsAssignment)}
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTON_SIZE.MD}
                  disabled={!this.state.isEditModeEnabled}
                  onClick={this.updateCreditor}
                />

                <Button
                  id="removeCreditorBtn"
                  className="ml-2 d-md-none"
                  label={`${TranslationService.translateModule(
                    'RemoveCreditors',
                    ModuleNamesList.CreditorsAssignment
                  )} (${this.state.selectedCreditors.length})`}
                  variant={BUTTON_VARIANTS.DANGER}
                  size={BUTTON_SIZE.MD}
                  disabled={!this.state.selectedCreditors.length}
                  onClick={this.showConfirmModal}
                />
              </div>
            </div>
          </div>
        </div>

        <ConfirmationModal
          isOpen={this.state.isConfirmModalOpened}
          onCancel={() => this.setState({ isConfirmModalOpened: false })}
          onOk={this.removeSelectedCreditors}
          message={this.state.confrmModalText}
        />
      </>
    );
  }

  onSelectHandler = (selectedRoles: IListInputElement) => {
    let stateToUpdate: any = [];
    const defaultValues: any[] = [...this.state.selectedRoles];

    if (defaultValues.indexOf(selectedRoles.key as any) !== -1) {
      const defaultValuesFiltered = defaultValues.filter((item) => item !== selectedRoles.key);

      stateToUpdate = defaultValuesFiltered;
    } else {
      stateToUpdate = [...this.state.selectedRoles, selectedRoles.key];
    }

    this.setState({
      selectedRoles: stateToUpdate,
    });
  };

  showConfirmModal = () => {
    if (this.state.selectedCreditors.length === 0) {
      InfoMessageService.warning(
        TranslationService.translateModule('ValidationSelectCreditorToRemove', ModuleNamesList.CreditorsAssignment)
      );
    } else {
      this.setState({
        isConfirmModalOpened: true,
        confrmModalText:
          TranslationService.translateModule(
            'ConfirmUnAssignCreditorsModalContent',
            ModuleNamesList.CreditorsAssignment
          ) +
          ' ' +
          this.state.selectedCreditors.map((item) => item.domainId).join(', '),
      });
    }
  };

  updateCreditor = async () => {
    if (this.state.isEditModeEnabled) {
      if (this.state.selectedRoles.length === 0) {
        InfoMessageService.warning(
          TranslationService.translateModule(
            'ValidationSelectAtLeastOneRoleToUpdateCreditor',
            ModuleNamesList.CreditorsAssignment
          )
        );
      } else {
        this.setState({
          isLoading: true,
        });
        try {
          const updateCreditorRolesStatus = await CreditorsAssignmentApiClient.updateCreditorRoles(
            this.props.userUId,
            this.state.selectedCreditors[0].domainId,
            this.state.selectedRoles
          );

          this.setState({
            selectedCreditors: [],
            selectedRoles: [],
          });

          if (updateCreditorRolesStatus.status === Status.Success) {
            InfoMessageService.success(
              TranslationService.translateModule('CreditorWasUpdatedSucessfully', ModuleNamesList.CreditorsAssignment)
            );
          } else {
            InfoMessageService.displayActionStatus(updateCreditorRolesStatus);
          }
        } finally {
          this.setState({
            isLoading: false,
          });
        }
      }
    }
  };

  removeSelectedCreditors = async () => {
    this.setState({
      isConfirmModalOpened: false,
      selectedCreditors: [],
      selectedRoles: [],
      isLoading: true,
    });
    const creditorsDomainIds = this.state.selectedCreditors.map((item) => item.domainId);
    await this.props.removeCreditorAssignment(creditorsDomainIds);
    this.setState({
      isLoading: false,
    });
  };

  clearSelection = () => {
    this.setState({
      selectedCreditors: [],
      selectedRoles: [],
    });
  };

  onCreditorSelect = async (element: IBaseCreditorDetails) => {
    let selectedCreditors = this.state.selectedCreditors;
    if (selectedCreditors.includes(element)) {
      selectedCreditors.splice(selectedCreditors.indexOf(element), 1);
    } else {
      selectedCreditors.push(element);
    }

    const isEditModeEnabled = selectedCreditors.length === 1;
    this.setState({
      selectedCreditors,
      isLoading: isEditModeEnabled,
      isEditModeEnabled,
      selectedRoles: [],
    });
    if (isEditModeEnabled) {
      const selectedRoles = await CreditorsAssignmentApiClient.getUserCreditorRoles(
        selectedCreditors[0].domainId,
        this.props.userUId
      );
      const selectedRolesUids = selectedRoles.map((item) => item.uId);

      this.setState({
        selectedRoles: this.props.availableCreditorsRoles
          .filter((item) => selectedRolesUids.includes(item.key))
          .map((item) => item.key),
        isLoading: false,
      });
    }
  };
}

export default ManageCreditors;
