import * as React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
import TranslationService from '../../core/services/TranslationService';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import Loader from '../../core/components/Loading/Loader';
import CaseStatisticsPortfolioAmountsApiClient from './CaseStatisticsPortfolioAmountsApiClient';
import RoundedBlockChart from './components/RoundedBlockChart';
import { IRoundedBlockData } from './types/IRoundedBlockData';
import './_CaseStatisticsPortfolioAmounts.scss';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';

interface IProps extends IModuleProps, AbortRequestPropsType {
  locale: string;
}

interface IState {
  data: IRoundedBlockData[];
  loading: boolean;
}

class CaseStatisticsPortfolioAmounts extends React.Component<IProps, IState> {
  public state: IState = {
    loading: false,
    data: [],
  };
  private moduleEvents: IModuleEventSubscription[];

  public componentDidMount = async () => {
    this.registerModuleEvents();
    await this.loadData();
  };

  public componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  }

  public loadData = async () => {
    this.setState({
      loading: true,
    });
    const data = await CaseStatisticsPortfolioAmountsApiClient.GetData(
      this.props.module.id,
      this.props.cancelTokenSource.token
    );
    this.setState({
      loading: false,
      data,
    });
  };

  public render() {
    return (
      <div className="module-box">
        <div className="module-box__content">
          <header
            className="module-box__header"
            dangerouslySetInnerHTML={{
              __html: TranslationService.translateModule('ChartHeader', ModuleNamesList.CaseStatisticsPortfolioAmounts),
            }}
          />
          <div className="module-box-content">
            {this.state.loading ? <Loader /> : <RoundedBlockChart data={this.state.data} />}
          </div>
        </div>
      </div>
    );
  }

  private registerModuleEvents() {
    this.moduleEvents = [{ name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.loadData }];
    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }
}
export default withAbortRequest(CaseStatisticsPortfolioAmounts);
