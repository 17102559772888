import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import DatePicker from '../../../core/components/Forms/DatePicker';
import { FormFieldset } from '../../../core/components/Forms/FormFieldset';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import SimpleCheckbox from '../../../core/components/Forms/SimpleCheckbox';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { numberOnlyRegex } from '../../../core/helpers/utils';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { productTypesOrdered } from '../helpers/productTypesOrdered';
import { CustomerTransactionProductType } from '../types/CustomerTransactionProductType';
import { CustomerTransactionsSelectFilter } from '../types/CustomerTransactionsSelectFilter';
import { ICustomerTransactionsListFilters } from '../types/ICustomerTransactionsListFilters';
import { ICustomerTransactionsWithLockFilters } from '../types/ICustomerTransactionsWithLockFilters';
import { OverdueState } from '../types/OverdueState';
import InputField from '../../../core/components/InputField/InputField';

type FilterType = {
  initialFilters: ICustomerTransactionsWithLockFilters;
  filterByOptions: INumberDictionary<string>;
  onFilter: (filters: ICustomerTransactionsListFilters) => void;
};

type IState = {
  isFilterExpanded: boolean;
};

const Filter = (props: FilterType) => {
  const [state, setState] = useState<IState>({ isFilterExpanded: false });

  const validationMessages = {
    required: TranslationService.translate('ValidationMessageRequiredField'),
    incorrectNumberFormat: TranslationService.translate('ValidationMessageIncorrectNumberFormat'),
    incorrectAccountNumber: TranslationService.translateModule(
      'ValidationMessageIncorrectNumberFormat',
      ModuleNamesList.InvoicesAgeAnalysis
    ),
    toBigAccountNumber: TranslationService.translateModule(
      'ValidationAccountNumberTooBig',
      ModuleNamesList.InvoicesAgeAnalysis
    ),
  };

  const validationSchema = yup.object().shape({
    invoiceNoMin: yup.number().typeError(validationMessages.incorrectNumberFormat),
    invoiceNoMax: yup.number().typeError(validationMessages.incorrectNumberFormat),
    filterByValue: yup.string().when('filteredBy', (val: string, schema: any) => {
      const mappedValue: any = CustomerTransactionsSelectFilter[val];
      if (mappedValue === CustomerTransactionsSelectFilter.AccountNumber) {
        return schema
          .required(validationMessages.required)
          .test('accNumber', validationMessages.incorrectAccountNumber, (value: any) => numberOnlyRegex.test(value))
          .test(
            'accNumberLength',
            validationMessages.toBigAccountNumber,
            (value: any) => numberOnlyRegex.test(value) && parseInt(value) <= Math.pow(2, 31) - 1
          );
      } else if (val === undefined || val === 'all') {
        return schema.notRequired();
      }

      return schema.required(validationMessages.required);
    }),
  });

  const getProductOptions = () => {
    const options = {};
    productTypesOrdered.forEach((value, idx) => {
      options[idx + 1] = TranslationService.translateModule(
        `CustomerTransactionProductType_${CustomerTransactionProductType[value]}`,
        ModuleNamesList.CustomerTransactionsList
      );
    });

    return options;
  };

  const onHandleChange = (value: Date | boolean | string | undefined, name: string, handleChange: any) => {
    const handleChangeEvent = {
      target: {
        id: name,
        name: name,
        value: value,
      },
    } as React.ChangeEvent<any>;

    handleChange(handleChangeEvent);
  };

  const buildEmptyFilters = (): ICustomerTransactionsWithLockFilters => ({
    errors: [],
    includeFullyPaid: false,
    invoiceNoMax: undefined,
    invoiceNoMin: undefined,
    isAccountNoLocked: props.initialFilters.isAccountNoLocked,
    filteredBy: props.initialFilters.isAccountNoLocked ? CustomerTransactionsSelectFilter.AccountNumber : undefined,
    filterByValue: props.initialFilters.isAccountNoLocked ? props.initialFilters.filterByValue : undefined,
    overdueState: undefined,
    regDateMax: undefined,
    regDateMin: undefined,
    product: props.initialFilters.product
      ? (productTypesOrdered.indexOf(parseInt(props.initialFilters.product as string)) + 1).toString()
      : undefined,
  });

  const [formInitialVals, setFormInitialVals] = useState<ICustomerTransactionsWithLockFilters>(buildEmptyFilters());

  useEffect(() => {
    setFormInitialVals({
      ...props.initialFilters,
      filteredBy: props.initialFilters.isAccountNoLocked ? CustomerTransactionsSelectFilter.AccountNumber : undefined,
      filterByValue: props.initialFilters.isAccountNoLocked ? props.initialFilters.filterByValue : undefined,
      product: props.initialFilters.product
        ? (productTypesOrdered.indexOf(parseInt(props.initialFilters.product as string)) + 1).toString()
        : undefined,
    });
  }, [props]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...formInitialVals }}
      onSubmit={(value: ICustomerTransactionsListFilters) => {
        props.onFilter({
          ...value,
          product:
            value.product && value.product !== '0'
              ? productTypesOrdered[parseInt(value.product) - 1].toString()
              : undefined,
        });
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, resetForm, submitForm, validateForm }) => {
        return (
          <Form className="c-form">
            <div className="container container--force">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                  <FormGroup
                    variant={FORM_GROUP_VARIANTS.BLOCK}
                    name="filteredBy"
                    label={TranslationService.translateModule('FilterBy', ModuleNamesList.CustomerTransactionsList)}
                    render={(name) => (
                      <div className="row">
                        <div className="col-6">
                          <SimpleSelect
                            id="customers-transactions-select-filter"
                            name="filteredBy"
                            // eslint-disable-next-line
                            value={values.filteredBy != undefined ? values.filteredBy.toString() : undefined}
                            onChangeHandle={(value) => {
                              if (value.key === 'all') {
                                validateForm();
                              }
                              onHandleChange(value.key, name, handleChange);
                            }}
                            emptyFirstRow={true}
                            options={props.filterByOptions}
                            disabled={props.initialFilters.isAccountNoLocked}
                          />
                        </div>
                        <div className="col-6">
                          <InputField
                            name="filterByValue"
                            value={values.filterByValue || ''}
                            disabled={props.initialFilters.isAccountNoLocked}
                            onChange={handleChange}
                          />

                          <ErrorMessage name="filterByValue" component="div" className="error error-message" />
                        </div>
                      </div>
                    )}
                  />
                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <FormGroup
                    className="br"
                    variant={FORM_GROUP_VARIANTS.BLOCK}
                    name="product"
                    label={TranslationService.translateModule('Product', ModuleNamesList.CustomerTransactionsList)}
                    render={(name) => (
                      <SimpleSelect
                        id="productType"
                        name={name}
                        value={values.product}
                        key="product_simple_select"
                        emptyFirstRow={true}
                        emptyFirstRowText={TranslationService.translate('All')}
                        emptyFirstRowValue={'0'}
                        options={getProductOptions()}
                        onChangeHandle={(selectedValue) => {
                          const value = selectedValue.key !== '0' ? selectedValue.key : '0';

                          return onHandleChange(value, name, handleChange);
                        }}
                      />
                    )}
                  />
                </div>

                <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <FormGroup
                    className="br"
                    variant={FORM_GROUP_VARIANTS.BLOCK}
                    name="overdueState"
                    label={TranslationService.translateModule(
                      'OverdueFilterLabel',
                      ModuleNamesList.CustomerTransactionsList
                    )}
                    render={(name) => (
                      <SimpleSelect
                        id="overdueState"
                        key="overdue_simple_select"
                        name={name}
                        value={values.overdueState ? OverdueState[values.overdueState].toString() : undefined}
                        emptyFirstRow={true}
                        options={{
                          [OverdueState.Any]: TranslationService.translate('Any'),
                          [OverdueState.Yes]: TranslationService.translateModule(
                            'OnlyOverdued',
                            ModuleNamesList.CustomerTransactionsList
                          ),
                          [OverdueState.No]: TranslationService.translateModule(
                            'OnlyNotOverdued',
                            ModuleNamesList.CustomerTransactionsList
                          ),
                        }}
                        onChangeHandle={(value) => onHandleChange(OverdueState[value.key], name, handleChange)}
                      />
                    )}
                  />
                </div>

                <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                  <FormFieldset
                    className="br"
                    label={TranslationService.translateModule('InvoiceNo', ModuleNamesList.CustomerTransactionsList)}
                    render={() => (
                      <div className="row">
                        <div className="col">
                          <FormGroup
                            variant={FORM_GROUP_VARIANTS.INLINE}
                            name="invoiceNoMin"
                            label={TranslationService.translateModule('From', ModuleNamesList.CustomerTransactionsList)}
                            render={(name) => (
                              <>
                                <SimpleInput
                                  id="customerTransactionInvoiceNoFrom"
                                  name={name}
                                  value={values.invoiceNoMin}
                                  onChange={(value) => onHandleChange(value, name, handleChange)}
                                />
                                <ErrorMessage name="invoiceNoMin" component="div" className="error error-message" />
                              </>
                            )}
                          />
                        </div>

                        <div className="col">
                          <FormGroup
                            variant={FORM_GROUP_VARIANTS.INLINE}
                            name="invoiceNoMax"
                            label={TranslationService.translateModule('To', ModuleNamesList.CustomerTransactionsList)}
                            render={(name) => (
                              <>
                                <SimpleInput
                                  id="customerTransactionInvoiceNoTo"
                                  name={name}
                                  value={values.invoiceNoMax}
                                  onChange={(value) => onHandleChange(value, name, handleChange)}
                                />
                                <ErrorMessage name="invoiceNoMax" component="div" className="error error-message" />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>

                {state.isFilterExpanded ? (
                  <>
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                      <FormFieldset
                        className="br"
                        label={TranslationService.translateModule('RegDate', ModuleNamesList.CustomerTransactionsList)}
                        render={() => (
                          <div className="row">
                            <div className="col">
                              <FormGroup
                                variant={FORM_GROUP_VARIANTS.INLINE}
                                name="regDateMin"
                                label="From"
                                render={(name) => (
                                  <DatePicker
                                    inputId="customerTransactionRegDateMin"
                                    inputName={name}
                                    value={values.regDateMin}
                                    onChange={(value) => onHandleChange(value, name, handleChange)}
                                  />
                                )}
                              />
                            </div>

                            <div className="col">
                              <FormGroup
                                variant={FORM_GROUP_VARIANTS.INLINE}
                                name="regDateMax"
                                label={TranslationService.translateModule(
                                  'To',
                                  ModuleNamesList.CustomerTransactionsList
                                )}
                                render={(name) => (
                                  <DatePicker
                                    inputId="customerTransactionRegDateMax"
                                    inputName={name}
                                    value={values.regDateMax}
                                    onChange={(value) => onHandleChange(value, name, handleChange)}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-auto align-self-end">
                      <FormGroup
                        variant={FORM_GROUP_VARIANTS.INLINE}
                        name="includeFullyPaid"
                        render={(name) => (
                          <SimpleCheckbox
                            name={name}
                            displayInline={true}
                            value={values.includeFullyPaid ? ['includeFullyPaid'] : []}
                            options={{
                              includeFullyPaid: TranslationService.translateModule(
                                'IncludeFullyPaid',
                                ModuleNamesList.CustomerTransactionsList
                              ),
                            }}
                            onChange={(values) =>
                              onHandleChange(values.indexOf('includeFullyPaid') > -1, name, handleChange)
                            }
                          />
                        )}
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="row">
                <div className="col-auto mr-auto align-self-end">
                  <Button
                    id="customerTransactionsFilterBtn"
                    className="mr-2"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.SM}
                    label={TranslationService.translateModule('Filter', ModuleNamesList.CustomerTransactionsList)}
                    type="submit"
                  />
                  <Button
                    id="customerTransactionsExpandBtn"
                    variant={BUTTON_VARIANTS.LINK}
                    size={BUTTON_SIZE.SM}
                    type="button"
                    label={TranslationService.translateModule('MoreFilters', ModuleNamesList.CustomerTransactionsList)}
                    onClick={() => setState({ isFilterExpanded: !state.isFilterExpanded })}
                  />
                  <Button
                    id="customerTransactionsClearBtn"
                    variant={BUTTON_VARIANTS.LINK}
                    size={BUTTON_SIZE.SM}
                    type="button"
                    label={TranslationService.translateModule('ClearFilters', ModuleNamesList.CustomerTransactionsList)}
                    onClick={async () => {
                      resetForm();

                      await setFormInitialVals({
                        ...buildEmptyFilters(),
                      });

                      submitForm();
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filter;
