import React from 'react';
import { Form, Formik } from 'formik';
import TranslationService from '../../../../core/services/TranslationService';

import Loader from '../../../../core/components/Loading/Loader';
import ListInputs from '../../../Reports/views/ListInputs';
import SimpleInput from '../../../Reports/views/SimpleInput';

import { IDeliverySettingSetVM } from '../../types/IDeliverySettingSetVM';
import NotificationsProps from './types/IPropsNotifications';
import NotificationState from './types/IStateNotifications';
import IDeliverySftp from './types/IDeliverySftp';
import IListInputs from './types/IListInputs';
import { IScheduleData } from '../../../Reports/types/IScheduleData';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../../core/components/Button/Button';

class ScheduleNotifications extends React.PureComponent<NotificationsProps, NotificationState> {
  state: NotificationState = {
    isLoading: true,
    formData: {} as IScheduleData,
    outputData: {
      ...this.props.data,
      deliverySettings: [],
      copyNotificationTo: '',
      standardNotificationEnabled: false,
    },
    listInputs: [],
    selectedValues: [],
    isShowingSftpOptions: false,
  };

  private dataSftp = [
    {
      name: 'sftpHost',
      prompt: TranslationService.translateModule('ScheduleNotificationsSftpServer', 'ScheduleReport'),
      type: 'text',
    },
    {
      name: 'sftpUser',
      prompt: TranslationService.translateModule('ScheduleNotificationsSftpUser', 'ScheduleReport'),
      type: 'text',
    },
    {
      name: 'sftpPassword',
      prompt: TranslationService.translateModule('ScheduleNotificationsSftpPassword', 'ScheduleReport'),
      type: 'password',
    },
  ];

  componentDidMount = () => {
    const formData = this.formInitData();

    this.prepareListInputs();

    this.setState({
      outputData: {
        ...formData,
      },
      isLoading: false,
    });
  };

  render() {
    return (
      <>
        <div className="c-creator__body">
          {this.state.isLoading ? (
            <Loader opacity={1} />
          ) : (
            this.props.data && (
              <Formik
                initialValues={this.state.outputData}
                onSubmit={(values) => {
                  this.submitFormHandler(values);
                }}
              >
                {({ values, handleChange }) => {
                  return (
                    <Form>
                      <div className="c-creator__box">
                        <p>{TranslationService.translateModule('ScheduleReportOptions', 'ScheduleReport')}</p>

                        <div className="row">
                          <div className="col-6">
                            <ListInputs
                              name="deliverySettings"
                              multiselect={true}
                              listInputs={this.state.listInputs}
                              selectedInputs={this.state.selectedValues}
                              handleChange={(value: any) => {
                                handleChange({
                                  target: {
                                    value: value,
                                    name: 'deliverySettings',
                                    id: 'deliverySettings',
                                  },
                                });
                                this.handleReportOptionsChnage(value);
                              }}
                              onSelect={() => {}}
                            />
                          </div>
                        </div>
                      </div>

                      {this.state.isShowingSftpOptions && (
                        <div className="c-creator__box">
                          <div className="row">
                            <div className="col-6">
                              {this.dataSftp.map((item: IDeliverySftp, index: number) => (
                                <SimpleInput
                                  key={index}
                                  name={item.name}
                                  prompt={item.prompt}
                                  type="text"
                                  dataType={item.type}
                                  value={values[item.name]}
                                  required={true}
                                  onChange={(data: any) => {
                                    handleChange({
                                      target: { value: data.value, name: data.name, id: data.name },
                                    });
                                    this.handleSFTPChange(data);
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      <section className="c-creator__box">
                        <h1 className="c-creator__box-title">
                          {TranslationService.translateModule('ScheduleNotification', 'ScheduleReport')}
                        </h1>

                        <div className="row">
                          <div className="col-6">
                            <div className="c-control-group c-control-group--wraped">
                              <div className="c-control c-control--checkbox">
                                <input
                                  type="checkbox"
                                  name="standardNotificationEnabled"
                                  id="standardNotificationEnabled"
                                  defaultChecked={values.standardNotificationEnabled}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: e.target.checked,
                                        id: e.target.id,
                                      },
                                    });
                                    this.handleNotifyMeChange(e.target.checked);
                                  }}
                                />

                                <label className="c-control__label" htmlFor="standardNotificationEnabled">
                                  {TranslationService.translateModule('ScheduleNotifyMe', 'ScheduleReport')}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div className="c-creator__box">
                        <div className="row">
                          <div className="col-6">
                            <SimpleInput
                              name="copyNotificationTo"
                              prompt={TranslationService.translate('SendNotificationTo')}
                              inputDesc={TranslationService.translate('MultipleEmailsBySemicolon')}
                              type="text"
                              required={this.state.outputData.standardNotificationEnabled}
                              placeholder="Email"
                              value={values.copyNotificationTo}
                              onChange={(data: any) => {
                                handleChange({
                                  target: { value: data.value, name: data.name, id: data.name },
                                });
                                this.handleRecipientCopyChange(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="c-creator__footer c-creator__footer--btn-action">
                        <Button
                          type="button"
                          label={TranslationService.translate('Back')}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          size={BUTTON_SIZE.MD}
                          onClick={this.props.goToPreviousStep}
                        />

                        <Button
                          label={TranslationService.translate('Next')}
                          variant={BUTTON_VARIANTS.PRIMARY}
                          size={BUTTON_SIZE.MD}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )
          )}
        </div>
      </>
    );
  }

  formInitData = () => {
    const { initData, data } = this.props;

    return {
      copyNotificationTo: data.copyNotificationTo || '',
      deliverySettings: data.deliverySettings,
      sftpHost: data.sftpHost || '',
      sftpPassword: data.sftpPassword || '',
      sftpUser: data.sftpUser || '',
      standardNotificationEnabled: data.standardNotificationEnabled || initData.standardNotificationEnabled,
    } as IScheduleData;
  };

  handleNotifyMeChange = (checked: boolean) => {
    this.setState({
      outputData: {
        ...this.state.outputData,
        standardNotificationEnabled: checked,
      },
    });
  };

  handleSFTPChange = ({ name, value }: any) => {
    name = name.charAt(0).toLowerCase() + name.slice(1);

    this.setState({
      outputData: {
        ...this.state.outputData,
        [name]: value,
      },
    });
  };

  handleRecipientCopyChange = ({ name, value }: any) => {
    this.setState({
      outputData: {
        ...this.state.outputData,
        [name]: value,
      },
    });
  };

  handleReportOptionsChnage = (data: any) => {
    this.setState({
      outputData: {
        ...this.state.outputData,
        deliverySettings: [...data],
      },
    });

    this.checkIsSFTP(data);
  };

  submitFormHandler = (values: {}) => {
    if (this.props.goToNextStep) {
      this.props.setScheduleOutputdata({
        ...this.state.outputData,
        ...values,
      });
      this.props.goToNextStep();
    }
  };

  prepareListInputs = () => {
    let selectedValues: number[] = [];
    const data: IListInputs[] = this.props.initData.deliverySettings.map((item: IDeliverySettingSetVM) => {
      if (item.selected) {
        selectedValues.push(item.deliveryType.id);
      }

      return {
        name: item.deliveryType.description,
        value: item.deliveryType.id,
      };
    });

    if (this.props.data.deliverySettings) {
      selectedValues = this.props.data.deliverySettings;
    }

    this.checkIsSFTP(selectedValues);

    this.setState({
      selectedValues,
      listInputs: data,
    });
  };

  checkIsSFTP = (data: number[]) => {
    let isShowingSftpOptions: boolean = false;

    for (let i = 0; i < this.props.initData.deliverySettings.length; i++) {
      const item = this.props.initData.deliverySettings[i];

      if (data.indexOf(item.deliveryType.id) > -1 && item.deliveryType.name === 'SFTP') {
        isShowingSftpOptions = true;
      }
    }

    this.setState({
      isShowingSftpOptions,
    });
  };
}
export default ScheduleNotifications;
