import * as React from 'react';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { DocumentsColumns } from '../types/DocumentsColumns';
import { IDocumentListElement } from '../types/IDocumentsListElement';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';

class DocumentsListTableConfigurator {
  public getDocumentsListTableColumns = (
    DocumentsListTable: IComponentTable<IDocumentListElement>,
    downloadDocument: (documentId: number, caseNumber: string) => Promise<void>
  ) => {
    let columns: IComponentTableColumn[] = [];
    if (DocumentsListTable != null) {
      columns = [...DocumentsListTable.columns.map((c) => ({ ...c, sort: true }))];
      this.configureDateColumns(columns);
      this.addDownloadColumn(columns, downloadDocument);

      return columns;
    }

    return columns;
  };

  private configureDateColumns = (columns: IComponentTableColumn[]) => {
    const dateColumns = [DocumentsColumns.Date];
    const currencyColumnsNames = dateColumns.map(
      (col) => DocumentsColumns[col].charAt(0).toLowerCase() + DocumentsColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return DateTimeParser.toLocaleDateString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private addDownloadColumn = (
    columns: IComponentTableColumn[],
    downloadDocument: (documentId: number, caseNumber: string) => Promise<void>
  ) => {
    const formatterFunc = (value: any, row: IDocumentListElement) => {
      return (
        <div onClick={async () => await downloadDocument(row.id, row.caseNumber)} className="btn-download">
          <i className="fas fa-file-download" />
          {row.isDownloading && (
            <div className="ml-1 spinner-border spinner-border-sm text-success" role="status">
              <span className="sr-only" />
            </div>
          )}
        </div>
      );
    };

    columns.push({
      dataField: 'download',
      formatter: formatterFunc,
      sort: false,
      headerClasses: (col: any, colIndex: number) => 'bt-table__header bt-table__header--text-center',
      classes: (cell: any, row: any, rowIndex: number, colIndex: number) =>
        'bt-table__cell bt-table__cell--text-center',
      style: (cell: any, row: any, rowIndex: number, colIndex: number) => undefined,
      headerStyle: (col: any, colIndex: number) => undefined,
      text: TranslationService.translateModule('Download', ModuleNamesList.DocumentsList),
    } as IComponentTableColumn);
  };
}

export default new DocumentsListTableConfigurator();
