import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IInvoiceListElement } from '../types/IInvoiceListElement';
import { InvoicesColumns, InvoicesPaymentsColumns } from '../types/InvoicesColumns';
import { IInvoicePayment } from '../types/IInvoicePayment';

class InvoicesListTableConfigurator {
  public getColumns = (invoicesListTable: IComponentTable<IInvoiceListElement>) => {
    let columns: IComponentTableColumn[] = [];
    if (invoicesListTable != null) {
      columns = [...invoicesListTable.columns];
      this.configureCurrencyColumns(columns);
      this.configureDateColumns(columns);

      return columns;
    }
    return columns;
  };

  private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
    const currencyColumns = [
      InvoicesColumns.OriginalAmount,
      InvoicesColumns.Balance,
      InvoicesColumns.Movement,
      InvoicesColumns.InterestBalance,
    ];
    const currencyColumnsNames = currencyColumns.map(
      (col) => InvoicesColumns[col].charAt(0).toLowerCase() + InvoicesColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return CurrencyParser.toLocaleString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private configureDateColumns = (columns: IComponentTableColumn[]) => {
    const dateColumns = [InvoicesColumns.DueDate, InvoicesColumns.InvoiceDate];
    const currencyColumnsNames = dateColumns.map(
      (col) => InvoicesColumns[col].charAt(0).toLowerCase() + InvoicesColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return DateTimeParser.toLocaleDateString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };
}

class InvoicesListPaymentsTableConfigurator {
  public getColumns = (invoicesListTable: IComponentTable<IInvoicePayment>) => {
    let columns: IComponentTableColumn[] = [];
    if (invoicesListTable != null) {
      columns = [...invoicesListTable.columns];
      this.configureCurrencyColumns(columns);
      this.configureDateColumns(columns);

      return columns;
    }
    return columns;
  };

  private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
    const currencyColumns = [
      InvoicesPaymentsColumns.Capital,
      InvoicesPaymentsColumns.Interest,
      InvoicesPaymentsColumns.InvoiceFee,
    ];
    const currencyColumnsNames = currencyColumns.map(
      (col) => InvoicesPaymentsColumns[col].charAt(0).toLowerCase() + InvoicesPaymentsColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return CurrencyParser.toLocaleString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private configureDateColumns = (columns: IComponentTableColumn[]) => {
    const dateColumns = [InvoicesPaymentsColumns.PaidDate];
    const currencyColumnsNames = dateColumns.map(
      (col) => InvoicesPaymentsColumns[col].charAt(0).toLowerCase() + InvoicesPaymentsColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return DateTimeParser.toLocaleDateString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };
}

export default {
  InvoicesListTableConfigurator: new InvoicesListTableConfigurator(),
  InvoicesListPaymentsTableConfigurator: new InvoicesListPaymentsTableConfigurator(),
};
