import { CancelToken } from 'axios';
import RestApiClient from '../../../core/api/RestApiClient';
import { ManagedPageModel } from '../../PagesList/Models/ManagedPageModel';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { ModuleInstanceSetting } from '../../PagesList/Models/ModuleInstanceSetting';
import { UpdateModuleInstanceSettingRequest } from '../../PagesList/types/UpdateModuleInstanceSettingRequest';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class ManageModuleInstancesApiClient {
  private cancelToken: CancelToken;

  constructor(cancelToken: CancelToken) {
    this.cancelToken = cancelToken;
  }

  public async getPageModuleInsatances(pageId: string): Promise<ManagedPageModel> {
    return await RestApiClient.get<ManagedPageModel>(
      `modules/ManageModulesInstances/page/${pageId}`,
      null,
      this.cancelToken
    );
  }

  public async getModuleInstanceSettings(
    moduleInstanceId: number
  ): Promise<INumberDictionary<ModuleInstanceSetting[]>> {
    return await RestApiClient.get<INumberDictionary<ModuleInstanceSetting[]>>(
      `modules/ManageModulesInstances/getModuleInstanceSettings/${moduleInstanceId}`
    );
  }

  public async updateSetting(request: UpdateModuleInstanceSettingRequest): Promise<IActionStatus> {
    return await RestApiClient.post<IActionStatus>(`modules/ManageModulesInstances/updateSetting`, request);
  }

  public async deleteModuleInstanceSetting(moduleInstanceSettingId: number): Promise<IActionStatus> {
    return await RestApiClient.delete<IActionStatus>(
      `modules/ManageModulesInstances/moduleInstanceSetting/${moduleInstanceSettingId}`
    );
  }
}

export default ManageModuleInstancesApiClient;
