import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router-dom';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { CustomerTransactionsListTableColumns } from '../types/CustomerTransactionsListTableColumns';
import { ICustomerTransactionsListItem } from '../types/ICustomerTransactionsListItem';
import { CustomerTransactionProductType } from '../types/CustomerTransactionProductType';
import UserIdentityService from '../../../core/services/UserIdentityService';
import { PermissionNames } from '../../../core/helpers/PermissionNames';
import { InvoiceStatusType } from '../types/InvoiceStatusType';

const userIdendtityService = new UserIdentityService();

const configureDocNoColumns = (columns: IComponentTableColumn[]) => {
  const column = columns.find((x: IComponentTableColumn) => x.dataField === 'docNo');

  if (column) {
    column.formatter = (value: any, element: ICustomerTransactionsListItem) => {
      return (
        <Link
          to={`invoice-details?invoiceNo=${value}&creditorNo=${element.creditorNo}&accountNo=${element.accountNo}`}
          className="font-color-1 font-weight-bold"
        >
          {value}
        </Link>
      );
    };
  }
};

const configureActions = (
  columns: IComponentTableColumn[],
  openPostponeInvoiceModal: (creditorNo: string, accountNo: string, invoiceNo: string, originalDueDate: Date) => void
) => {
  const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
  if (column) {
    column.formatter = (value: any, caseListElement: ICustomerTransactionsListItem) =>
      actionsRowData(value, caseListElement, openPostponeInvoiceModal);
  }
};

const configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
  const currencyColumns = [CustomerTransactionsListTableColumns.Capital, CustomerTransactionsListTableColumns.Balance];
  const currencyColumnsNames = currencyColumns.map(
    (col) =>
      CustomerTransactionsListTableColumns[col].charAt(0).toLowerCase() +
      CustomerTransactionsListTableColumns[col].slice(1)
  );
  const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
  const formatterFunc = (value: any) => CurrencyParser.toLocaleString(value);

  for (const col of cols) {
    col.formatter = formatterFunc;
  }
};

const configureDateColumns = (columns: IComponentTableColumn[]) => {
  const dateColumns = [CustomerTransactionsListTableColumns.RegDate, CustomerTransactionsListTableColumns.DueDate];
  const currencyColumnsNames = dateColumns.map(
    (col) =>
      CustomerTransactionsListTableColumns[col].charAt(0).toLowerCase() +
      CustomerTransactionsListTableColumns[col].slice(1)
  );
  const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
  const formatterFunc = (value: any) => DateTimeParser.toLocaleDateString(value);

  for (const col of cols) {
    col.formatter = formatterFunc;
  }
};

const configureCaseLinkColumns = (columns: IComponentTableColumn[]) => {
  const columnsList = [CustomerTransactionsListTableColumns.CaseNo];
  const columnsNames = columnsList.map(
    (col) =>
      CustomerTransactionsListTableColumns[col].charAt(0).toLowerCase() +
      CustomerTransactionsListTableColumns[col].slice(1)
  );
  const cols = columns.filter((col) => columnsNames.indexOf(col.dataField) >= 0);

  const formatterFunc = (value: any, row: any) => (
    <Link to={`/case?id=${row.caseNo}`} className="font-color-1 font-weight-bold">
      {value}
    </Link>
  );

  for (const col of cols) {
    col.formatter = formatterFunc;
  }
};

const configureDebtorColumns = (
  columns: IComponentTableColumn[],
  openDebtorDetailsModal: (creditorNo: string, accountNo: string, invoiceNo: string) => void
) => {
  const columnsList = [CustomerTransactionsListTableColumns.DebtorNo, CustomerTransactionsListTableColumns.DebtorName];
  const columnsNames = columnsList.map(
    (col) =>
      CustomerTransactionsListTableColumns[col].charAt(0).toLowerCase() +
      CustomerTransactionsListTableColumns[col].slice(1)
  );
  const cols = columns.filter((col) => columnsNames.indexOf(col.dataField) >= 0);

  const formatterFunc = (value: any, row: any) => (
    <NavLink
      to={'#'}
      onClick={(e: any) => {
        e.preventDefault();
        openDebtorDetailsModal(row.creditorNo, row.accountNo.toString(), row.docNo.toString());
      }}
      className="font-color-1 font-weight-bold"
    >
      {value}
    </NavLink>
  );

  for (const col of cols) {
    col.formatter = formatterFunc;
  }
};

const configureProductAndStatusIconColumn = (columns: IComponentTableColumn[]) => {
  const column = columns.find((x: IComponentTableColumn) => x.dataField === 'product');
  if (column) {
    column.formatter = (value: any, element: ICustomerTransactionsListItem) =>
      TranslationService.translateModule(
        `CustomerTransactionProductType_${CustomerTransactionProductType[value]}`,
        ModuleNamesList.CustomerTransactionsList
      );

    let statusIconColumn = {
      dataField: 'statusIcon',
      text: '',
      sort: false,
    } as IComponentTableColumn;

    statusIconColumn.formatter = statusIconRowData;
    statusIconColumn.headerClasses = () => 'bt-table__header bt-table__header--single-icon';
    statusIconColumn.classes = () => {
      return 'bt-table__cell bt-table__cell--text-center';
    };

    columns.splice(0, 0, statusIconColumn);
  }
};

const statusIconRowData = (value: any, element: ICustomerTransactionsListItem) => {
  const mapping = {
    [CustomerTransactionProductType.PreLegal]: 'ctl-icon ctl-icon--pre-legal',
    [CustomerTransactionProductType.Reminder]: 'ctl-icon ctl-icon--reminder',
    [CustomerTransactionProductType.Legal]: 'ctl-icon ctl-icon--legal',
    [CustomerTransactionProductType.Surveillance]: 'ctl-icon ctl-icon--surveillance',
    [CustomerTransactionProductType.Invoice]: 'ctl-icon ctl-icon--invoice',
  };

  return <i className={mapping[element.product]} />;
};

const actionsRowData = (
  value: any,
  element: ICustomerTransactionsListItem,
  openPostponeInvoiceModal: (creditorNo: string, accountNo: string, invoiceNo: string, originalDueDate: Date) => void
) => {
  if (element.caseNo || !userIdendtityService.hasPermission(PermissionNames.CanPostponeArmInvoice)) return;
  return element.statusEnum.toString() !== InvoiceStatusType.FullyPaid.toString() ? (
    <Dropdown alignRight className="button-table">
      <Dropdown.Toggle bsPrefix="c-btn c-btn--color-primary c-btn--toggle" id="customerTransationActionsBtn">
        {TranslationService.translateModule('Actions', ModuleNamesList.CaseList)}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          id="customerTransationPostponeDateAction"
          onClick={() => {
            openPostponeInvoiceModal(
              element.creditorNo,
              element.accountNo.toString(),
              element.docNo.toString(),
              element.dueDate
            );
          }}
        >
          <i className="far fa-calendar-alt" />{' '}
          {TranslationService.translateModule('ActionsPostponeInvoice', ModuleNamesList.CustomerTransactionsList)}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <React.Fragment />
  );
};

const configureNameIdColumnAsLink = (columns: IComponentTableColumn[]) => {
  const column = columns.find((x: IComponentTableColumn) => x.dataField === 'nameId');
  if (column) {
    column.formatter = (value: any, element: ICustomerTransactionsListItem) => {
      return (
        <Link
          to={`creditor-account-summary?nameId=${value}&creditorNo=${element.creditorNo}&accountNo=${element.accountNo}&debtorNo=${element.debtorNo}`}
          className="font-color-1 font-weight-bold"
        >
          {value}
        </Link>
      );
    };
  }
};

const configureExternalVoucherNoColumnsAsLink = (columns: IComponentTableColumn[]) => {
  const column = columns.find((x: IComponentTableColumn) => x.dataField === 'externalVoucherNo');
  if (column) {
    column.formatter = (value: any, element: ICustomerTransactionsListItem) => {
      return (
        <Link
          to={`creditor-account-summary?externalVoucherNo=${value}&creditorNo=${element.creditorNo}&accountNo=${element.accountNo}&debtorNo=${element.debtorNo}`}
          className="font-color-1 font-weight-bold"
        >
          {value}
        </Link>
      );
    };
  }
};

export default {
  getTableColumns: (
    customerTransactionsListTable: IComponentTable<ICustomerTransactionsListItem>,
    openPostponeInvoiceModal: (creditorNo: string, accountNo: string, invoiceNo: string, originalDueDate: Date) => void,
    openDebtorDetailsModal: (creditorNo: string, accountNo: string, invoiceNo: string) => void
  ) => {
    let columns: IComponentTableColumn[] = [];
    if (customerTransactionsListTable != null) {
      columns = [...customerTransactionsListTable.columns];
      configureCurrencyColumns(columns);
      configureDateColumns(columns);
      configureActions(columns, openPostponeInvoiceModal);
      configureCaseLinkColumns(columns);
      configureProductAndStatusIconColumn(columns);
      configureDocNoColumns(columns);
      configureDebtorColumns(columns, openDebtorDetailsModal);
      configureNameIdColumnAsLink(columns);
      configureExternalVoucherNoColumnsAsLink(columns);

      return columns;
    }
    return columns;
  },
};
