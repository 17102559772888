import { NavBarFilterOption } from '../../../types/NavBarFilterOption';

export enum CustomerTransactionsSelectFilter {
  AccountNumber = 0,
  DebtorNumber = 1,
  DebtorName = 2,
  UnitID = 3,
  NameId = 4,
  ExternalVoucherId = 5,
  CaseNumber = 6,
  InvoiceNumber = 7,
}

export const getCustomerTransactionsSelectFilter = (
  navBarFilterOptionString: string
): CustomerTransactionsSelectFilter => {
  const mapping = {
    [NavBarFilterOption.CaseNumber]: CustomerTransactionsSelectFilter.CaseNumber,
    [NavBarFilterOption.DebtorNumber]: CustomerTransactionsSelectFilter.DebtorNumber,
    [NavBarFilterOption.DebtorName]: CustomerTransactionsSelectFilter.DebtorName,
    [NavBarFilterOption.InvoiceNumber]: CustomerTransactionsSelectFilter.InvoiceNumber,
    [NavBarFilterOption.UnitId]: CustomerTransactionsSelectFilter.UnitID,
    [NavBarFilterOption.NameId]: CustomerTransactionsSelectFilter.NameId,
    [NavBarFilterOption.ExternalVoucherNo]: CustomerTransactionsSelectFilter.ExternalVoucherId,
  };

  return mapping[navBarFilterOptionString];
};
