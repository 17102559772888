import { useEffect, useState } from 'react';

type TimelineVerticalScrollType = {
  data: any;
};

const TimelineVerticalScroll = (props: TimelineVerticalScrollType) => {
  const [style, setStyle] = useState<any>();

  useEffect(() => {
    if (props.data) {
      const style = {
        top: `${props.data.top}%`,
        height: `calc(${props.data.scrollHeight} * 100%)`,
      };

      setStyle(style);
    }
  }, [props.data]);

  return (
    <div className="timeline-v-scroll">
      <div className="timeline-v-scroll__bar">
        <div style={style} />
      </div>
    </div>
  );
};

export default TimelineVerticalScroll;
