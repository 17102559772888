import { useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { SettingsType } from '../../../core/Enums/SettingsType';
import { InputContainer } from '../../../core/components/Forms/InputContainer';

const SaveButton = (props: { value: EditorValue | string; onUpdate: (value: string) => void; inToolbar?: boolean }) => (
  <div className="stce-rich-editor__button-group">
    <Button
      key="svBtn"
      type="button"
      className="c-btn c-btn--icon-only c-btn--1"
      icon={{ className: 'far fa-save', position: 'left' }}
      variant={BUTTON_VARIANTS.PRIMARY}
      size={BUTTON_SIZE.MD}
      onClick={() => {
        props.onUpdate(typeof props.value === 'string' ? props.value : props.value.toString('html'));
      }}
    />
  </div>
);

const CancelButton = () => (
  <div className="stce-rich-editor__button-group">
    <Button
      type="button"
      key="cnclBtn"
      className="c-btn c-btn--icon-only c-btn--1"
      icon={{ className: 'fas fa-ban', position: 'left' }}
      variant={BUTTON_VARIANTS.PRIMARY}
      size={BUTTON_SIZE.MD}
    />
  </div>
);

type Props = {
  type: SettingsType;
  value: any;
  row: any;
  id: string;
  options?: any[];
  onUpdate: (val: any) => void;
  onBlur?: (val?: any) => void;
  onKeyUp?: (val?: any) => void;
};

const SettingsTypeCellEditor = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>(props.value);
  const [rteValue, setRteValue] = useState(RichTextEditor.createValueFromString(props.value || '', 'html'));

  return props.type === SettingsType.HtmlString ? (
    <>
      <RichTextEditor
        autoFocus={true}
        value={rteValue}
        onChange={(val: EditorValue) => setRteValue(val)}
        customControls={[
          <SaveButton key={'1'} value={rteValue} onUpdate={props.onUpdate} inToolbar={true} />,
          <CancelButton key={'2'} />,
        ]}
        toolbarClassName="stce-rich-editor__toolbar"
        className="stce-rich-editor"
        toolbarConfig={{
          display: ['INLINE_STYLE_BUTTONS'],
          INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD', className: 'c-btn c-btn--1 c-btn--md' }],
          BLOCK_TYPE_DROPDOWN: [],
          BLOCK_TYPE_BUTTONS: [],
          BLOCK_ALIGNMENT_BUTTONS: [],
        }}
      />
    </>
  ) : (
    <>
      <InputContainer
        type={props.type}
        id={props.id}
        value={inputValue}
        onBlur={(value) => {
          if (value !== props.value && props.onBlur) {
            props.onBlur(value);
          }
        }}
        onKeyUp={(e) => {
          if (props.onKeyUp) {
            props.onKeyUp(e);
          }
        }}
        onChange={(value) => {
          if (inputValue !== value) {
            setInputValue(value);

            props.onUpdate(value);
          }
        }}
      />
    </>
  );
};

export default SettingsTypeCellEditor;
