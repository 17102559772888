import * as React from 'react';
import { ContainerFactory } from '../factories/ContainerFactory';
import { ISkinProps } from '../../core/types/ISkinProps';

export function FullWidthTopAnd3CenterContainersSkin(props: ISkinProps) {
  const containers = props.skin.containers;
  return (
    <>
      <div className="skin">
        <div className="skin__row skin__row--full">
          <div className="skin__content">
            <div className="row">
              <div className="col-12">
                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={0} />
              </div>
            </div>
          </div>
        </div>
        <div className="skin__row skin__row--full">
          <div className="skin__content">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={1} />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={2} />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={3} />
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <ContainerFactory containers={containers} routeParameters={props.routeParameters} moduleIndex={4} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
