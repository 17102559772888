import React from 'react';
import { IContainer } from '../../core/api/types/IContainer';
import ContainerList from '../lists/ContainerList';
import { ParsedQuery } from 'query-string';

const MissingContainer = () => <div> [missing container] </div>;
const ContainerNotFound = () => <div> [container not found] </div>;

interface IProps {
  staticHeight?: boolean;
  moduleIndex: number;
  containers: IContainer[];
  routeParameters: ParsedQuery;
}

export function ContainerFactory(props: IProps) {
  if (!props.containers || props.containers.length === 0) {
    return <MissingContainer />;
  }

  if (props.containers.length <= props.moduleIndex) {
    return <ContainerNotFound />;
  }

  const container = props.containers[props.moduleIndex];
  if (!container) {
    return <ContainerNotFound />;
  }

  const Container = ContainerList[container.name];
  return Container ? (
    <Container container={container} staticHeight={props.staticHeight} routeParameters={props.routeParameters} />
  ) : (
    <ContainerNotFound />
  );
}
