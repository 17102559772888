import { IUserDetails } from '../../../core/types/IUserDetails';
import UserProfileApiClient from '../api/UserProfileApiClient';
import { IUserProfileModuleSettings } from '../types/IUserProfileModuleSettings';
import { IActionStatus } from 'core/api/types/IActionStatus';

class UserProfileService {
  public async updateUserDetails(userDetails: IUserDetails, moduleInstanceId: number): Promise<any> {
    return await UserProfileApiClient.updateUserDetails(userDetails, moduleInstanceId);
  }

  public async getModuleSettings(moduleInstanceId: number): Promise<IUserProfileModuleSettings> {
    return await UserProfileApiClient.getModuleSettings(moduleInstanceId);
  }

  public async requestVerificationCodes(
    phone: string,
    email: string,
    moduleInstanceId: number
  ): Promise<IActionStatus> {
    return await UserProfileApiClient.requestVerificationCodes(phone, email, moduleInstanceId);
  }

  public async checkEmailExists(email: string, moduleInstanceId: number): Promise<boolean> {
    return await UserProfileApiClient.checkEmailExists(email, moduleInstanceId);
  }
}

export default new UserProfileService();
