import * as React from 'react';
import { ISkinProps } from '../../core/types/ISkinProps';
import { ContainerFactory } from '../factories/ContainerFactory';

export class TopMainSidebarSkin extends React.Component<ISkinProps> {
  public render() {
    const containers = this.props.skin.containers;
    const routeParameters = this.props.routeParameters;
    return (
      <>
        <div className="skin">
          <div className="skin__row skin__row--full">
            <div className="skin__content">
              <ContainerFactory containers={containers} moduleIndex={0} routeParameters={routeParameters} />
            </div>
          </div>
          <div className="skin__row skin__row--full">
            <div className="skin__content">
              <div className="row">
                <div className="col-lg-9 mb-3 mb-0-lg">
                  <ContainerFactory containers={containers} moduleIndex={1} routeParameters={routeParameters} />
                </div>
                <div className="col-lg-3">
                  <ContainerFactory containers={containers} moduleIndex={2} routeParameters={routeParameters} />
                </div>
              </div>
            </div>
          </div>
          <div className="skin__row skin__row--full">
            <div className="skin__content col-lg-9">
              <ContainerFactory containers={containers} moduleIndex={3} routeParameters={routeParameters} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
