import * as React from 'react';
import { Status } from '../../core/api/Enums/Status';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import Loader from '../../core/components/Loading/Loader';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable, IComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import GroupUsersTableConfigurator from './helpers/GroupUsersTableConfigurator';
import { IGroupUser } from './types/IGroupUser';
import GroupUsersAssignmentApiClient from './api/GroupUsersAssignmentApiClient';

interface IGroupUsersAssignmentProps extends IModuleProps {
  userUId: string;
}

interface IState {
  isLoading: boolean;
  usersTable: IComponentTable<IGroupUser>;
  selectedUsers: IGroupUser[];
}

export default class GroupUsersAssignment extends React.Component<IGroupUsersAssignmentProps, IState> {
  public state: IState = {
    isLoading: true,
    usersTable: { ...emptyComponentTable },
    selectedUsers: [],
  };

  public componentDidMount = async () => {
    this.setState({ isLoading: true });
    const result = await GroupUsersAssignmentApiClient.getGroupUsers(this.props.userUId, this.props.module.id);
    this.setState({
      usersTable: result.table,
      selectedUsers: result.selectedUsers,
    });
    this.setState({ isLoading: false });
  };

  public render() {
    return (
      <div className="modal-content--min-height">
        <section className={`l-module__section mt-4 ${this.state.isLoading ? 'l-module__section--loading' : ''}`}>
          {this.state.isLoading ? <Loader opacity={0.5} /> : null}
          <BootstrapTableContainer
            remote={false}
            wrapperClasses="bt"
            classes="bt__table bt-table bt--small bt--selectable"
            keyField="uId"
            paginationProps={{}}
            selectRow={{
              hideSelectColumn: true,
              mode: 'checkbox',
              classes: 'bt bt-table bt-table__row bt-table__row--selected',
              clickToSelect: true,
              onSelect: this.onSelect,
              selected: this.state.selectedUsers.map((item) => item.uId),
            }}
            data={this.state.usersTable.values}
            columns={
              this.state.usersTable.columns.length === 0
                ? []
                : GroupUsersTableConfigurator.getTableColumns(this.state.usersTable.columns)
            }
          />
          <button className="btn btn--normal btn-color-2 btn-sm mt-1 float-right" onClick={this.assignRoles}>
            {TranslationService.translateModule('AssignGroupsButton', ModuleNamesList.GroupUsersAssignment)}
          </button>
        </section>
      </div>
    );
  }

  private onSelect = (element: IGroupUser) => {
    let selectedUsers = this.state.selectedUsers;
    const index = selectedUsers.findIndex((item) => item.uId === element.uId);
    if (index > -1) {
      selectedUsers.splice(index, 1);
    } else {
      selectedUsers.push(element);
    }
    this.setState({ selectedUsers });
  };

  private assignRoles = async () => {
    try {
      this.setState({ isLoading: true });
      const result = await GroupUsersAssignmentApiClient.assignGroupUsers(this.props.userUId, this.state.selectedUsers);
      if (result.status === Status.Success) {
        InfoMessageService.success(
          TranslationService.translateModule('SuccessAssignment', ModuleNamesList.GroupUsersAssignment)
        );
      } else {
        InfoMessageService.displayActionStatus(result);
      }
    } catch {
      InfoMessageService.error(
        TranslationService.translateModule('ErrorAssignment', ModuleNamesList.GroupUsersAssignment)
      );
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };
}
