import * as React from 'react';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { ISearchDebtorListElement } from '../types/ISearchDebtorListElement';

interface IProps {
  data: IComponentTable<ISearchDebtorListElement>;
  onDebtorSelect: (domainId: string) => void;
}

class SearchDebtorsList extends React.Component<IProps> {
  public rowEvents = {
    onClick: (e: any, row: ISearchDebtorListElement, rowIndex: number) => {
      this.props.onDebtorSelect(row.domainId);
    },
  };

  public render() {
    return (
      <div className="row">
        <div className="col-12">
          <BootstrapTable
            wrapperClasses="bt"
            classes="bt__table bt-table bt-table--dark"
            keyField="domainId"
            data={this.props.data.values}
            columns={this.props.data.columns}
            pagination={paginationFactory({})}
            rowEvents={this.rowEvents}
          />
        </div>
      </div>
    );
  }
}

export default SearchDebtorsList;
