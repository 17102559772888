import RestApiClient from '../../../core/api/RestApiClient';
import { ICustomerTransactionsListRequest } from '../types/ICustomerTransactionsListRequest';
import { ICustomerTransactionsSummary } from '../types/ICustomerTransactionsSummary';
import { ICustomerTransactionsInitialSettings } from '../types/ICustomerTransactionsInitialSettings';

class CustomerTransactionsListApi {
  public async getCustomerTransactions(
    request: ICustomerTransactionsListRequest
  ): Promise<ICustomerTransactionsSummary> {
    return await RestApiClient.post('modules/CustomerTransactionsListModule/getCustomerTransactionsList', request);
  }
  public async getCustomerTransactionsAsFile(request: ICustomerTransactionsListRequest): Promise<Blob> {
    return await RestApiClient.downloadBlob('modules/CustomerTransactionsListModule/export', request);
  }
  public async getInitialSettings(moduleInstanceId: number): Promise<ICustomerTransactionsInitialSettings> {
    return await RestApiClient.get(`modules/CustomerTransactionsListModule/getInitialSettings/${moduleInstanceId}`);
  }
}

export default new CustomerTransactionsListApi();
