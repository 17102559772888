import * as React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import setlanguage from '../../../../actionCreators/setLanguage';
import { IStore } from '../../../../reducers/IStore';
import TranslationService from '../../../services/TranslationService';
import { ILanguage, LanguageCode } from '../../../types/ILanguage';
import { localeToCountryCode } from '../../../utils/constants';

interface IProps {
  currentLanguageCode: string;
  languages: ILanguage[];
  setlanguageAndClearState: (langCode: LanguageCode) => void;
  displayAsDropdown?: boolean;
}

interface IState {
  dropdownOpen: boolean;
}

class LanguageSwitcher extends React.Component<IProps, IState> {
  public state: IState = {
    dropdownOpen: false,
  };

  public render() {
    return this.props.displayAsDropdown ? (
      this.getDropdown()
    ) : (
      <div className="language-switch">
        {this.props.languages
          .map((lang: ILanguage) => this.getLanguageItemElement(lang))
          .reduce((result, item) => (
            <>
              {result}
              <> | </>
              {item}
            </>
          ))}
      </div>
    );
  }

  private getLanguageItemElement = (lang: ILanguage) => {
    const selectedClassName = 'selected';

    return (
      <button
        id={`${localeToCountryCode[lang.code]}LangBtn`}
        type="button"
        tabIndex={0}
        key={lang.code}
        className={`${this.props.currentLanguageCode === lang.code && selectedClassName}`}
        onClick={() => this.changeLanguage(lang.code)}
      >
        {localeToCountryCode[lang.code]}
      </button>
    );
  };

  private getDropdown = () => {
    return (
      <Dropdown className="c-dropdown" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag="a" className="nav-link" tabIndex={0} caret={true}>
          <i className={`c-flag c-flag--${this.props.currentLanguageCode}`} />
        </DropdownToggle>
        <DropdownMenu right={true}>
          {this.props.languages.map((lang: ILanguage, index) => (
            <DropdownItem
              key={index}
              id={`${localeToCountryCode[lang.code]}LangBtn` as string}
              onClick={() => this.changeLanguage(lang.code)}
            >
              <i className={`c-flag c-flag--${lang.code}`} />
              {TranslationService.translate(`${localeToCountryCode[lang.code]}_lang`)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  private toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  private changeLanguage = (code: LanguageCode) => {
    this.props.setlanguageAndClearState(code);
  };
}

const mapStateToProps = (state: IStore) => ({
  languages: state.languages,
  currentLanguageCode: state.currentCultureCode,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
  setlanguageAndClearState: (langCode: LanguageCode) => dispatch(setlanguage(langCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
