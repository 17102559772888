import React, { useEffect, useState } from 'react';
import { BootstrapTableContainer } from '../../../core/components/BootstrapTable/BootstrapTableContainer';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { Card } from '../../../core/components/Card/Card';
import { CardContent } from '../../../core/components/Card/components/CardContent';
import { CardHeader } from '../../../core/components/Card/components/CardHeader';
import { InputContainer } from '../../../core/components/Forms/InputContainer';
import { SettingsType } from '../../../core/Enums/SettingsType';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import InfoMessageService from '../../../core/services/InfoMessageService';
import TranslationService from '../../../core/services/TranslationService';
import { INumberDictionary } from '../../../core/types/IDictionary';
import { getComplexColumns } from '../helpers/ComplexSettingsTableConfigurator';
import { IModuleComplexField } from '../types/IModuleComplexField';
import { IModuleSetting } from '../types/IModuleSetting';
import { IUpdateModuleSettingRequest } from '../types/IUpdateModuleSettingRequest';
import AddNewSetting from './AddNewSetting';

type ComplexSettingsTablePropsType = {
  moduleSettings: IModuleSetting;
  resetSettings: (instanceSettingId: number) => void;
  updateModuleSetting: (setting: IModuleSetting | IUpdateModuleSettingRequest, moduleSettingsId?: number) => void;
};

const ComplexSettingsTable = (props: ComplexSettingsTablePropsType) => {
  const [emptyTableDataRow, setEmptyTableDataRow] = useState<any>();
  const [isAddNewSettingMode, setIsAddNewSettingMode] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [isRemovable, setIsRemovable] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const { id, description, type, complexFields } = props.moduleSettings;

  const mapToFlatDataObject = (moduleComplexFields: IModuleComplexField[]) => {
    const result = moduleComplexFields.map((item, index: number) => {
      const mappedObject = {
        name: item.name,
        index: index,
      };

      Object.getOwnPropertyNames(item.complexFieldsSettings).forEach((settingName, index: number) => {
        mappedObject['props'] = {
          ...mappedObject['props'],
          [settingName]: {
            type: item.complexFieldsSettings[settingName].type,
            id: item.complexFieldsSettings[settingName].complexFieldsSettingId,
          },
        };
        mappedObject[settingName] = item.complexFieldsSettings[settingName].value;
      });

      return mappedObject;
    });

    return result;
  };

  const getSelectComponent = (complexModuleSetting: IModuleSetting) => {
    const options = mapToFlatDataObject(complexModuleSetting.complexFields);
    const selectListOptopns: INumberDictionary<string> = {};

    options.forEach((item: any) => (selectListOptopns[item.Value] = item.name));

    return (
      <InputContainer
        type={SettingsType.Select}
        id={'complexItem-' + complexModuleSetting.id}
        options={selectListOptopns}
        value={complexModuleSetting.value}
        onChange={(value: string) => {
          const request = complexModuleSetting;
          request.value = value;

          props.updateModuleSetting(request, props.moduleSettings.id);
        }}
      />
    );
  };

  const handleRemoveRowClick = (row: any) => {
    const moduleSettings: IModuleSetting = { ...props.moduleSettings };

    if (moduleSettings.complexFields.length > 1) {
      moduleSettings.complexFields = moduleSettings.complexFields.filter((complexField) => {
        return complexField.name !== row.name ? true : false;
      });

      props.updateModuleSetting(moduleSettings, props.moduleSettings.id);
    } else {
      InfoMessageService.warning(
        TranslationService.translateModule('DeleteSettingIsDenied', ModuleNamesList.ModuleSettings)
      );
    }
  };

  const setValueByType = (key: string, value: string) => {
    const newEmptyProp = {};

    switch (typeof value) {
      case 'number':
        newEmptyProp[key] = 0;
        break;
      case 'string':
        newEmptyProp[key] = '';
        break;
      case 'boolean':
        newEmptyProp[key] = false;
        break;
      case 'object':
        newEmptyProp[key] = value;
        break;
    }

    return newEmptyProp;
  };

  const handleAddRowClick = () => {
    setIsAddNewSettingMode(true);

    if (emptyTableDataRow) {
      setEmptyTableDataRow({
        ...emptyTableDataRow,
        index: tableData.length,
      });
    } else {
      const emptyTableRow = {};

      Object.keys(tableData[0]).forEach((key) => {
        const newEmptyProp = setValueByType(key, tableData[0][key]);

        emptyTableRow[key] = newEmptyProp[key];
      });

      emptyTableRow['index'] = tableData.length;

      setEmptyTableDataRow(emptyTableRow);
    }
  };

  const handleChnage = (value: string, id: number) => {
    const changedSetting: IUpdateModuleSettingRequest = {
      id,
      value: value,
      isComplex: true,
    };

    props.updateModuleSetting(changedSetting, props.moduleSettings.id);
  };

  const handleAddClick = (values: any) => {
    const fieldTypes = emptyTableDataRow.props;
    const newComplexFields: IModuleComplexField = {
      name: values.name,
      translationKey: values.name,
      type: 1,
      complexFieldsSettings: {},
    };

    for (const prop in values) {
      if (prop !== 'name') {
        newComplexFields.complexFieldsSettings[prop] = {
          complexFieldsSettingId: 0,
          value: values[prop],
          type: fieldTypes[prop].type,
          isInternal: false,
        };
      }
    }

    props.updateModuleSetting(
      {
        ...props.moduleSettings,
        complexFields: [...props.moduleSettings.complexFields, newComplexFields],
      },
      props.moduleSettings.id
    );

    setIsAddNewSettingMode(false);
  };

  const handleCancelClick = () => {
    setIsAddNewSettingMode(false);
  };

  const handleResetClick = () => {
    props.resetSettings(id);
  };

  useEffect(() => {
    if (props.moduleSettings && props.moduleSettings.isComplex) {
      const moduleSettings = mapToFlatDataObject(props.moduleSettings.complexFields);

      if (props.moduleSettings.isDefault === undefined) {
        setIsDefault(true);
      } else {
        setIsDefault(props.moduleSettings.isDefault || false);
      }

      setTableData(moduleSettings);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props.moduleSettings)]);

  useEffect(() => {
    setIsRemovable(type === SettingsType.Dictionary);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card class="mb-4">
        <>
          <CardHeader>
            <div className="row align-items-center justify-content-between">
              <div className="col">
                <h1 className="c-heading">{description}</h1>
              </div>

              <div className="col col-auto align-self-end ">
                {!isDefault && (
                  <Button
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.MD}
                    type="button"
                    label={TranslationService.translateModule('Reset', ModuleNamesList.PagesList)}
                    onClick={handleResetClick}
                  />
                )}

                {type === SettingsType.Dictionary && (
                  <Button
                    type="button"
                    id="complexSettingsTableAdd"
                    variant={BUTTON_VARIANTS.LINK}
                    size={BUTTON_SIZE.SM}
                    label="Add new setting"
                    disabled={isAddNewSettingMode}
                    icon={{
                      className: 'fas fa-plus',
                      position: '',
                    }}
                    onClick={handleAddRowClick}
                  />
                )}
              </div>
            </div>
          </CardHeader>

          <CardContent>
            <>
              {type === SettingsType.Select ? (
                <section className="mt-2">{getSelectComponent(props.moduleSettings)}</section>
              ) : (
                <section>
                  {isAddNewSettingMode && (
                    <AddNewSetting
                      emptyTableDataRow={emptyTableDataRow}
                      onAddClick={handleAddClick}
                      onCancelClick={handleCancelClick}
                    />
                  )}

                  {tableData.length && (
                    <BootstrapTableContainer
                      variant="secondary"
                      classes="bt__table bt-table"
                      columns={getComplexColumns(complexFields, isRemovable, handleChnage, handleRemoveRowClick)}
                      data={tableData}
                      keyField="name"
                      paginationProps={{}}
                      remote={false}
                      wrapperClasses="bt bt--secondary"
                      defaultSorted={[
                        {
                          dataField: 'name',
                          order: 'asc',
                        },
                      ]}
                    />
                  )}
                </section>
              )}
            </>
          </CardContent>
        </>
      </Card>
    </>
  );
};

export default ComplexSettingsTable;
