import { IInvoicesAgeAnalysisRequest } from '../types/IInvoicesAgeAnalysisRequest';
import InvoicesAgeAnalysisApi from '../api/InvoicesAgeAnalysisApi';
import { IInvoicesAgeAnalysisSummary } from '../types/IInvoicesAgeAnalysisSummary';
import { CancelToken } from 'axios';

class InvoicesAgeAnalysisService {
  public async getInvoicesAgeAnalysisData(
    request: IInvoicesAgeAnalysisRequest,
    cancelToken: CancelToken
  ): Promise<IInvoicesAgeAnalysisSummary> {
    return await InvoicesAgeAnalysisApi.getInvoicesAgeAnalysisData(request, cancelToken);
  }
}

export default new InvoicesAgeAnalysisService();
