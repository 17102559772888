import { NavItem } from 'reactstrap';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { ILeftNavMenuState } from './types/ILeftNavMenuState';

export const LeftMenuSubLinks = (item: ILeftNavMenuState) => {
  if (!item.navItems) {
    return <div />;
  }

  return (
    <>
      {item.navItems
        .sort((a, b) => a.order - b.order)
        .map((subItem, index: number) => {
          const isActive = item.pathName ? item.pathName === subItem.to : false;
          return (
            <NavItem key={index} className="c-left-menu__item">
              <NavLink
                className="c-left-menu__link c-left-menu__link--sub"
                to={subItem.to}
                isActive={() => isActive}
                id={subItem.id}
              >
                {subItem.name}
                <LeftMenuSubLinks navItems={subItem.subLinks} />
              </NavLink>
            </NavItem>
          );
        })}
    </>
  );
};
