import React from 'react';
import { SimpleSelect } from '../../core/components/Forms/SimpleSelect';
import SimpleInput from '../../core/components/Forms/SimpleInput';
import { NavBarFilterOption } from '../../types/NavBarFilterOption';
import { INumberDictionary } from '../../core/types/IDictionary';
import { ISelectedValue } from '../../core/components/Forms/types/ISelectedValue';
import { Button, BUTTON_VARIANTS } from 'core/components/Button/Button';
import TranslationService from 'core/services/TranslationService';
import { ModuleNamesList } from 'core/lists/ModuleNamesList';
import './style.scss';
import NavbarSearchFormApiClient from './api/NavbarSearchFormApiClient';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import InfoMessageService from 'core/services/InfoMessageService';
import { IFilterByOption } from './types/InitialSettings';
import RouteParamsService from 'core/services/RouteParamsService';

interface IState {
  redirect: boolean;
  isOpenFilter: boolean;
  searchValue: string;
  filteredBy?: NavBarFilterOption;
  filterByOptions: INumberDictionary<IFilterByOption>;
  filterByOptionsDisplayed: INumberDictionary<string>;
  redirectUrl?: string;
}

class NavbarSearchForm extends React.Component<RouteComponentProps, IState> {
  public state: IState = {
    redirect: false,
    isOpenFilter: false,
    searchValue: '',
    filterByOptions: {},
    filterByOptionsDisplayed: {},
  };

  public componentDidMount = async () => {
    const initialSettings = await NavbarSearchFormApiClient.getInitialSettings();
    const filterByOptionsDisplayed = {};
    Object.getOwnPropertyNames(initialSettings.filterByOptions).forEach(
      (key) => (filterByOptionsDisplayed[key] = initialSettings.filterByOptions[key].name)
    );
    this.setState(
      {
        filterByOptions: initialSettings.filterByOptions,
        filterByOptionsDisplayed,
      },
      this.setCurrentFilterValues
    );
  };

  setCurrentFilterValues = () => {
    const parameters = RouteParamsService.getQueryParameters();
    const filteredBy = parameters['filteredBy'] as string | undefined;
    const searchValue = parameters['filteredByValue'] as string | undefined;
    if (filteredBy && searchValue && this.state.filterByOptions[filteredBy] !== undefined) {
      this.setState({
        filteredBy: NavBarFilterOption[filteredBy],
        searchValue,
        redirect: false,
        redirectUrl: this.state.filterByOptions[filteredBy].pageUrl,
      });
    }
  };

  getFilterUrlProps = () => {
    const filteredBy = this.state.filteredBy
      ? 'filteredBy=' + NavBarFilterOption[this.state.filteredBy].toString()
      : '';
    const filteredByValue = this.state.searchValue !== '' ? 'filteredByValue=' + this.state.searchValue : '';
    return { filteredBy, filteredByValue };
  };

  toggleFilterView = () => {
    this.setState({
      isOpenFilter: !this.state.isOpenFilter,
    });
  };

  public render() {
    if (this.state.redirect === true && this.state.redirectUrl !== undefined) {
      const { filteredBy, filteredByValue } = this.getFilterUrlProps();
      return <Redirect to={`/${this.state.redirectUrl}?${filteredBy}&${filteredByValue}`} />;
    }

    return (
      <div className={`case-filter ${this.state.isOpenFilter ? 'case-filter--is-open' : ''}`}>
        <Button
          className="case-filter__toggle-btn"
          onClick={this.toggleFilterView}
          icon={{ className: 'fas fa-search', position: 'left' }}
          variant={BUTTON_VARIANTS.TRANSPARENT}
        />

        <div className="case-filter__form">
          <div className="row">
            <div className="col-12 col-sm-6 mb-2">
              <SimpleSelect
                id="case-list-select-filter"
                classNames="case-filter__input"
                value={this.state.filteredBy ? NavBarFilterOption[this.state.filteredBy].toString() : undefined}
                onChangeHandle={(value: ISelectedValue) => {
                  this.setState({
                    filteredBy: value.key !== 'all' ? NavBarFilterOption[value.key] : '',
                    redirectUrl: value.key !== 'all' ? this.state.filterByOptions[value.key].pageUrl : '',
                  });
                }}
                emptyFirstRow={true}
                options={this.state.filterByOptionsDisplayed}
              />
            </div>

            <div className="col-12 col-sm-6 mb-2">
              <SimpleInput
                id="searchValue"
                className="case-filter__input"
                value={this.state.searchValue}
                onChange={(searchValue: string) => this.setState({ searchValue })}
                onKeyUp={({ keyCode, target: { value } }) => {
                  if (keyCode === 13) {
                    this.goToPage();
                  }
                }}
              />
            </div>
          </div>

          <Button
            label={TranslationService.translateModule('Filter', ModuleNamesList.NavbarSearchForm)}
            onClick={() => this.goToPage()}
            icon={{ className: 'fas fa-search', position: 'left' }}
            variant={BUTTON_VARIANTS.PRIMARY}
          />
        </div>
      </div>
    );
  }

  private goToPage = () => {
    if (!this.state.filteredBy || this.state.searchValue.trim() === '') {
      InfoMessageService.warning(
        TranslationService.translateModule('YouNeedToDefineFilters', ModuleNamesList.NavbarSearchForm)
      );
    } else {
      const { filteredBy, filteredByValue } = this.getFilterUrlProps();
      if (this.props.location.search !== `?${filteredBy}&${filteredByValue}`) {
        this.setState({ redirect: true });
      }
    }
  };
}

export default withRouter(NavbarSearchForm);
