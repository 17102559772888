import classNames from 'classnames';
import './Loader.scss';

type PropsType = {
  main?: boolean;
  opacity?: number;
  isLoaded?: boolean;
  cssClass?: string;
  hideContent?: boolean;
  small?: boolean;
  text?: string[];
};

const Loader = (props: PropsType) => {
  return (
    <>
      {(props.isLoaded === undefined || !props.isLoaded) && (
        <div
          className={classNames(
            'loader loader--small',
            props.cssClass,
            props.isLoaded && 'loader--loaded',
            props.opacity && 'loader--overlay',
            props.hideContent && 'hide-background'
          )}
          style={props.opacity ? { background: `rgba(255, 255, 255, ${props.opacity})` } : {}}
        >
          <div>
            {props.text && props.text.length > 0 && (
              <div className="loader__text">
                {props.text.map((t, i) => (
                  <p key={i} className="loader__msg">
                    {t}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div>
            <div className="lds-css ng-scope">
              <div className="lds-wedges">
                <div>
                  <div>
                    <div />
                  </div>

                  <div>
                    <div />
                  </div>

                  <div>
                    <div />
                  </div>

                  <div>
                    <div />
                  </div>
                </div>
              </div>
              {props.main && <p className="loader__msg">Loading</p>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
