import { Form, Formik } from 'formik';
import React from 'react';
import { Button, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { FormGroup, FORM_GROUP_VARIANTS } from '../../../core/components/Forms/FormGroup';
import SimpleInput from '../../../core/components/Forms/SimpleInput';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { ILanguage } from '../../../core/types/ILanguage';
import { TranslationsFilters } from '../types/TranslationsFilters';
import { GLOBAL_KEY } from './../helpers/const';

export default (props: {
  languages: ILanguage[];
  onFilter: (searchValue: string, langCode: string, moduleName: string) => void;
}) => {
  const getModulesOptions = () => {
    const result = [];
    for (const moduleName in ModuleNamesList) {
      result.push({ key: moduleName, value: moduleName });
    }

    return [
      {
        key: GLOBAL_KEY,
        value: TranslationService.translateModule('Global', ModuleNamesList.TranslationsManagement),
      },
      ...result,
    ];
  };

  const getLanguageCodeOptions = () => {
    return props.languages.map((l) => ({ key: l.code, value: TranslationService.translate(l.name) }));
  };

  return (
    <Formik
      initialValues={{
        searchValue: '',
        langCode: 'en-us',
        moduleName: GLOBAL_KEY,
      }}
      onSubmit={(values: TranslationsFilters) => {
        props.onFilter(values.searchValue, values.langCode, values.moduleName);
      }}
    >
      {({ values, handleChange, handleSubmit }) => {
        return (
          <Form className="c-form">
            <div className="container container--force">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-6 col-xl-3">
                  <FormGroup
                    variant={FORM_GROUP_VARIANTS.BLOCK}
                    name="searchValueFilter"
                    label={TranslationService.translateModule('Search', ModuleNamesList.TranslationsManagement)}
                    render={(name: any) => (
                      <SimpleInput
                        id="searchValueFilterInput"
                        name={name}
                        value={values.searchValue}
                        onChange={(value: string) => handleChange('searchValue')(value)}
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-8 col-lg-6 col-xl-3">
                  <FormGroup
                    variant={FORM_GROUP_VARIANTS.BLOCK}
                    name="moduleNameFilter"
                    label={TranslationService.translateModule('Module', ModuleNamesList.TranslationsManagement)}
                    render={(name: string) => (
                      <SimpleSelect
                        id="moduleNameFilter"
                        options={getModulesOptions()}
                        value={values.moduleName}
                        name={name}
                        onChangeHandle={(selectedValue: ISelectedValue) =>
                          handleChange('moduleName')(selectedValue.key)
                        }
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-8 col-lg-6 col-xl-3">
                  <FormGroup
                    variant={FORM_GROUP_VARIANTS.BLOCK}
                    name="langCodeFilter"
                    label={TranslationService.translateModule('LanguageCode', ModuleNamesList.TranslationsManagement)}
                    render={(name: string) => (
                      <SimpleSelect
                        id="langCodeFilter"
                        options={getLanguageCodeOptions()}
                        value={values.langCode}
                        name={name}
                        onChangeHandle={(selectedValue: ISelectedValue) => handleChange('langCode')(selectedValue.key)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-auto mr-auto align-self-end">
                  <Button
                    id="TranslationsManagementFilterBtn"
                    className="c-btn c-btn--primary c-btn--sm"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    type="submit"
                    label={TranslationService.translateModule('Filter', ModuleNamesList.CustomerTransactionsList)}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
