import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import Loader from '../../core/components/Loading/Loader';
import useEffectAsync from '../../core/hooks/useEffectAsync';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import UsersApi from './api/UsersApi';
import { User } from './types/User';
import './_user-statistics.scss';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { Card } from '../../core/components/Card/Card';
import { CardHeader } from '../../core/components/Card/components/CardHeader';

export default (props: IExtendedModuleProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const users = await UsersApi.getUsers(props.module.id);
      setUsers(users);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const convertToChartData = (usersData: User[]) => {
    var lockedUsers = usersData.filter((u) => u.isLocked);
    var unlockedUsers = usersData.filter((u) => !u.isLocked);

    return [
      {
        title: TranslationService.translateModule('LockedUsersTitle', ModuleNamesList.UsersStatistics),
        color: '',
        textValue: TranslationService.translateModule('LockedUsersTitle', ModuleNamesList.UsersStatistics),
        subHeader: `${Math.round((lockedUsers.length * 100) / users.length)}%`,
        value: lockedUsers.length,
      },
      {
        title: TranslationService.translateModule('UnlockedUsersTitle', ModuleNamesList.UsersStatistics),
        color: '',
        textValue: TranslationService.translateModule('UnlockedUsersTitle', ModuleNamesList.UsersStatistics),
        subHeader: `${Math.round((unlockedUsers.length * 100) / users.length)}%`,
        value: unlockedUsers.length,
      },
    ];
  };

  useEffectAsync(async () => {
    await fetchData();
  }, []);

  return (
    <Card class="mt-3">
      <>
        <CardHeader>
          <h1 className="c-heading">
            {TranslationService.translateModule('UsersStatisticsHeader', ModuleNamesList.UsersStatistics)}
          </h1>
        </CardHeader>

        <CardContent>
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="users-statistics">
                <div className="chart">
                  <div className="chart__main">
                    <div className="invoices-counter">
                      <p className="count">{users.length}</p>

                      <p className="desc">
                        {TranslationService.translateModule('TotalUsers', ModuleNamesList.UsersStatistics)}
                      </p>
                    </div>
                    <PieChart data={convertToChartData(users)} lineWidth={34} labelPosition={115} />
                  </div>
                  <ul className="chart__legend">
                    {convertToChartData(users).map((elem: any, index: number) => {
                      return (
                        <li key={index} data-value={elem.subHeader}>
                          {elem.textValue}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </>
        </CardContent>
      </>
    </Card>
  );
};
