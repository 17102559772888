import { IModuleEvent } from '../types/IModuleEvent';
import { IModuleEventSubscription } from '../types/IModuleEventSubscription';
import { eventEmmiter } from '../utils';
import { filter } from 'rxjs/operators';

interface ISub {
  unsubscribe: () => void;
}

interface IModuleSub {
  moduleEventSubscription: IModuleEventSubscription;
  sub: ISub;
}

class ModuleEventSubscriber {
  private moduleSubscriptions: IModuleSub[] = [];
  private eventEmitter = eventEmmiter;

  public emitEvent(moduleEvent: IModuleEvent) {
    this.eventEmitter.next(moduleEvent);
  }

  public registerEvents(moduleEvents: IModuleEventSubscription[]) {
    moduleEvents.forEach((eventSubscription: IModuleEventSubscription) => {
      const subscription = this.eventEmitter
        .pipe(filter((event) => event.name === eventSubscription.name))
        .subscribe((event) => eventSubscription.callback(event.data));

      this.moduleSubscriptions.push({ moduleEventSubscription: eventSubscription, sub: subscription });
    });
  }

  public unsubscribeEvents(moduleEvents: IModuleEventSubscription[]) {
    this.moduleSubscriptions
      .filter((s) => moduleEvents.indexOf(s.moduleEventSubscription) >= 0)
      .forEach((s) => s.sub.unsubscribe());
  }
}

export default new ModuleEventSubscriber();
