import { IActionStatus } from '../../core/api/types/IActionStatus';
import CreateNewCaseApiClient from './api/CreateNewCaseApiClient';
import { IDebtorDetails } from './types/IDebtorDetails';
import { IClaimInfo } from './components/IClaimInfo';
import { IInvoiceDetails } from './types/IInvoiceDetails';
import { ICaseNewMessage } from './types/ICaseNewMessage';

class CreateNewCaseService {
  public async createNewCase(
    debtorDetails: IDebtorDetails,
    claimInfo: IClaimInfo,
    invoicesList: IInvoiceDetails[],
    message: ICaseNewMessage,
    moduleInstanceId: number
  ): Promise<IActionStatus> {
    const formData = new FormData();
    invoicesList.forEach((invoice: IInvoiceDetails, index: number) => {
      invoice.attachedFiles.forEach((file: File) => {
        formData.append('file' + index.toString(), file, file.name);
      });
      invoice.attachedFiles = [];
    });

    formData.append('debtorDetails', JSON.stringify(debtorDetails));
    formData.append('invoicesList', JSON.stringify(invoicesList));
    formData.append('claimInfo', JSON.stringify(claimInfo));
    formData.append('moduleInstanceId', moduleInstanceId.toString());
    formData.append('messageDetails', JSON.stringify(message));

    return await CreateNewCaseApiClient.createNewCase(formData);
  }
}

export default new CreateNewCaseService();
