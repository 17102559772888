import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IInvoiceDetails } from '../types/IInvoiceDetails';

interface IState {
  isLoading: boolean;
}

interface IProps {
  invoicesList: IInvoiceDetails[];
  attachFiles(files: FileList, index: number): void;
  removeInvoice(index: number): void;
  updateInvoice(index: number, invoice: IInvoiceDetails): void;
}

class InvoicesList extends React.PureComponent<IProps, IState> {
  state: IState = {
    isLoading: false,
  };

  columns: any[] = [
    {
      dataField: 'number',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderInvoice', ModuleNamesList.CreateNewCase),
    },
    {
      dataField: 'dateAdd',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderDateAdd', ModuleNamesList.CreateNewCase),
      formatter: (value: any) => DateTimeParser.toLocaleDateString(value),
    },
    {
      dataField: 'dateDue',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderDateDue', ModuleNamesList.CreateNewCase),
      formatter: (value: any) => DateTimeParser.toLocaleDateString(value),
    },
    {
      dataField: 'amount',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderAmount', ModuleNamesList.CreateNewCase),
    },
    {
      dataField: 'reminderFee',
      text: TranslationService.translateModule('CaseInfoReminderFee', ModuleNamesList.CreateNewCase),
    },
    {
      dataField: 'currency',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderCurrency', ModuleNamesList.CreateNewCase),
    },
    {
      dataField: 'interest',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderInterest', ModuleNamesList.CreateNewCase),
      formatter: (value: any) => (isNaN(parseFloat(value)) ? value : `${value} %`),
    },
    {
      dataField: 'actions',
      text: TranslationService.translateModule('CaseInfoInvoicesListHeaderActions', ModuleNamesList.CreateNewCase),
      isDummyField: true,
      formatter: (value: any, invoice: IInvoiceDetails, index: number) => {
        return (
          <div className="bt-table__action-buttons" key={`bt-table__action-buttons_${index}`}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-remove`}>
                  {TranslationService.translateModule(
                    'CaseInfoInvoicesListRemoveInvoice',
                    ModuleNamesList.CreateNewCase
                  )}
                </Tooltip>
              }
            >
              <i className="fas fa-trash" onClick={() => this.props.removeInvoice(index)} />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-upload`}>
                  {TranslationService.translateModule('CaseInfoInvoicesListUploadFile', ModuleNamesList.CreateNewCase)}
                </Tooltip>
              }
            >
              <label htmlFor={`invoice${index}`}>
                <input
                  type="file"
                  id={`invoice${index}`}
                  multiple={true}
                  className="d-none"
                  onChange={(e: any) => {
                    this.props.attachFiles(e.target.files, index);
                    e.target.value = null;
                  }}
                />
                <i className="fas fa-paperclip" />
                {invoice.attachedFiles.length > 0 ? (
                  <span className="small-counter">{`(${invoice.attachedFiles.length})`}</span>
                ) : (
                  ''
                )}
              </label>
            </OverlayTrigger>
            {invoice.attachedFiles.length > 0 ? (
              <div key={`invoice_${index}_files`}>
                {invoice.attachedFiles.map((f, fileIdx) => (
                  <div key={fileIdx}>
                    {f.name}{' '}
                    <i
                      className="fas fa-times"
                      key={`invoice_${index}_files_${fileIdx}`}
                      onClick={() => {
                        this.deleteFile(index, invoice, f);
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  deleteFile = (index: number, invoice: IInvoiceDetails, file: File) => {
    const fileIndex = invoice.attachedFiles.indexOf(file);
    const newInvoice = {
      ...invoice,
      attachedFiles: [...invoice.attachedFiles.slice(0, fileIndex), ...invoice.attachedFiles.slice(fileIndex + 1)],
    };

    this.props.updateInvoice(index, newInvoice);
  };

  render() {
    return (
      <div className="row mt-3">
        <div className="col-12">
          <BootstrapTable
            wrapperClasses="bt"
            classes={'bt__table bt-table'}
            keyField="number"
            data={this.props.invoicesList}
            columns={this.columns}
          />
        </div>
      </div>
    );
  }
}

export default InvoicesList;
