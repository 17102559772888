import { useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { SettingsType } from '../../../core/Enums/SettingsType';

const SaveButton = (props: {
  value: EditorValue | string;
  isLoading: boolean;
  onUpdate: (value: string) => void;
  inToolbar?: boolean;
}) => (
  <div className="stce-rich-editor__button-group">
    <Button
      key="svBtn"
      type="button"
      className="c-btn c-btn--icon-only c-btn--1"
      icon={{ className: 'far fa-save', position: 'left' }}
      variant={BUTTON_VARIANTS.PRIMARY}
      size={BUTTON_SIZE.MD}
      showLoader={props.isLoading}
      disabled={props.isLoading}
      onClick={() => {
        props.onUpdate(typeof props.value === 'string' ? props.value : props.value.toString('html'));
      }}
    />
  </div>
);

const CancelButton = (props: { inToolbar?: boolean; onClose: () => void }) => (
  <div className="stce-rich-editor__button-group">
    <Button
      type="button"
      key="cnclBtn"
      className="c-btn c-btn--icon-only c-btn--1"
      icon={{ className: 'fas fa-ban', position: 'left' }}
      variant={BUTTON_VARIANTS.PRIMARY}
      size={BUTTON_SIZE.MD}
      onClick={props.onClose}
    />
  </div>
);

const SettingsTypeCellEditor = (props: {
  type: number;
  value: string;
  row: any;
  isLoading: boolean;
  onUpdate: (rowId: string, val: string) => void;
  onClose: () => void;
}) => {
  const [rteValue, setRteValue] = useState(RichTextEditor.createValueFromString(props.value || '', 'html'));
  const [simpleValue, setSimpleValue] = useState(props.value || '');

  return (
    <>
      {props.type === SettingsType.HtmlString ? (
        <>
          <RichTextEditor
            autoFocus={true}
            value={rteValue}
            onChange={(val: EditorValue) => setRteValue(val)}
            customControls={[
              <SaveButton
                key={'1'}
                inToolbar={true}
                value={rteValue}
                onUpdate={(val) => props.onUpdate(props.row.id, val)}
                isLoading={props.isLoading}
              />,
              <CancelButton key={'2'} inToolbar={true} onClose={props.onClose} />,
            ]}
            toolbarClassName="stce-rich-editor__toolbar"
            className="stce-rich-editor"
            toolbarConfig={{
              display: ['INLINE_STYLE_BUTTONS'],
              INLINE_STYLE_BUTTONS: [
                {
                  label: 'Bold',
                  style: 'BOLD',
                  className: 'c-btn c-btn--1 c-btn--md',
                },
              ],
              BLOCK_TYPE_DROPDOWN: [],
              BLOCK_TYPE_BUTTONS: [],
              BLOCK_ALIGNMENT_BUTTONS: [],
            }}
          />
        </>
      ) : (
        <div className="stce">
          <div className="stce__input-container">
            <input
              autoFocus
              type="text"
              className="form-control"
              defaultValue={simpleValue}
              onChange={(e: any) => {
                setSimpleValue(e.target.value);
              }}
            />
          </div>
          <div className="stce__buttons-container">
            <SaveButton
              onUpdate={(val) => props.onUpdate(props.row.id, val)}
              value={simpleValue}
              isLoading={props.isLoading}
            />
            <CancelButton onClose={props.onClose} />
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsTypeCellEditor;
