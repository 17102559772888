import React from 'react';
import TranslationService from '../../../core/services/TranslationService';
import { IListInputElement } from './types/IListInputElement';

interface IListInputs {
  className?: string;
  label?: string;
  col?: number;
  availableSelectAll?: boolean;
  allInputsSelected?: boolean;
  listInputs: IListInputElement[];
  selectedInputs: string[];
  disabledOverflowEnabled?: boolean;
  markWithActiveClass?: boolean;
  onAllSelect?: () => void;
  onSelect: (value: IListInputElement) => void;
}

const ListInputs = (props: IListInputs) => {
  const colClassNames = props.col ? `col-12 col-sm-6 col-md-4 col-lg-${12 / props.col}` : '';

  const getIdName = (name: string | undefined) => {
    let idName = name ? name.replace(/\s/g, '') : '';
    idName = idName.charAt(0).toLowerCase() + idName.substring(1);

    return idName;
  };

  return (
    <div
      className={`c-input-list c-input-list--2 ${
        props.className ? `c-input-list__list--${props.className}` : ''
      } position-relative ${props.disabledOverflowEnabled ? 'c-creator-list--opacity-5' : ''}`}
    >
      {props.label && <p className="c-input-list__label">{props.label}</p>}

      <ul className="c-input-list__list row">
        {props.disabledOverflowEnabled && (
          <div className="opacity-box opacity-box-background opacity-box-background--transparent" />
        )}

        {props.availableSelectAll && (
          <li key="0" className={`c-input-list__item ${props.col ? colClassNames : ''}`} onClick={props.onAllSelect}>
            {TranslationService.translate('SelectAll')}
            {props.allInputsSelected ? <i className="far fa-check-square" /> : <i className="far fa-square" />}
          </li>
        )}

        {props.listInputs &&
          props.listInputs.map((item: IListInputElement, index: number) => {
            const isSelectedItem =
              props.markWithActiveClass && props.selectedInputs && props.selectedInputs.indexOf(item.key as any) > -1;
            const idName = getIdName(item.value);

            return (
              <li
                key={index}
                id={idName}
                className={`c-input-list__item ${props.col ? colClassNames : ''} ${
                  isSelectedItem ? 'c-input-list__item--active' : ''
                }`}
                onClick={() => props.onSelect(item)}
              >
                <div className="c-input-list__item-content">
                  {item.value}

                  {props.selectedInputs && props.selectedInputs.indexOf(item.key as any) > -1 ? (
                    <i className="far fa-check-square" />
                  ) : (
                    <i className="far fa-square" />
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ListInputs;
