import React from 'react';
import { connect } from 'react-redux';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { PermissionNames } from '../../core/helpers/PermissionNames';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import UserIdentityService from '../../core/services/UserIdentityService';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IStore } from '../../reducers/IStore';
import Filters from './components/Filters';
import Footer from './components/Footer';
import CustomerTransactionsListTableConfigurator from './helpers/CustomerTransactionsListTableConfigurator';
import CustomerTransactionsListService from './services/CustomerTransactionsListService';
import { CustomerTransactionProductType } from './types/CustomerTransactionProductType';
import { CustomerTransactionsListTableColumns } from './types/CustomerTransactionsListTableColumns';
import { ICustomerTransactionsListItem } from './types/ICustomerTransactionsListItem';
import { ICustomerTransactionsWithLockFilters } from './types/ICustomerTransactionsWithLockFilters';
import { saveAs } from 'file-saver';
import CustomerTransactionsListApi from './api/CustomerTransactionsListApi';
import {
  ICustomerTransactionsInitialSettings,
  emptyCustomersInitialSettings,
} from './types/ICustomerTransactionsInitialSettings';
import {
  CustomerTransactionsSelectFilter,
  getCustomerTransactionsSelectFilter,
} from './types/CustomerTransactionsSelectFilter';

interface IState {
  expanded: number[];
  isFilterSectionLoading: boolean;
  isLoading: boolean;
  initialDataLoaded: boolean;
  moduleSettings: ICustomerTransactionsInitialSettings;
  page: number;
  pageSize: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  total: number;
  totalCapital: number;
  totalBalance: number;
  data: IComponentTable<ICustomerTransactionsListItem>;
  filters: ICustomerTransactionsWithLockFilters;
}

class CustomerTransactionsList extends React.Component<IExtendedModuleProps, IState> {
  private userIdentityService: UserIdentityService = new UserIdentityService();

  public module: any = flatModuleOptions<any>(this.props.module as any);
  public state: IState = {
    expanded: [],
    isFilterSectionLoading: false,
    isLoading: false,
    initialDataLoaded: false,
    page: 1,
    pageSize: 10,
    sortBy: CustomerTransactionsListTableColumns[CustomerTransactionsListTableColumns.DueDate],
    moduleSettings: { ...emptyCustomersInitialSettings },
    total: 0,
    totalCapital: 0,
    totalBalance: 0,
    sortDirection: 'desc',
    data: {
      columns: [],
      values: [],
    },
    filters: {
      isAccountNoLocked: false,
      includeFullyPaid: false,
      errors: [],
    },
  };

  constructor(props: IExtendedModuleProps) {
    super(props);
    this.mapCurrentFolder();
  }

  public componentDidMount = async () => {
    await this.fetchSettings();
    if (this.props.routeParameters['accountNo']) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            filterByValue: this.props.routeParameters['accountNo'] as string,
            filteredBy: 0,
            isAccountNoLocked: true,
          },
        },
        this.loadData
      );
    } else if (this.props.routeParameters['product']) {
      let productStr = this.props.routeParameters['product'] as string;
      productStr = productStr.substr(0, 1).toUpperCase() + productStr.substr(1);
      let val = '0';
      for (const ctp in CustomerTransactionProductType) {
        if (ctp === productStr) {
          val = CustomerTransactionProductType[ctp].toString();
        }
      }

      this.setState(
        {
          filters: {
            ...this.state.filters,
            product: val,
          },
        },
        this.loadData
      );
    } else {
      this.loadData();
    }
  };

  public fetchSettings = async () => {
    this.setState({ isFilterSectionLoading: true });
    const settings = await CustomerTransactionsListApi.getInitialSettings(this.props.module.id);
    this.setState({
      moduleSettings: settings,
      isFilterSectionLoading: false,
    });
  };

  public async componentDidUpdate(prevProps: IExtendedModuleProps) {
    if (prevProps.creditors !== this.props.creditors) {
      await this.loadData();
    }
  }

  public render() {
    if (!this.userIdentityService.hasPermission(PermissionNames.CanReadArmTransactionsList)) {
      return <NoPermissionsComponent />;
    }

    return (
      <article className="l-module ctl">
        <section className="l-module__section l-module__section--head">
          <h1 className="l-module__title">
            <i className="fas fa-file-invoice" />
            {TranslationService.translateModule('LeftMenuInvoices', ModuleNamesList.CustomerTransactionsList)}:
            <strong className="l-module__title-highlighted">
              {TranslationService.translateModule('CustomerTransactions', ModuleNamesList.CustomerTransactionsList)}
            </strong>
          </h1>
        </section>

        {this.state.moduleSettings.enableFiltersSection && (
          <section className="l-module__section l-module__section--filter mb-5">
            {this.state.isFilterSectionLoading && <Loader opacity={0.5} />}
            <Filters
              initialFilters={this.state.filters}
              filterByOptions={this.state.moduleSettings.filterByOptions}
              onFilter={this.onFilter}
            />
          </section>
        )}

        <section className={`l-module__section ${this.state.isLoading ? 'l-module__section--loading' : ''}`}>
          {(!this.state.initialDataLoaded || this.state.isLoading) && <Loader opacity={0.5} />}

          <BootstrapTableContainer
            remote={true}
            data={this.state.data.values}
            paginationProps={{
              page: this.state.page,
              sizePerPage: this.state.pageSize,
              totalSize: this.state.total,
            }}
            onExport={this.state.moduleSettings.exportEnabled ? this.onExport : undefined}
            classes="bt__table bt-table"
            keyField="id"
            wrapperClasses="bt"
            columns={CustomerTransactionsListTableConfigurator.getTableColumns(
              this.state.data,
              this.openPostponeInvoiceModal,
              this.openDebtorDetailsModal
            )}
            onTableChange={this.onTableChange}
          />
        </section>

        {this.state.initialDataLoaded && this.state.moduleSettings.enableSummarySection ? (
          <section className="l-module__section l-module__section--p-0 mt-3">
            <Footer
              total={this.state.total}
              totalBalance={this.state.totalBalance}
              totalCapital={this.state.totalCapital}
              currency={this.props.currency}
            />
          </section>
        ) : null}
      </article>
    );
  }

  private openPostponeInvoiceModal = (
    creditorNo: string,
    accountNo: string,
    invoiceNo: string,
    originalDueDate: Date
  ) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.PostponeInvoiceModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT: TranslationService.translateModule(
            'ActionsPostponeInvoice',
            ModuleNamesList.CustomerTransactionsList
          ),
          MODAL_HEADER_ICON: 'fas fa-calendar',
        },
        otherData: {
          creditorNo,
          accountNo,
          invoiceNo,
          originalDueDate,
          onExit: this.loadData,
        },
      },
    });
  };

  private openDebtorDetailsModal = (creditorNo: string, accountNo: string, invoiceNo: string) => {
    ModuleEventSubscriber.emitEvent({
      name: getModalOpenEvent(this.module.DebtorDetailsModalModuleInstanceId),
      data: {
        modalData: {
          MODAL_HEADER_TEXT: TranslationService.translateModule(
            'DebtorDetailsModal',
            ModuleNamesList.CustomerTransactionsList
          ),
          MODAL_HEADER_ICON: 'fas fa-user',
        },
        otherData: {
          creditorNo,
          accountNo,
          invoiceNo,
        },
      },
    });
  };

  private onTableChange = (type: any, { page, sizePerPage, sortField, sortOrder, data }: any) => {
    this.setState(
      {
        page,
        pageSize: sizePerPage,
        sortBy: sortField ? this.convertToSortByNumber(sortField) : this.state.sortBy,
        sortDirection: sortOrder ? sortOrder : this.state.sortDirection,
      },
      this.loadData
    );
  };

  private onFilter = (filters: ICustomerTransactionsWithLockFilters) => {
    this.setState(
      {
        filters,
        page: 1,
      },
      this.loadData
    );
  };

  private loadData = async () => {
    this.setState({
      isLoading: true,
    });

    const transactions = await CustomerTransactionsListService.getCustomerTransactions({
      moduleInstanceId: this.props.module.id,
      filters: {
        ...this.state.filters,
        filteredBy:
          this.state.filters.filteredBy && this.state.filters.filteredBy.toString() === 'all'
            ? undefined
            : this.state.filters.filteredBy,
      },
      page: this.state.page,
      pageSize: this.state.pageSize,
      sortBy: this.state.sortBy,
      orderingDirection: this.state.sortDirection,
    });

    this.setState({
      isLoading: false,
      initialDataLoaded: true,
      data: {
        ...transactions.data,
        values: transactions.data.values.map((i, idx) => ({ ...i, id: idx, actions: false })),
      },
      total: transactions.total,
      totalBalance: transactions.totalBalance,
      totalCapital: transactions.totalCapital,
    });
  };

  private onExport = async () => {
    const blob = await CustomerTransactionsListService.getCustomerTransactionsAsFile({
      moduleInstanceId: this.props.module.id,
      filters: {
        ...this.state.filters,
        filteredBy:
          this.state.filters.filteredBy && this.state.filters.filteredBy.toString() === 'all'
            ? undefined
            : this.state.filters.filteredBy,
      },
      page: -1,
      pageSize: -1,
      sortBy: this.state.sortBy,
      orderingDirection: this.state.sortDirection,
    });

    saveAs(
      blob,
      `${TranslationService.translateModule(
        'LeftMenuInvoices',
        ModuleNamesList.CustomerTransactionsList
      )} - ${TranslationService.translateModule('CustomerTransactions', ModuleNamesList.CustomerTransactionsList)}.csv`,
      { autoBom: true }
    );
  };

  private convertToSortByNumber = (sortField: string) => {
    return CustomerTransactionsListTableColumns[sortField.charAt(0).toUpperCase() + sortField.slice(1)];
  };

  /*eslint-disable */
  private mapCurrentFolder = () => {
    if (this.props.routeParameters.filteredBy && this.props.routeParameters.filteredByValue) {
      const filteredBy: CustomerTransactionsSelectFilter = getCustomerTransactionsSelectFilter(
        this.props.routeParameters.filteredBy.toString()
      );
      this.state.filters.filteredBy = filteredBy;
      this.state.filters.filterByValue = this.props.routeParameters.filteredByValue.toString();
    }
  };
  /*eslint-enable */
}

const mapStateToProps = (state: IStore) => {
  return {
    currency: state.currency,
    creditors: state.currentCreditors,
  };
};

export default connect(mapStateToProps)(CustomerTransactionsList);
