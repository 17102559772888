import React from 'react';
import TextValue from '../../../../core/components/TextValue/TextValue';
import Accordion from '../../../../core/components/Accordion/Accordion';
import styles from './ConversationList.module.scss';
import DateTimeParser from '../../../../core/helpers/DateTimeParser';
import { ConversationType } from '../../types/ConversationType';
import TranslationService from '../../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';

type PropsType = {
  conversations: ConversationType[];
  selectedConversationId: number;
  onChange: (conversationId: number, creditorNumber: string) => void;
};

const ConversationList = (props: PropsType) => {
  const handleOnToggle = (conversationId: number) => {
    if (props.selectedConversationId !== conversationId) {
      const conversation = props.conversations.find((c) => {
        return c.id === conversationId;
      });

      if (conversation) {
        props.onChange(conversation.id, conversation.caseDetails.creditor.number);
      }
    }
  };

  return (
    <Accordion
      className={styles.wrapper}
      currentOpen={props.selectedConversationId}
      onToggle={(conversationId: number) => handleOnToggle(conversationId)}
    >
      {props.conversations.map((c) => {
        return (
          <Accordion.Item key={c.id.toString()} className={styles.conv}>
            <Accordion.Header index={c.id} className={styles.convHeader} activeClass={styles.convHeaderActive}>
              <div className={styles.info}>
                <div className="row">
                  <div className="col-auto">
                    <p className={styles.coversationDate}>{DateTimeParser.toLocaleDateString(c.dueDate)}</p>
                  </div>

                  <div className="col">
                    <p className={styles.coversationId}>{c.caseDetails.caseNumber}</p>

                    <p>{`${c.debtor.firstName} ${c.debtor.lastName} / ${c.debtor.number}`}</p>
                  </div>
                </div>
              </div>
            </Accordion.Header>

            <Accordion.Body index={c.id} className={styles.convBody}>
              <div className={styles.mmoreInfo}>
                <div className="row">
                  <div className="col-12 mb-3">
                    <TextValue
                      label={TranslationService.translateModule('CategoryLabel', ModuleNamesList.HomeMessages)}
                      value={c.category}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <TextValue
                      label={TranslationService.translateModule('DebtorLabel', ModuleNamesList.HomeMessages)}
                      value={`${c.debtor.firstName} ${c.debtor.lastName} / ${c.debtor.number}`}
                    />
                  </div>

                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <TextValue
                          label={TranslationService.translateModule('DueDateField', ModuleNamesList.HomeMessages)}
                          value={DateTimeParser.toLocaleDateString(c.dueDate)}
                        />
                      </div>

                      <div className="col-6">
                        <TextValue
                          label={TranslationService.translateModule('RegDateField', ModuleNamesList.HomeMessages)}
                          value={DateTimeParser.toLocaleDateString(c.dueDate)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default React.memo(ConversationList);
