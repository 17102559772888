import { ParsedQuery } from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import AutoOnboardingApiClient from './core/api/AutoOnboardingApiClient';
import { IPage } from './core/api/types/IPage';
import Loader from './core/components/Loading/Loader';
import { RoleNames } from './core/helpers/RoleNames';
import LayoutsList from './core/lists/LayoutList';
import RouteParamsService from './core/services/RouteParamsService';
import UserIdentityService from './core/services/UserIdentityService';
import {
  AutoOnboardingTokenStatus,
  emptyTokenData,
  IAutoOnboardingTokenData,
} from './core/types/IAutoOnboardingTokenData';
import { IPageProps } from './core/types/IPageProps';
import { IPageState } from './core/types/IPageState';
// TODO: Those urls should be configurable and fetched from global application settings
import {
  CREATE_NEW_CREDITOR_URL,
  CREDITOR_WAITING_FOR_APPROVAL_URL,
  REGISTER_USER_URL,
  ROOT_PAGE_URL,
} from './core/utils/constants';
import { TOKEN_REGEX } from './core/utils/helpers';
import { IStore } from './reducers/IStore';

const EmptyLayout = () => <div>[empty layout]</div>;
const LayoutNotFound = () => <div>[layout not found]</div>;

interface IAutoOnboardingState {
  isTokenAvailable: boolean;
  isTokenLoaded: boolean;
  tokenData: IAutoOnboardingTokenData;
  shouldRegisterNewCreditor: boolean;
}

class PageRenderer extends React.Component<IPageProps & RouteComponentProps> {
  public state: IPageState & IAutoOnboardingState = {
    isLoading: true,
    isTokenAvailable: false,
    isTokenLoaded: false,
    tokenData: emptyTokenData,
    shouldRegisterNewCreditor: false,
  };

  private userIdentityService = new UserIdentityService();

  private ensureSignedInOrPublicPageAccessed() {
    if (this.shouldStartUserAuthentication()) {
      this.startAuthentication();
    }
  }

  public componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.ensureSignedInOrPublicPageAccessed();
    this.loadAutoOnboardingTokenData().then(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  public render() {
    const onboardingToken = this.getAutoOnboardingTokenFromQueryStringOrProps();
    const shouldStartAuth = this.shouldStartUserAuthentication();
    let pageToSearch = this.props.match.params.page;

    const loadingPage = this.startAuthOrShowLoadingPage(shouldStartAuth);

    if (loadingPage) {
      return loadingPage;
    }

    if (onboardingToken && this.isCurrentlyLoggedUserOwnerOfToken()) {
      pageToSearch = this.removeTokenFromPageUrlIfHomepage(pageToSearch);

      const redirectUrl = this.getRedirectUrlIfOnboardingNeedsSomeActions(onboardingToken as string);

      if (redirectUrl) {
        if (redirectUrl !== window.location.href.replace(window.location.origin + '/', '')) {
          return <Redirect to={redirectUrl} />;
        }
      }

      const page = pageToSearch
        ? this.props.pages.find((p) => p.pageUrl.toLowerCase() === pageToSearch.toLowerCase())
        : this.props.pages.find((p) => p.pageUrl === ROOT_PAGE_URL);

      return (
        <Page
          currentPage={page}
          key={this.props.location.pathname + this.props.location.search}
          routeParameters={this.getRouterParams()}
        />
      );
    } else {
      if (pageToSearch === 'autoonboarding-error') {
        // replace enforced backend autoonboarding error when in context of another user
        return <Redirect to="/" />;
      }

      const page = pageToSearch
        ? this.props.pages.find((p) => p.pageUrl.toLowerCase() === pageToSearch.toLowerCase())
        : this.props.pages.find((p) => p.pageUrl === ROOT_PAGE_URL);

      const redirectUrl = this.getRedirectUrlToCreditorRegistrationOrPendingApprovalIfNeeded(page);
      if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
      }

      return (
        <Page
          currentPage={page}
          key={this.props.location.pathname + this.props.location.search}
          routeParameters={this.getRouterParams()}
        />
      );
    }
  }

  private isCurrentlyLoggedUserOwnerOfToken() {
    if (this.state.tokenData.userEmail) {
      return this.state.tokenData.userEmail === this.userIdentityService.GetUserDetails().email;
    }

    return false;
  }

  private async loadAutoOnboardingTokenData() {
    const token = this.getAutoOnboardingTokenFromQueryStringOrProps();
    if (token) {
      const tokenData = await AutoOnboardingApiClient.GetOnboardingInitialData(token as string);
      const shouldRegisterNewCreditor = await AutoOnboardingApiClient.ShouldRegisterNewCreditor(token as string);

      this.setState({
        isTokenAvailable: true,
        tokenData,
        shouldRegisterNewCreditor,
        isTokenLoaded: true,
      });
    } else {
      this.setState({
        isTokenAvailable: false,
      });
    }
  }

  private getRouterParams = () => {
    let queryParameters = RouteParamsService.getQueryParameters();
    if (!this.isCreditorRegistrationNeededFromAutoOnboardingToken() && queryParameters['token']) {
      delete queryParameters['token'];
    }

    return queryParameters;
  };

  private getRedirectUrlToCreditorRegistrationOrPendingApprovalIfNeeded = (page: IPage | undefined) => {
    if (page && !page.isPublic && page.pageUrl !== CREATE_NEW_CREDITOR_URL) {
      if (this.shouldRedirectToCreditorsRegistration()) {
        return CREATE_NEW_CREDITOR_URL;
      } else if (this.shouldRedirectToWaitingForCreditorsApprovalPage()) {
        return CREDITOR_WAITING_FOR_APPROVAL_URL;
      }
    }

    return null;
  };

  private startAuthOrShowLoadingPage = (shouldStartAuth: boolean) => {
    if (this.state.isLoading || this.props.creditorsLoading || shouldStartAuth) {
      if (shouldStartAuth) {
        this.startAuthentication();
      }

      return <Page isLoading={true} routeParameters={this.getRouterParams()} />;
    }

    return null;
  };

  private getRedirectUrlIfOnboardingNeedsSomeActions = (onboardingToken: string): string => {
    if (
      this.state.tokenData.status === AutoOnboardingTokenStatus.FailedBankIdVerification ||
      this.state.tokenData.status === AutoOnboardingTokenStatus.PassedBankIdVerification
    ) {
      return `${REGISTER_USER_URL}?token=${onboardingToken as string}`;
    }
    if (this.state.tokenData.status === AutoOnboardingTokenStatus.PendingCreditorRegistration) {
      if (this.isCreditorRegistrationNeededFromAutoOnboardingToken()) {
        return `${CREATE_NEW_CREDITOR_URL}?token=${onboardingToken as string}`;
      }
    } else if (this.state.tokenData.status === AutoOnboardingTokenStatus.Done) {
      if (
        this.shouldRedirectToWaitingForCreditorsApprovalPage() ||
        (this.shouldRedirectToCreditorsRegistration() && this.props.creditors.length === 0)
      ) {
        return `autoonboarding-error?token=${onboardingToken}`;
      }
      if (this.shouldRedirectToWaitingForCreditorsApprovalPage()) {
        return CREDITOR_WAITING_FOR_APPROVAL_URL;
      }

      if (this.shouldRedirectToCreditorsRegistration()) {
        return CREATE_NEW_CREDITOR_URL;
      }

      return '';
    } else if (this.state.tokenData.status === AutoOnboardingTokenStatus.PendingUserRegistration) {
      return '';
    }

    return 'error';
  };

  private removeTokenFromPageUrlIfHomepage = (pageToSearch: string) => {
    const tokenToRemove = this.getTokenFromPageProp(pageToSearch);
    if (tokenToRemove) {
      const tokenToRemoveFromUrl = tokenToRemove[0];
      return pageToSearch.replace(tokenToRemoveFromUrl, '');
    }

    return pageToSearch;
  };

  private startAuthentication = () => {
    const pagePath = window.location.pathname + window.location.search || ROOT_PAGE_URL;
    this.userIdentityService.StartAuthentication(pagePath);
  };

  private shouldStartUserAuthentication = (): boolean => {
    const isLoggedIn = this.userIdentityService.IsLoggedIn();
    const pagePath = this.props.match.params.page || ROOT_PAGE_URL;
    const currentPage = this.props.pages.find(
      (page: IPage) => page.pageUrl.toLowerCase() === pagePath.toLowerCase()
    ) as IPage;
    return (!currentPage && !isLoggedIn) || (currentPage && !currentPage.isPublic && !isLoggedIn);
  };

  private shouldRedirectToCreditorsRegistration = () =>
    (this.props.creditors.length === 0 &&
      this.props.match.params.page !== CREATE_NEW_CREDITOR_URL &&
      !this.userIdentityService.isInRole(RoleNames.DCA_AGENCY)) ||
    this.isCreditorRegistrationNeededFromAutoOnboardingToken();

  private shouldRedirectToWaitingForCreditorsApprovalPage = () =>
    this.props.creditors.length > 0 &&
    this.props.creditors.findIndex((c) => c.isAuthorized) < 0 &&
    this.props.match.params.page !== CREDITOR_WAITING_FOR_APPROVAL_URL;

  private isCreditorRegistrationNeededFromAutoOnboardingToken = () => {
    if (this.state.isTokenAvailable) {
      return (
        this.state.tokenData.userEmail === this.userIdentityService.GetUserDetails().email &&
        this.state.shouldRegisterNewCreditor
      );
    }

    return false;
  };

  private getAutoOnboardingTokenFromQueryStringOrProps = () =>
    RouteParamsService.getQueryParamsFromRoute(this.props.match.url)['token'] ||
    RouteParamsService.getQueryParamsFromRoute(this.props.location.search)['token'];
  private getTokenFromPageProp = (page: string) => TOKEN_REGEX.exec(page);
}

const Page = (props: { currentPage?: IPage | undefined; isLoading?: boolean; routeParameters: ParsedQuery }) => {
  if (props.isLoading) {
    return <Loader opacity={1} />;
  }

  if (!props.currentPage) {
    return <Redirect to="error" />;
  }

  if (props.currentPage && !props.currentPage.layout) {
    return <EmptyLayout />;
  }

  const Layout = LayoutsList[props.currentPage.layout.name];
  return Layout ? (
    <Layout layout={props.currentPage.layout} routeParameters={props.routeParameters} />
  ) : (
    <LayoutNotFound />
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    pages: state.pages,
    creditors: state.creditors,
    creditorsLoading: state.creditorsLoading,
  };
};

export default connect(mapStateToProps, null)(PageRenderer);
