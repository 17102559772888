import RestApiClient from '../../core/api/RestApiClient';
import { IUserListElement } from './types/IUserListElement';
import { IComponentTable } from '../../core/types/IComponentTable';
import { IPortalUsersFilterRequest } from './types/IPortalUsersFilterRequest';
import { IActionStatus } from 'core/api/types/IActionStatus';

class PortalUsersListApiClient {
  public async getUsers(
    moduleId: number,
    request: IPortalUsersFilterRequest
  ): Promise<IComponentTable<IUserListElement>> {
    return await RestApiClient.post<IComponentTable<IUserListElement>>(
      `modules/PortalUsersList/getUsers/${moduleId}`,
      request,
      null,
      undefined,
      null,
      true
    );
  }
  public async changeUserLockStatus(userUId: string, setIsLocked: boolean): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(
      `modules/PortalUsersList/changeUserLockStatus/${userUId}/${setIsLocked}`
    );
  }
  public async resetPassword(userUId: string): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(`modules/PortalUsersList/resetUserPassword/${userUId}`);
  }
  public async deleteUser(userUId: string): Promise<IActionStatus> {
    return await RestApiClient.get<IActionStatus>(`modules/PortalUsersList/deleteUser/${userUId}`);
  }
}

export default new PortalUsersListApiClient();
