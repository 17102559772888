import * as React from 'react';
import { Button, BUTTON_COLORS, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import InfoMessageService from '../../../core/services/InfoMessageService';
import TranslationService from '../../../core/services/TranslationService';
import { AutoOnboardingTokenStatus, IAutoOnboardingTokenData } from '../../../core/types/IAutoOnboardingTokenData';
import StepListWizardButtons from '../../StepListWizard/StepListWizardButtons';
import RegisterUserApiClient from '../RegisterUserApiClient';
interface IBisnodeVerificationStepProps {
  goToNextStep: () => any;
  backToPrevStep: () => any;
  token: IAutoOnboardingTokenData | null;
}

interface IState {
  loading: boolean;
  verificationPassed: boolean;
}

class BisnodeVerification extends React.Component<IBisnodeVerificationStepProps, IState> {
  constructor(props: IBisnodeVerificationStepProps) {
    super(props);
    this.state = {
      loading: false,
      verificationPassed: false,
    };
  }
  private getMessage() {
    if (this.props.token) {
      if (this.props.token.status === AutoOnboardingTokenStatus.PassedBankIdVerification) {
        return TranslationService.translateModule('BisnodeVerificationMsg', ModuleNamesList.RegisterUserModuleName);
      } else if (this.props.token.status === AutoOnboardingTokenStatus.PassedBisnodeVerification) {
        return TranslationService.translateModule(
          'BisnodeVerificationPassedMsg',
          ModuleNamesList.RegisterUserModuleName
        );
      } else if (this.props.token.status === AutoOnboardingTokenStatus.FailedBisnodeVerification) {
        return TranslationService.translateModule(
          'BisnodeVerificationFailedMsg',
          ModuleNamesList.RegisterUserModuleName
        );
      }
    }

    return TranslationService.translateModule('BisnodeVerificationError', ModuleNamesList.RegisterUserModuleName);
  }

  private verificationAlreadyPassed = () => {
    if (this.props && this.props.token) {
      return (
        this.props.token.status === AutoOnboardingTokenStatus.PassedBisnodeVerification ||
        this.props.token.status === AutoOnboardingTokenStatus.FailedBisnodeVerification
      );
    }

    return false;
  };

  private getStepButtons() {
    if (this.props.token) {
      if (
        this.props.token.status === AutoOnboardingTokenStatus.PassedBisnodeVerification ||
        this.state.verificationPassed
      ) {
        return (
          <StepListWizardButtons
            rightButtonOnClick={this.props.goToNextStep}
            rightButtonClassName="float-right"
            rightButtonText={
              <>
                {TranslationService.translate('Next') + ' '}
                <i className="fas fa-angle-double-right next-button-arrow right" />
              </>
            }
          />
        );
      } else if (
        this.props.token.status === AutoOnboardingTokenStatus.FailedBisnodeVerification ||
        this.props.token.status === AutoOnboardingTokenStatus.PendingUserRegistration
      ) {
        return null;
      }
    }

    return null;
  }

  private callBisnodeVerification = async () => {
    this.setState({
      loading: true,
    });

    if (this.props.token) {
      try {
        const verificationPassed = await RegisterUserApiClient.CallBisnodeVerification(this.props.token.token);

        this.setState({
          verificationPassed: verificationPassed,
        });

        if (verificationPassed) {
          InfoMessageService.success('BisnodeVerificationPassedMsg', ModuleNamesList.RegisterUserModuleName);
        }
      } catch {
        InfoMessageService.error('BisnodeVerificationError', ModuleNamesList.RegisterUserModuleName);
      }
    }

    this.setState({
      loading: false,
    });
  };

  public render() {
    return (
      <div className="step-content">
        <header className="step-header">
          <h2 className="step-title">
            {TranslationService.translateModule('BisnodeVerificationStepTitle', ModuleNamesList.RegisterUserModuleName)}
          </h2>

          <p>{this.getMessage()}</p>
        </header>

        <div className="bisnode-content">
          {this.props.token && this.props.token.status === AutoOnboardingTokenStatus.PassedBankIdVerification && (
            <Button
              label={TranslationService.translateModule(
                'BisnodeVerificationBtn',
                ModuleNamesList.RegisterUserModuleName
              )}
              size={BUTTON_SIZE.MD}
              variant={BUTTON_VARIANTS.PRIMARY}
              color={BUTTON_COLORS.PRIMARY}
              className="mb-5"
              disabled={this.verificationAlreadyPassed() || this.state.loading || this.state.verificationPassed}
              showLoader={this.state.loading}
              onClick={this.callBisnodeVerification}
            />
          )}
          {this.getStepButtons()}
        </div>
      </div>
    );
  }
}

export default BisnodeVerification;
