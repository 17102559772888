import React from 'react';
import DateTimeParser from './../../../core/helpers/DateTimeParser';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import { ISelectedValue } from '../../../core/components/Forms/types/ISelectedValue';
import DatePicker from '../../../core/components/Forms/DatePicker';
import { Field } from 'formik';
import TranslationService from '../../../core/services/TranslationService';
import IReportParameter from '../types/IReportParameter';
import { toTitleCaseWithoutWhitespaces } from '../../../core/helpers/toTitleCaseWithoutWhitespaces';

interface IProps {
  data: IReportParameter;
  values: any;
  dateForm: string;
  handleChange: (date: any) => void;
  onChangeHandler: (data: any) => void;
}

interface ISelectedRangeDate {
  code: string;
  name: string;
}
interface IState {
  complexTypeValues: Array<ISelectedValue>;
  selectedRangeDate: ISelectedRangeDate;
}

export class DateSimple extends React.PureComponent<IProps, IState> {
  public state: IState = {
    complexTypeValues: [],
    selectedRangeDate: {
      code: '',
      name: '',
    },
  };

  componentDidMount() {
    this.prepareOptions();
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.dateForm !== prevProps.dateForm) {
      this.onChangeDateHandler(this.props.dateForm, this.props.data.itemParameters[0].name);
    }
  }

  render() {
    return (
      <section className="c-creator__box c-creator__box--decor">
        {this.props.data && (
          <>
            {this.props.data.itemParameters && this.props.data.itemParameters.length ? (
              this.props.data.itemParameters.map((item: any, index: number) => (
                <DateComponent
                  key={index}
                  data={item}
                  values={this.props.values}
                  handleChange={this.onChangeDateHandler}
                  dateForm={this.props.dateForm}
                />
              ))
            ) : (
              <DateComponent
                data={this.props.data}
                handleChange={this.onChangeDateHandler}
                dateForm={this.props.dateForm}
              />
            )}

            <div className="c-control row row--aligned">
              <label className="c-control__label col-12 col-sm-4">{TranslationService.translate('DateFrom')}</label>

              <div className="col-12 col-sm-8 icon-calendar default-form">
                {this.props.data.complexTypeValues && (
                  <SimpleSelect
                    id="selected-date"
                    name={`${this.props.data.name}`}
                    value={this.state.selectedRangeDate.code}
                    options={this.state.complexTypeValues}
                    onChangeHandle={(data: ISelectedValue) => this.hanldeDateRangeChange(data, this.props.data.name)}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </section>
    );
  }

  onChangeDateHandler = (date: any, name: string) => {
    this.props.handleChange({
      name: name,
      type: 'DateTime',
      value: DateTimeParser.toLocaleDateString(date),
    });
  };

  hanldeDateRangeChange = (selectedValue: ISelectedValue, name: string) => {
    this.props.onChangeHandler({
      name: `${name}_DateFrom_`,
      type: '_DateFrom_',
      value: selectedValue.key,
    });

    this.setState({
      selectedRangeDate: {
        name: selectedValue.value,
        code: selectedValue.key,
      },
    });
  };

  prepareOptions() {
    if (!this.props.data.complexTypeValues) {
      return;
    }

    const selectOptions = this.props.data.complexTypeValues.map((value: any) => {
      return {
        key: value.code,
        value: value.name,
      };
    });

    this.setState({
      complexTypeValues: selectOptions,
      selectedRangeDate: {
        ...this.state.selectedRangeDate,
        code: this.props.values[`${this.props.data.name}_DateFrom_Complex`] || '',
      },
    });
  }
}

const DateComponent = (props: any) => {
  const validationDate = (value: any) => {
    let error;

    if (!value) {
      error = TranslationService.translate('ValidationMessageRequiredField');
    }

    return error;
  };

  return (
    <Field name={props.data.name} validate={validationDate}>
      {({ field, form }: any) => (
        <div className="c-control row row--aligned">
          <label className="c-control__label col-12 col-sm-4" htmlFor={field.name}>
            {TranslationService.translate(toTitleCaseWithoutWhitespaces(props.data.prompt || ''), props.data.prompt)}
          </label>

          <div className="col-12 col-sm-8 icon-calendar default-form">
            <DatePicker
              onChange={(date) => {
                props.handleChange(date, props.data.name);
              }}
              inputId={props.data.name}
              inputName={props.data.name}
              value={props.values[field.name] || ''}
            />
          </div>

          {form.errors[field.name] && form.touched[field.name] && (
            <div className="col-12 c-input-error">{form.errors[field.name]}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default DateSimple;
