import React, { useEffect, useState } from 'react';
import { Button, BUTTON_SIZE, BUTTON_VARIANTS } from '../../../core/components/Button/Button';
import Select from '../../../core/components/Forms/Select';
import { Typography, TypographyVariants } from '../../../core/components/Typography/Typography';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import InfoMessageService from '../../../core/services/InfoMessageService';
import TranslationService from '../../../core/services/TranslationService';
import ManagePagePermissionsApiClient from '../api/ManagePagePermissionsApiClient';
import { IAccessPermission } from '../types/IAccessPermission';
import { IPermission } from '../types/IPermission';
import { IRole } from '../types/IRole';
import Loader from '../../../core/components/Loading/Loader';
import { PermissionType } from '../types/PermissionType';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

interface IProps {
  pageId: number;
}

const ManagePagePersmissions = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pagePermissions, setPagePermissions] = useState<IAccessPermission[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesOptions, setRoleOptions] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [permissionsOptions, setPermissionsOptions] = useState<any[]>([]);
  const [selectedPermission, setSelectedPermission] = useState(0);
  const [selectedRole, setSelectedRole] = useState<string>(EMPTY_GUID);

  const load = async () => {
    setIsLoading(true);
    try {
      await loadRoles();
      await loadPermissions();
      await loadPagePermissions();
    } catch {
      InfoMessageService.error(TranslationService.translate('ErrorLoadingData'));
    }

    setIsLoading(false);
  };

  const updateRoleOptions = async () => {
    const pagePermissionsIds = pagePermissions.filter((p) => p.type === PermissionType.Role).map((p) => p.value);
    setRoleOptions([
      {
        id: EMPTY_GUID,
        name: TranslationService.translateModule('SelectRole', ModuleNamesList.ManagePagePermissions),
      },
      ...roles
        .filter((r) => pagePermissionsIds.indexOf(r.uId) === -1)
        .map((r) => ({ id: r.uId, name: r.displayName }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    ]);
  };

  const updatePermissionOptions = async () => {
    const pagePermissionsIds = pagePermissions.filter((p) => p.type === PermissionType.Permission).map((p) => p.value);
    setPermissionsOptions([
      {
        id: 0,
        name: TranslationService.translateModule('SelectPermission', ModuleNamesList.ManagePagePermissions),
      },
      ...[
        ...permissions
          .filter((r) => pagePermissionsIds.indexOf(r.id.toString()) === -1)
          .map((r) => ({ id: r.id, name: r.displayName })),
      ].sort((a, b) => a.name.localeCompare(b.name)),
    ]);
  };

  const loadPagePermissions = async () => {
    const pagePermissions = await ManagePagePermissionsApiClient.getPageAccessPermissions(props.pageId);
    setPagePermissions(pagePermissions);
  };

  const loadRoles = async () => {
    const roles = await ManagePagePermissionsApiClient.getRoles();
    setRoles(roles);
  };

  const loadPermissions = async () => {
    const permissions = await ManagePagePermissionsApiClient.getPermissions();
    setPermissions(permissions);
  };

  const removePermission = (id: string) => {
    const pagePermissionToRemove = pagePermissions.filter(
      (p) => p.type === PermissionType.Permission && p.value === id
    )[0];
    const idx = pagePermissions.indexOf(pagePermissionToRemove);
    setPagePermissions([...pagePermissions.slice(0, idx), ...pagePermissions.slice(idx + 1)]);

    const originalPermissionToReadd = permissions.filter((r) => r.id === parseInt(id))[0];

    setPermissionsOptions([
      { ...permissionsOptions[0] },
      ...[
        ...permissionsOptions.slice(1),
        {
          id: originalPermissionToReadd.id,
          name: originalPermissionToReadd.displayName,
        },
      ].sort((a, b) => a.name.localeCompare(b.name)),
    ]);
  };

  const removeRole = (id: string) => {
    const pagePermissionToRemove = pagePermissions.filter((p) => p.type === PermissionType.Role && p.value === id)[0];
    const idx = pagePermissions.indexOf(pagePermissionToRemove);
    setPagePermissions([...pagePermissions.slice(0, idx), ...pagePermissions.slice(idx + 1)]);

    const originalRoleToReadd = roles.filter((r) => r.uId === id)[0];

    setRoleOptions([
      { ...rolesOptions[0] },
      ...[
        ...rolesOptions.slice(1),
        {
          id: originalRoleToReadd.uId,
          name: originalRoleToReadd.displayName,
        },
      ].sort((a, b) => a.name.localeCompare(b.name)),
    ]);
  };

  const savePermissions = async () => {
    setIsLoading(true);
    try {
      const result = await ManagePagePermissionsApiClient.savePageAccessPermissions(props.pageId, pagePermissions);
      InfoMessageService.displayActionStatus(result);
    } catch {
      InfoMessageService.error(TranslationService.translate('InfoMessageErrorTitle'));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [props.pageId]);

  useEffect(() => {
    const run = async () => {
      await updateRoleOptions();
      await updatePermissionOptions();
    };

    run();
  }, [pagePermissions]);

  return (
    <>
      <section className="l-module__section mt-4">
        {isLoading && <Loader />}
        <Select>
          <>
            <Select.Label>
              <Typography variant={TypographyVariants.Paragraph}>
                {TranslationService.translateModule('SelectRole', ModuleNamesList.ManagePagePermissions)}
              </Typography>
            </Select.Label>

            <Select.Options
              name="roleSelect"
              id="roleSelect"
              options={rolesOptions}
              onChange={async (option: any) => {
                setSelectedRole(option.id);
              }}
            />
          </>
        </Select>
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTON_SIZE.MD}
          label={TranslationService.translateModule('AddRole', ModuleNamesList.ManagePagePermissions)}
          disabled={selectedRole === EMPTY_GUID}
          onClick={() => {
            setPagePermissions([
              ...pagePermissions,
              {
                type: PermissionType.Role,
                value: selectedRole.toString(),
              },
            ]);
            const idx = rolesOptions.indexOf(rolesOptions.filter((r) => r.id === selectedRole)[0]);
            setRoleOptions([...rolesOptions.slice(0, idx), ...rolesOptions.slice(idx + 1)]);
            setSelectedRole(EMPTY_GUID);
          }}
        />
        <ul>
          {pagePermissions
            .filter((p) => p.type === PermissionType.Role)
            .map((p) => (
              <li key={`${p.type}_${p.value}`}>
                {roles.filter((r) => r.uId === p.value)[0].displayName}{' '}
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.MD}
                  icon={{ className: 'fas fa-times', position: 'left' }}
                  onClick={() => removeRole(p.value)}
                />
              </li>
            ))}
        </ul>
        <Select>
          <>
            <Select.Label>
              <Typography variant={TypographyVariants.Paragraph}>
                {TranslationService.translateModule('SelectPermission', ModuleNamesList.ManagePagePermissions)}
              </Typography>
            </Select.Label>

            <Select.Options
              name="permissionSelect"
              id="permissionSelect"
              options={permissionsOptions}
              onChange={async (option: any) => {
                setSelectedPermission(option.id);
              }}
            />
          </>
        </Select>
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTON_SIZE.MD}
          label={TranslationService.translateModule('AddPermission', ModuleNamesList.ManagePagePermissions)}
          disabled={selectedPermission === 0}
          onClick={() => {
            setPagePermissions([
              ...pagePermissions,
              {
                type: PermissionType.Permission,
                value: selectedPermission.toString(),
              },
            ]);
            const idx = permissionsOptions.indexOf(permissionsOptions.filter((r) => r.id === selectedPermission)[0]);
            setPermissionsOptions([...permissionsOptions.slice(0, idx), ...permissionsOptions.slice(idx + 1)]);
            setSelectedPermission(0);
          }}
        />
        <ul>
          {pagePermissions
            .filter((p) => p.type === PermissionType.Permission)
            .map((p) => (
              <li key={`${p.type}_${p.value}`}>
                {permissions.filter((r) => r.id === parseInt(p.value))[0].displayName}{' '}
                <Button
                  variant={BUTTON_VARIANTS.ICON}
                  size={BUTTON_SIZE.MD}
                  icon={{ className: 'fas fa-times', position: 'left' }}
                  onClick={() => removePermission(p.value)}
                />
              </li>
            ))}
        </ul>

        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTON_SIZE.MD}
          label={TranslationService.translate('Save')}
          onClick={savePermissions}
        />
      </section>
    </>
  );
};

export default ManagePagePersmissions;
