import * as React from 'react';
import AutoOnboardingApiClient from '../../core/api/AutoOnboardingApiClient';
import { Status } from '../../core/api/Enums/Status';
import { IActionStatus } from '../../core/api/types/IActionStatus';
import AlertMessages from '../../core/components/AlertMessages/AlertMessages';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { CardHeader, CardHeaderVariants } from '../../core/components/Card/components/CardHeader';
import Loader from '../../core/components/Loading/Loader';
import { SvgIconsNames } from '../../core/components/SvgIcons/enums/SvgIconsNames';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import RouteParamsService from '../../core/services/RouteParamsService';
import TranslationService from '../../core/services/TranslationService';
import { AutoOnboardingTokenStatus, IAutoOnboardingTokenDataModel } from '../../core/types/IAutoOnboardingTokenData';
import { INumberDictionary } from '../../core/types/IDictionary';
import { IModuleProps } from '../../core/types/IModuleProps';
import StepList from '../StepListWizard/StepList';
import { IConfirmations } from '../StepListWizard/types/IConfirmations';
import { IStepListItem } from '../StepListWizard/types/IStepListItem';
import { StepIconsCss } from '../StepListWizard/types/StepIconCss';
import BankIdVerification from './components/BankIdVerification';
import BisnodeVerification from './components/BisnodeVerification';
import Confirmation from './components/Confirmation';
import Contacts from './components/Contacts';
import NewRegistration from './components/NewRegistration';
import SecurityAndTerms from './components/SecurityAndTerms';
import Welcome from './components/Welcome';
import './RegisterUser.scss';
import RegisterUserApiClient from './RegisterUserApiClient';
import RegisterUserService from './RegisterUserService';
import { IRegisterUserAutoOnboardingTokenState, IRegisterUserState } from './types/IRegisterUserState';
import { emptyUser, IUser } from './types/IUser';
import { RegisterUserSteps } from './types/RegisterUserSteps';

class RegisterUser extends React.Component<IModuleProps> {
  public stepList: INumberDictionary<IStepListItem>;
  public state: IRegisterUserState & IRegisterUserAutoOnboardingTokenState = {
    currentStep: RegisterUserSteps.NewRegistration,
    currentStepIndex: 0,
    companyName: '',
    componentIsLoading: false,
    confirmations: {
      byPhone: false,
      byEmail: false,
    },
    contactFormValid: false,
    errorMessages: [],
    isAgreementConfirmed: false,
    isLoading: true,
    user: emptyUser,
    token: {
      data: '',
      status: 0,
      token: '',
      userEmail: '',
    },
  };

  public componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });

    const queryString = RouteParamsService.getQueryParameters();
    await this.loadInstanceSettings();
    const token = queryString['token'];

    if (token) {
      await this.loadTokenData(token as string);
    }

    this.setState({
      isLoading: false,
    });
  };

  public isInAutoOnboardingTokenContext = () => {
    return this.getAutoOnboardingToken() !== undefined;
  };

  public getAutoOnboardingToken = () => {
    return (
      this.props.routeParameters['token'] || RouteParamsService.getQueryParamsFromRoute(window.location.search)['token']
    );
  };

  public loadInstanceSettings = async () => {
    const instanceSettings = await RegisterUserApiClient.GetModuleSettings(this.props.module.id);
    this.stepList = this.getStepComponentsList(instanceSettings.confirmations);
    this.setState({
      ...instanceSettings,
      isLoading: false,
    });
  };

  public loadTokenData = async (token: string) => {
    const tokenData = await AutoOnboardingApiClient.GetOnboardingInitialData(token);
    const tokenDataModel = JSON.parse(tokenData.data) as IAutoOnboardingTokenDataModel;
    if (tokenData) {
      const mergedUser = {
        email: this.state.user.email ? this.state.user.email : tokenDataModel.user.email,
        firstName: this.state.user.firstName ? this.state.user.firstName : tokenDataModel.user.firstName,
        lastName: this.state.user.lastName ? this.state.user.lastName : tokenDataModel.user.lastName,
        phone: this.state.user.phone ? this.state.user.phone : tokenDataModel.user.phone,
        confirmationCodeEmail: this.state.user.confirmationCodeEmail,
        confirmationCodePhone: this.state.user.confirmationCodePhone,
        emailRepeat: this.state.user.emailRepeat,
        password: this.state.user.password,
        passwordRepeat: this.state.user.passwordRepeat,
        userAuthorizationType: this.state.user.userAuthorizationType,
      };

      this.setState({
        user: mergedUser,
        token: tokenData,
      });

      if (
        tokenData.status === AutoOnboardingTokenStatus.PassedBankIdVerification ||
        tokenData.status === AutoOnboardingTokenStatus.FailedBankIdVerification
      ) {
        this.setState({
          currentStep: RegisterUserSteps.BankIdVerification,
          currentStepIndex: parseInt(Object.keys(this.stepList)[RegisterUserSteps.BankIdVerification], 10),
        });
      } else if (
        tokenData.status === AutoOnboardingTokenStatus.PassedBisnodeVerification ||
        tokenData.status === AutoOnboardingTokenStatus.FailedBisnodeVerification
      ) {
        this.setState({
          currentStep: RegisterUserSteps.BisnodeVerification,
          currentStepIndex: parseInt(Object.keys(this.stepList)[RegisterUserSteps.BisnodeVerification], 10),
        });
      } else if (tokenData.status === AutoOnboardingTokenStatus.PendingUserRegistration) {
        this.setState({
          user: {
            firstName: tokenDataModel.user ? tokenDataModel.user.firstName : emptyUser.firstName,
            lastName: tokenDataModel.user ? tokenDataModel.user.lastName : emptyUser.lastName,
            phone: tokenDataModel.user ? tokenDataModel.user.phone : emptyUser.phone,
            email: tokenDataModel.user ? tokenDataModel.user.email : emptyUser.email,
          },
          token: tokenData,
        });
      }
    }
  };

  public updateUser = (user: IUser) => {
    this.setState({ user });
  };

  public updateConfirmationCodes = (codes: any) => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        confirmationCodeEmail: codes.confirmationCodeEmail,
        confirmationCodePhone: codes.confirmationCodePhone,
      },
    });
  };

  public registerNewUser = async () => {
    this.setState({
      ...this.state,
      componentIsLoading: true,
      errorMessages: [],
    });
    const actionStatus: IActionStatus = await RegisterUserService.CreateUser(this.state.user);
    if (actionStatus.status !== Status.Success) {
      this.setState({
        ...this.state,
        errorMessages: actionStatus.messages ? actionStatus.messages : [],
        componentIsLoading: false,
      });
      return false;
    } else {
      return true;
    }
  };

  public onIsAgreementConfirmedChange = (newValue: boolean) => {
    this.setState({
      ...this.state,
      isAgreementConfirmed: newValue,
    });
  };

  public goToStep = async (stepIndex: number) => {
    const nextStep = parseInt(Object.keys(this.stepList)[stepIndex], 10) as RegisterUserSteps;
    let goToNextStep: boolean = true;
    if (nextStep === RegisterUserSteps.Welcome) {
      const token = this.getAutoOnboardingToken() as string;
      const creditorExists = this.isInAutoOnboardingTokenContext()
        ? !(await AutoOnboardingApiClient.ShouldRegisterNewCreditor(token))
        : false;

      goToNextStep =
        (!this.isInAutoOnboardingTokenContext() ||
          (this.isInAutoOnboardingTokenContext() &&
            (await AutoOnboardingApiClient.UpdateOnboardingTokenStatus(
              token,
              creditorExists ? AutoOnboardingTokenStatus.Done : AutoOnboardingTokenStatus.PendingCreditorRegistration
            )))) &&
        (await this.registerNewUser());
    }

    if (goToNextStep) {
      this.setState({
        ...this.state,
        currentStep: nextStep,
        currentStepIndex: stepIndex,
        errorMessages: [],
        componentIsLoading: false,
      });
    }
  };

  public goToNextStep = async () => {
    const nextStepIndex = this.state.currentStepIndex + 1;
    await this.goToStep(nextStepIndex);
  };

  public backToPrevStep = async () => {
    const nextStepIndex = this.state.currentStepIndex - 1;
    await this.goToStep(nextStepIndex);
  };

  public render() {
    return (
      <div className="register-user">
        {!this.state.isLoading ? (
          <div className="animated fadeIn register-user__container">
            <Card class="mb-4 mt-4">
              <>
                <CardHeader variant={CardHeaderVariants.Secondary}>
                  <StepList stepList={this.stepList} currentStep={this.state.currentStep} center={true} />
                </CardHeader>
                <CardContent>
                  <>
                    {this.state.errorMessages.length > 0 ? (
                      <AlertMessages messages={this.state.errorMessages} translate={true} alertClass="alert-danger" />
                    ) : (
                      ''
                    )}
                    {!this.state.componentIsLoading ? this.getStepComponent() : <Loader />}
                  </>
                </CardContent>
              </>
            </Card>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }

  private getStepComponent = () => {
    switch (this.state.currentStep) {
      case RegisterUserSteps.NewRegistration:
        return <NewRegistration companyName={this.state.companyName} goToNextStep={this.goToNextStep} />;
      case RegisterUserSteps.Contacts:
        return (
          <Contacts
            user={this.state.user}
            onUpdateUser={this.updateUser}
            goToNextStep={this.goToNextStep}
            backToPrevStep={this.backToPrevStep}
            disablePrefilledFields={this.isInAutoOnboardingTokenContext()}
          />
        );
      case RegisterUserSteps.SecurityAndTerms:
        return (
          <SecurityAndTerms
            isAgreementConfirmed={this.state.isAgreementConfirmed}
            goToNextStep={this.goToNextStep}
            backToPrevStep={this.backToPrevStep}
            onAgreementChange={this.onIsAgreementConfirmedChange}
            user={this.state.user}
          />
        );
      case RegisterUserSteps.BankIdVerification:
        return (
          <BankIdVerification
            goToNextStep={this.goToNextStep}
            backToPrevStep={this.backToPrevStep}
            token={this.state.token}
          />
        );
      case RegisterUserSteps.BisnodeVerification:
        return (
          <BisnodeVerification
            goToNextStep={this.goToNextStep}
            backToPrevStep={this.backToPrevStep}
            token={this.state.token}
          />
        );
      case RegisterUserSteps.Confirmation:
        return (
          <Confirmation
            user={this.state.user}
            confirmations={this.state.confirmations}
            goToNextStep={this.goToNextStep}
            backToPrevStep={this.backToPrevStep}
            updateConfirmationsCodes={this.updateConfirmationCodes}
            moduleInstanceid={this.props.module.id}
          />
        );
      case RegisterUserSteps.Welcome:
        return <Welcome companyName={this.state.companyName} />;
    }

    return null;
  };

  private getStepComponentsList = (confirmations: IConfirmations): INumberDictionary<IStepListItem> => {
    const stepComponents: INumberDictionary<IStepListItem> = {
      [RegisterUserSteps.NewRegistration]: {
        iconSvg: SvgIconsNames.UserPlus,
        iconCss: StepIconsCss.UserPlus,
        text: TranslationService.translateModule('NewRegistrationStepTitle', ModuleNamesList.RegisterUserModuleName),
      },
      [RegisterUserSteps.Contacts]: {
        iconSvg: SvgIconsNames.Contacts,
        text: TranslationService.translateModule('ContactsStepTitle', ModuleNamesList.RegisterUserModuleName),
      },
      [RegisterUserSteps.SecurityAndTerms]: {
        iconCss: StepIconsCss.Agreement,
        text: TranslationService.translateModule('SecurityAndTermsStepTitle', ModuleNamesList.RegisterUserModuleName),
      },
    };

    if (RouteParamsService.getQueryParameters()['token'] || this.props.routeParameters['token']) {
      stepComponents[RegisterUserSteps.BankIdVerification] = {
        iconCss: StepIconsCss.BankIdVerification,
        text: TranslationService.translateModule('BankIdVerificationStepTitle', ModuleNamesList.RegisterUserModuleName),
      };
      stepComponents[RegisterUserSteps.BisnodeVerification] = {
        iconCss: StepIconsCss.BisnodeVerification,
        text: TranslationService.translateModule(
          'BisnodeVerificationStepTitle',
          ModuleNamesList.RegisterUserModuleName
        ),
      };
    }

    if (confirmations.byEmail || confirmations.byPhone) {
      stepComponents[RegisterUserSteps.Confirmation] = {
        iconCss: StepIconsCss.Confirmation,
        text: TranslationService.translateModule('ConfirmationStepTitle', ModuleNamesList.RegisterUserModuleName),
      };
    }

    stepComponents[RegisterUserSteps.Welcome] = {
      iconCss: StepIconsCss.Welcome,
      text: TranslationService.translateModule('WelcomeStepTitle', ModuleNamesList.RegisterUserModuleName),
    };

    return stepComponents;
  };
}

export default RegisterUser;
