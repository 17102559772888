import React, { useState, useEffect } from 'react';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import { IModuleProps } from '../../core/types/IModuleProps';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import ErrorResponseHandler from '../../core/services/ErrorResponseHandler';
import Loader from '../../core/components/Loading/Loader';
import TranslationService from '../../core/services/TranslationService';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { emptyComponentTable, IComponentTable } from 'core/types/IComponentTable';
import { CreditorOnboardingAgreement } from './types/CreditorOnboardingAgreement';
import CreditorOnboardingAgreementsApiClient from './api/CreditorOnboardingAgreementsApiClient';
import { IComponentTableColumn } from 'core/types/IComponentTableColumn';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'core/components/Modal/Modal';
import EditOnboardingAgreement from './components/EditOnboardingAgreement';
import { Status } from 'core/api/Enums/Status';
import InfoMessageService from 'core/services/InfoMessageService';
import { Button, BUTTON_VARIANTS } from 'core/components/Button/Button';

const CreditorOnboardingAgreements = (props: IModuleProps & AbortRequestPropsType) => {
  const [tableData, setTableData] = useState<IComponentTable<CreditorOnboardingAgreement>>({
    ...emptyComponentTable,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAgreement, setSelectedAgreement] = useState<CreditorOnboardingAgreement | undefined>(undefined);
  const [isManageModalOpened, setIsManageModalOpened] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const profiles = await CreditorOnboardingAgreementsApiClient.getCreditorAgreementsList(
        props.module.id,
        props.cancelTokenSource.token
      );
      setTableData(profiles);
    } catch (error: any) {
      ErrorResponseHandler.hasAccess(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const configureColumns = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
    if (column) {
      column.sort = false;
      column.formatter = (value: any, item: CreditorOnboardingAgreement) => actionsRowData(value, item);
    }
    return columns;
  };

  const actionsRowData = (value: any, item: CreditorOnboardingAgreement) => {
    return (
      <Dropdown alignRight className="button-table">
        <Dropdown.Toggle bsPrefix="c-btn c-btn--color-primary c-btn--toggle" id={`caseActionsBtn-${item.id}`}>
          {TranslationService.translateModule('Actions', ModuleNamesList.CreditorOnboardingAgreements)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setIsManageModalOpened(true);
              setSelectedAgreement(item);
            }}
            id={`agreement-action-${item.id}`}
          >
            <i className="far fa-edit" />{' '}
            {TranslationService.translateModule('Edit', ModuleNamesList.CreditorOnboardingAgreements)}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              removeAgreement(item);
            }}
            id={`agreement-remove-${item.id}`}
          >
            <i className="fas fa-times" />{' '}
            {TranslationService.translateModule('Remove', ModuleNamesList.CreditorOnboardingAgreements)}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const saveOnboardingAgreement = async (item: CreditorOnboardingAgreement) => {
    const result = await CreditorOnboardingAgreementsApiClient.addOrEdit(item, props.cancelTokenSource.token);
    if (result.status === Status.Success) {
      InfoMessageService.success(TranslationService.translate('ActionEndsWithSuccess'));
      await fetchData();
      setIsManageModalOpened(false);
    } else {
      InfoMessageService.error(TranslationService.translate('GeneralErrorOccured'));
    }
  };

  const removeAgreement = async (item: CreditorOnboardingAgreement) => {
    setIsLoading(true);
    const result = await CreditorOnboardingAgreementsApiClient.remove(item.id);
    setIsLoading(false);
    if (result.status === Status.Success) {
      InfoMessageService.success(TranslationService.translate('ActionEndsWithSuccess'));
      await fetchData();
    } else {
      InfoMessageService.error(TranslationService.translate('GeneralErrorOccured'));
    }
  };

  return (
    <article className="l-module">
      <section className="l-module__section l-module__section--head">
        <h1>
          {TranslationService.translateModule(
            'CreditorOnboardingAgreementstMenu',
            ModuleNamesList.CreditorOnboardingAgreements
          )}
        </h1>
      </section>
      <section className="l-module__section l-module__section--filter">
        <Button
          label={TranslationService.translateModule('ButtonCreateNew', ModuleNamesList.GroupUsersList)}
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => {
            setIsManageModalOpened(true);
            setSelectedAgreement(undefined);
          }}
        />
      </section>
      <section className="l-module__section mt-3">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <BootstrapTableContainer
              remote={false}
              wrapperClasses="bt"
              classes="bt__table bt-table"
              keyField="id"
              data={tableData.values}
              columns={configureColumns(tableData.columns)}
            />
          </>
        )}
      </section>

      <Modal
        isOpen={isManageModalOpened}
        onClose={() => setIsManageModalOpened(false)}
        modalData={{
          headerText: `${TranslationService.translateModule(
            'ManageAgreementModalHeader',
            ModuleNamesList.CreditorOnboardingAgreements
          )}`,
          content: (
            <EditOnboardingAgreement
              data={selectedAgreement}
              onClose={() => setIsManageModalOpened(false)}
              onSubmit={saveOnboardingAgreement}
            />
          ),
        }}
      />
    </article>
  );
};

export default compose(withRouter, withAbortRequest)(CreditorOnboardingAgreements);
