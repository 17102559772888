import React from 'react';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { CardHeader } from '../../core/components/Card/components/CardHeader';
import { connect } from 'react-redux';
import { Form, Formik, FormikErrors } from 'formik';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IStore } from '../../reducers/IStore';
import { IUserDetailsUpdateModel } from './types/IUserDetailsUpdateModel';
import { IUserProfileModuleSettings } from './types/IUserProfileModuleSettings';
import { phoneRegex, emailRegex } from '../../core/helpers/utils';
import * as yup from 'yup';
import FormGroupField from '../../core/components/Forms/FormGroupField';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import UserIdentityService from '../../core/services/UserIdentityService';
import UserProfileService from './services/UserProfileService';
import './UserProfile.scss';
import { Status } from 'core/api/Enums/Status';
import Loader from 'core/components/Loading/Loader';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import setlanguage from '../../actionCreators/setLanguage';
import { ILanguage, LanguageCode } from '../../core/types/ILanguage';

interface IProps extends IModuleProps {
  languages: ILanguage[];
  setSelectedLanguage: (langCode: LanguageCode) => void;
}

interface IState {
  isLoading: boolean;
  isWaitingForConfirmationCodes: boolean;
  isInCodeVerificationStep: boolean;
  moduleSettings: IUserProfileModuleSettings;
  userDetails: IUserDetailsUpdateModel;
}

class UserProfile extends React.PureComponent<IProps, IState> {
  private userIdentityService: UserIdentityService = new UserIdentityService();

  private validationMessages = {
    incorrectPhoneNoPhormat: TranslationService.translate('ValidationMessageIncorrectPhoneNoFormat'),
    required: TranslationService.translate('ValidationMessageRequiredField'),
    emailAlreadyExists: TranslationService.translate('ValidationMessageEmailAlreadyExists'),
    invalidEmail: TranslationService.translate('ValidationMessageIncorrentEmailFormat'),
    numberFormat: TranslationService.translate('ValidationMessageIncorrectNumberFormat'),
    max: TranslationService.translate('ValidationMessageValueIsTooLong'),
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: false,
      isInCodeVerificationStep: false,
      isWaitingForConfirmationCodes: false,
      moduleSettings: {} as IUserProfileModuleSettings,
      userDetails: this.getUserDetails(),
    };
  }

  public async componentDidMount() {
    this.setState({ isLoading: true });
    const settings = await this.loadModuleSettings();
    this.setState({
      moduleSettings: settings,
      isLoading: false,
    });
  }

  public loadModuleSettings = async () => {
    return await UserProfileService.getModuleSettings(this.props.module.id);
  };

  public render() {
    return (
      <>
        <Card class="mt-3">
          <>
            <CardHeader>
              <h1 className="c-heading">
                {TranslationService.translateModule('UserProfileTitle', this.props.module.name)}
              </h1>
            </CardHeader>

            <CardContent>
              <Formik
                onSubmit={this.onSubmit}
                enableReinitialize={true}
                validationSchema={this.validationSchema()}
                initialValues={{
                  firstname: this.state.userDetails.firstname,
                  lastname: this.state.userDetails.lastname,
                  phone: this.state.userDetails.phone,
                  email: this.state.userDetails.email,
                  locale: this.state.userDetails.locale || '',
                  phoneVerificationCode: '',
                  emailVerificationCode: '',
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  submitForm,
                  validateForm,
                  setErrors,
                  setFieldTouched,
                }) => (
                  <Form className="c-form">
                    {this.state.isLoading && <Loader opacity={0.5} />}
                    <div className="row justify-content-center">
                      <div className="col-md-8 col-lg-6">
                        <FormGroupField
                          fieldName="firstname"
                          id="firstName"
                          label={TranslationService.translateModule('Firstname', this.props.module.name)}
                          labelClass="col-4"
                          inputContainerClass="col-8"
                          value={values.firstname}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          disabled={this.state.isWaitingForConfirmationCodes || this.state.isInCodeVerificationStep}
                        />

                        <div className="w-100"></div>

                        <FormGroupField
                          fieldName="lastname"
                          id="lastName"
                          label={TranslationService.translateModule('Lastname', this.props.module.name)}
                          labelClass="col-4"
                          inputContainerClass="col-8"
                          value={values.lastname}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          disabled={this.state.isWaitingForConfirmationCodes || this.state.isInCodeVerificationStep}
                        />

                        <div className="w-100"></div>

                        <FormGroupField
                          fieldName="phone"
                          label={TranslationService.translateModule('Phone', this.props.module.name)}
                          labelClass="col-4"
                          inputContainerClass="col-8"
                          value={values.phone}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          disabled={this.state.isWaitingForConfirmationCodes || this.state.isInCodeVerificationStep}
                        />

                        <div className="w-100"></div>

                        <FormGroupField
                          fieldName="email"
                          label={TranslationService.translateModule('Email', this.props.module.name)}
                          labelClass="col-4"
                          inputContainerClass="col-8"
                          value={values.email}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />

                        <div className="w-100"></div>

                        <FormGroupField
                          fieldName="locale"
                          component="select"
                          label={TranslationService.translateModule('Language', this.props.module.name)}
                          labelClass="col-4"
                          inputContainerClass="col-8"
                          value={values.locale}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          disabled={this.state.isWaitingForConfirmationCodes || this.state.isInCodeVerificationStep}
                        >
                          {this.props.languages.map((lang) => (
                            <option value={lang.code} key={lang.code}>
                              {TranslationService.translate(lang.name)}
                            </option>
                          ))}
                        </FormGroupField>

                        <div className="row justify-content-end mb-4">
                          <div className="col-auto">
                            <Button
                              type="button"
                              variant={BUTTON_VARIANTS.PRIMARY}
                              size={BUTTON_SIZE.MD}
                              onClick={this.initialSubmit(values, submitForm, validateForm, setErrors, setFieldTouched)}
                              label={TranslationService.translateModule('Save', this.props.module.name)}
                              disabled={this.state.isWaitingForConfirmationCodes || this.state.isInCodeVerificationStep}
                            />
                          </div>
                        </div>

                        <div
                          className={
                            this.state.isWaitingForConfirmationCodes || this.state.isInCodeVerificationStep
                              ? ''
                              : 'd-none'
                          }
                        >
                          <div className={!this.state.moduleSettings.confirmationByPhone ? 'd-none' : ''}>
                            <FormGroupField
                              fieldName="phoneVerificationCode"
                              label={TranslationService.translateModule(
                                'PhoneVerificationCode',
                                this.props.module.name
                              )}
                              labelClass="col-4"
                              inputContainerClass="col-8"
                              value={values.phoneVerificationCode}
                              errors={errors}
                              touched={touched}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              disabled={false}
                            />
                          </div>

                          <div className={!this.state.moduleSettings.confirmationByEmail ? 'd-none' : ''}>
                            <FormGroupField
                              fieldName="emailVerificationCode"
                              label={TranslationService.translateModule(
                                'EmailVerificationCode',
                                this.props.module.name
                              )}
                              labelClass="col-4"
                              inputContainerClass="col-8"
                              value={values.emailVerificationCode}
                              errors={errors}
                              touched={touched}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              disabled={false}
                            />
                          </div>

                          <div className="row justify-content-end mb-4">
                            <div className="col-auto">
                              <Button
                                variant={BUTTON_VARIANTS.PRIMARY}
                                size={BUTTON_SIZE.MD}
                                type="submit"
                                label={TranslationService.translateModule('Confirm', this.props.module.name)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </>
        </Card>
      </>
    );
  }

  private validationSchema = () =>
    yup.object().shape({
      phoneVerificationCode:
        this.state.moduleSettings.confirmationByPhone && this.state.isInCodeVerificationStep
          ? yup.string().matches(/^[0-9]*$/, this.validationMessages.numberFormat)
          : yup.string(),
      emailVerificationCode:
        this.state.moduleSettings.confirmationByEmail && this.state.isInCodeVerificationStep
          ? yup.string().required(this.validationMessages.required)
          : yup.string(),
      phone: yup
        .string()
        .max(15, this.validationMessages.max)
        .required(this.validationMessages.required)
        .matches(phoneRegex, this.validationMessages.incorrectPhoneNoPhormat),
      email: yup
        .string()
        .email(this.validationMessages.invalidEmail)
        .test('isDuplicated', this.validationMessages.emailAlreadyExists, async (email) => {
          if (emailRegex.test(email || '') && email !== this.state.userDetails.email) {
            const result = await UserProfileService.checkEmailExists(email || '', this.props.module.id);
            return !result;
          } else {
            return true;
          }
        }),
    });

  private initialSubmit =
    (
      values: any,
      submitForm: () => void,
      validateForm: (values?: any) => Promise<FormikErrors<any>>,
      setErrors: (errors: FormikErrors<any>) => void,
      setFieldTouched: (field: string, isTouched?: boolean) => void
    ) =>
    async () => {
      const errors = await validateForm(values);
      const areErrorsEmpty = JSON.stringify(errors) === JSON.stringify({});

      if (areErrorsEmpty) {
        if (
          (!this.state.moduleSettings.confirmationByEmail && !this.state.moduleSettings.confirmationByPhone) ||
          !this.areVerificationCodesNeeded(values)
        ) {
          submitForm();
        } else {
          this.setState({
            isWaitingForConfirmationCodes: true,
            isInCodeVerificationStep: true,
          });

          try {
            const status = await UserProfileService.requestVerificationCodes(
              values.phone,
              values.email,
              this.props.module.id
            );
            if (status.status !== Status.Success) {
              InfoMessageService.error(TranslationService.translateModule('ErrorMessage', this.props.module.name));
            }
            this.setState({
              isWaitingForConfirmationCodes: false,
              isInCodeVerificationStep: !(status.status !== Status.Success),
            });
          } catch {
            InfoMessageService.error(TranslationService.translateModule('ErrorMessage', this.props.module.name));
            this.setState({
              isWaitingForConfirmationCodes: false,
              isInCodeVerificationStep: false,
            });
          }
        }
      } else {
        for (const prop in errors) {
          if (errors.hasOwnProperty(prop)) {
            setFieldTouched(prop, true);
          }
        }

        setErrors(errors);
      }
    };

  private onSubmit = async (values: any, actions: any) => {
    try {
      this.setState({ isLoading: true });
      await UserProfileService.updateUserDetails(
        { ...values, userId: this.userIdentityService.GetUserId() } as IUserDetailsUpdateModel,
        this.props.module.id
      );
      InfoMessageService.success(TranslationService.translateModule('SuccessMessage', this.props.module.name));
      actions.resetForm();
      this.setState({
        isInCodeVerificationStep: false,
        isWaitingForConfirmationCodes: false,
      });

      this.userIdentityService.updateUserDetails({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        locale: values.locale,
        phone: values.phone,
      });

      this.props.setSelectedLanguage(values.locale);

      this.setState({
        userDetails: this.getUserDetails(),
        isLoading: false,
      });
    } catch (ex: any) {
      if (ex.response.status === 400 && ex.response.data && ex.response.data.messages) {
        actions.setErrors(this.tansformErrorMessagesToFormikErrors(ex.response.data.messages));
      } else {
        InfoMessageService.error(TranslationService.translateModule('ErrorMessage', this.props.module.name));
        this.setState({
          isInCodeVerificationStep: false,
          isWaitingForConfirmationCodes: false,
          isLoading: false,
        });
      }
    }
  };

  private getUserDetails = () => {
    const userDetails = this.userIdentityService.GetUserDetails();
    return {
      ...userDetails,
      userId: this.userIdentityService.GetUserId(),
      phoneVerificationCode: '',
      emailVerificationCode: '',
    };
  };

  private tansformErrorMessagesToFormikErrors = (errors: string[]) => {
    const result = {};
    const fieldToErrorCodeMap = {
      ErrorEmailCodeIsIncorrect: 'emailVerificationCode',
      ErrorPhoneCodeIsIncorrect: 'phoneVerificationCode',
    };

    errors.map((err) =>
      Object.assign(result, {
        [fieldToErrorCodeMap[err]]: TranslationService.translateModule(err, this.props.module.name),
      })
    );

    return result;
  };

  private areVerificationCodesNeeded = (values: any) =>
    this.state.userDetails.email !== values.email ||
    this.state.userDetails.firstname !== values.firstname ||
    this.state.userDetails.lastname !== values.lastname ||
    this.state.userDetails.phone !== values.phone;
}

const mapStateToProps = (state: IStore) => ({
  languages: state.languages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, AnyAction>) => ({
  setSelectedLanguage: (langCode: LanguageCode) => dispatch(setlanguage(langCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
