import React from 'react';
import { Field } from 'formik';
import TranslationService from '../../../core/services/TranslationService';
import { toTitleCaseWithoutWhitespaces } from '../../../core/helpers/toTitleCaseWithoutWhitespaces';

type HandleChangeProps = {
  name: string;
  type: string;
  value: string;
};

type Props = {
  name: string;
  prompt: string;
  type: string;
  dataType: string;
  defaultValues: string;
  required: boolean;
  handleChange: (data: HandleChangeProps) => void;
};

type State = {
  value: string;
  isLoaded: boolean;
};

export class NumberInput extends React.PureComponent<Props, State> {
  state: State = {
    value: '',
    isLoaded: false,
  };

  componentDidMount() {
    this.setState({
      value: this.props.defaultValues,
      isLoaded: true,
    });
  }

  render() {
    return (
      <>
        {this.state.isLoaded && (
          <>
            <Field name={this.props.name} validate={this.validation}>
              {({ field, form }: any) => (
                <div className="c-control">
                  <div className="row row--aligned">
                    <label
                      className={`col-4 c-control__label c-control__label--${this.props.type}`}
                      htmlFor={this.props.name}
                    >
                      {TranslationService.translate(
                        toTitleCaseWithoutWhitespaces(this.props.prompt),
                        this.props.prompt
                      )}
                    </label>

                    <div className="col-8">
                      <input
                        {...field}
                        type="string"
                        onChange={this.handleChange}
                        value={this.state.value || ''}
                        required={this.props.required}
                      />

                      {form.errors[field.name] && form.touched[field.name] && (
                        <div className="c-input-error">{form.errors[field.name]}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Field>
          </>
        )}
      </>
    );
  }

  validation = (value: string | undefined) => {
    let error;

    if (!value) {
      error = TranslationService.translate('ValidationMessageRequiredField');
    }

    return error;
  };

  handleChange = ({ target: { value } }: any) => {
    const re = /\d/;

    if (re.test(value)) {
      this.setState({
        value,
      });
    }

    this.props.handleChange({
      name: this.props.name,
      type: this.props.dataType,
      value,
    });
  };
}

export default NumberInput;
