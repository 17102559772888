import * as React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import Loader from '../../core/components/Loading/Loader';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { IModuleProps } from '../../core/types/IModuleProps';
import CaseStatisticsCasesOverdueApiClient from './CaseStatisticsCasesOverdueApiClient';
import { IPortfolioChartData } from './types/IPortfolioChartData';
import './_CaseStatisticsCasesOverdue.scss';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';

interface IProps extends IModuleProps, AbortRequestPropsType {
  locale: string;
}

interface IState {
  data: IPortfolioChartData[];
  loading: boolean;
  numOfInvoices: number;
}

class CaseStatisticsCasesOverdue extends React.Component<IProps, IState> {
  state: IState = {
    loading: false,
    data: [],
    numOfInvoices: 0,
  };
  private moduleEvents: IModuleEventSubscription[];

  componentDidMount = async () => {
    this.registerModuleEvents();
    await this.loadData();
  };

  componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  }

  loadData = async () => {
    this.setState({
      loading: true,
      data: [],
      numOfInvoices: 0,
    });

    const { data, numOfInvoices } = await CaseStatisticsCasesOverdueApiClient.GetData(
      this.props.cancelTokenSource.token
    );

    this.setState(() => {
      const overdueData = data.filter((item) => {
        return item.value > 0;
      });

      return {
        loading: false,
        data: overdueData,
        numOfInvoices: numOfInvoices,
      };
    });
  };

  render() {
    const { loading, data, numOfInvoices } = this.state;

    return (
      <div className="module-box">
        <div className="module-box__content">
          <header
            className="module-box__header"
            dangerouslySetInnerHTML={{
              __html: TranslationService.translateModule('ChartHeader', ModuleNamesList.CaseStatisticsCasesOverdue),
            }}
          />

          <div className="module-box-content chart-invoices-due">
            {loading ? (
              <Loader />
            ) : (
              <div className="chart">
                <div className="chart__main">
                  <div className="invoices-counter">
                    <p className="count">{numOfInvoices}</p>

                    <p className="desc">
                      {TranslationService.translateModule('TotalCases', ModuleNamesList.CaseStatisticsCasesOverdue)}
                    </p>
                  </div>

                  <PieChart data={data} lineWidth={28} labelPosition={110} />
                </div>

                <ul className="chart__legend">
                  {this.state.data.map((elem: any, index: number) => {
                    return (
                      <li key={index} data-value={elem.subHeader}>
                        {elem.textValue}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private registerModuleEvents() {
    this.moduleEvents = [{ name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.loadData }];
    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }
}
export default withAbortRequest(CaseStatisticsCasesOverdue);
