import * as React from 'react';
import { ContainerFactory } from '../factories/ContainerFactory';
import { ISkinProps } from '../../core/types/ISkinProps';

export const TwoColumnsSkin = (props: ISkinProps) => {
  const containers = props.skin.containers;

  return (
    <div className="container">
      <div className="row">
        <div className="col" style={{ paddingTop: '10px' }}>
          <nav className="nav flex-column">
            <ContainerFactory containers={containers} moduleIndex={0} routeParameters={props.routeParameters} />
          </nav>
        </div>
        <div className="col" style={{ paddingTop: '10px' }}>
          <ContainerFactory containers={containers} moduleIndex={1} routeParameters={props.routeParameters} />
        </div>
      </div>
    </div>
  );
};
