import { IComponentTable } from '../../../core/types/IComponentTable';
import InvoiceDetailsMessagesApi from '../api/InvoiceDetailsMessagesApi';
import { GetInvoiceDetailsMessagesRequest } from '../types/GetInvoiceDetailsMessagesRequest';
import { IInvoiceDetailsMessageItemIndexed } from '../types/IInvoiceDetailsMessageItem';
import { AddNewInvoiceMessageRequest } from '../types/AddNewInvoiceMessageRequest';
import { IActionStatus } from '../../../core/api/types/IActionStatus';

class InvoiceDetailsMessagesService {
  public async getInvoiceDetailsMessages(
    request: GetInvoiceDetailsMessagesRequest
  ): Promise<IComponentTable<IInvoiceDetailsMessageItemIndexed>> {
    return await InvoiceDetailsMessagesApi.getInvoiceDetailsMessages(request);
  }

  public async addNewInvoiceMessage(request: AddNewInvoiceMessageRequest): Promise<IActionStatus> {
    return await InvoiceDetailsMessagesApi.addNewInvoiceMessage(request);
  }
}

export default new InvoiceDetailsMessagesService();
