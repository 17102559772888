import * as React from 'react';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { IRoleDetails } from '../types/IRoleDetails';
import TranslationService from '../../../core/services/TranslationService';
import Dropdown from 'react-bootstrap/Dropdown';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';

class RolesTableConfigurator {
  public getTableColumns = (
    items: IComponentTable<IRoleDetails>,
    openManageRoleModal: (role: IRoleDetails) => void,
    openConfirmRemoveRoleModal: (role: IRoleDetails) => void
  ) => {
    let columns: IComponentTableColumn[] = [];
    if (items != null) {
      columns = items.columns;
      this.configureIsCoreRoleColumn(columns);
      this.configureActions(columns, openManageRoleModal, openConfirmRemoveRoleModal);

      return columns;
    }

    return columns;
  };

  private configureIsCoreRoleColumn = (columns: IComponentTableColumn[]) => {
    const column = columns.find((col) => col.dataField === 'isCoreRole');
    if (column) {
      const formatterFunc = (value: any, row: IRoleDetails) => {
        return (
          <span className={`badge ${row.isCoreRole ? 'badge-info' : 'badge-danger'}`}>
            {TranslationService.translate(row.isCoreRole ? 'Yes' : 'No')}
          </span>
        );
      };

      column.formatter = formatterFunc;
    }
  };

  private configureActions = (
    columns: IComponentTableColumn[],
    openManageRoleModal: (role: IRoleDetails) => void,
    openConfirmRemoveRoleModal: (role: IRoleDetails) => void
  ) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
    if (column) {
      column.headerStyle = () => {
        return { width: '90px' };
      };
      column.formatter = (value: any, item: IRoleDetails) =>
        !item.isCoreRole ? (
          <Dropdown alignRight className="button-table">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {TranslationService.translateModule('Actions', ModuleNamesList.RolesManagement)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  openManageRoleModal(item);
                }}
              >
                <i className="fas fa-tasks" />{' '}
                {TranslationService.translateModule('ActionManageRole', ModuleNamesList.RolesManagement)}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  openConfirmRemoveRoleModal(item);
                }}
              >
                <i className="fas fa-trash-alt" />{' '}
                {TranslationService.translateModule('ActionRemoveRole', ModuleNamesList.RolesManagement)}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          ''
        );
    }
  };
}

export default new RolesTableConfigurator();
