import { connect } from 'react-redux';
import { IUnitOverview } from './types/IUnitOverview';
import { ModuleNamesList } from 'core/lists/ModuleNamesList';
import Loader from '../../../core/components/Loading/Loader';
import React, { useState, useEffect } from 'react';
import RouteParamsService from '../../../core/services/RouteParamsService';
import TranslationService from 'core/services/TranslationService';
import UnitOverviewApiClient from './api/UnitOverviewApiClient';
import NoPermissionsComponent from '../../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import PermissionsApiClient from '../../../core/api/PermissionsApiClient';
import CurrencyParser from 'core/helpers/CurrencyParser';

type UnitOverviewProps = {
  caseId: string;
};

const UnitOverview = (props: UnitOverviewProps) => {
  const [isDataLoading, setisDataLoading] = useState<boolean>(true);
  const [unitOverview, setUnitOverview] = useState<IUnitOverview>();
  const [hasAccessToModule, setHasAccessToModule] = useState<boolean>(true);

  const checkAccessModule = async () => {
    const hasAccess = await PermissionsApiClient.hasAccessToCase(props.caseId);
    setHasAccessToModule(hasAccess);
  };

  useEffect(() => {
    setisDataLoading(true);
    checkAccessModule();
    UnitOverviewApiClient.getCaseUnitOverview(props.caseId).then((data) => {
      setisDataLoading(false);
      setUnitOverview(data);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <article className="l-box l-box--relative">
      {!hasAccessToModule ? (
        <NoPermissionsComponent />
      ) : (
        <>
          <div className="l-box__head">
            <h1>
              {TranslationService.translateModule('UnitOverviewHeader', ModuleNamesList.UnitOverview)}:{' '}
              {unitOverview
                ? unitOverview.unitNo !== ''
                  ? unitOverview.unitNo
                  : TranslationService.translateModule('UnitNotAssigned', ModuleNamesList.UnitOverview)
                : ''}
            </h1>
          </div>

          <div className="l-box__body">
            <Loader isLoaded={!isDataLoading} opacity={0.5} />
            <ul className="c-widget c-widget-list">
              <li className="c-widget-list__item">
                {TranslationService.translateModule('UnitARBalance', ModuleNamesList.UnitOverview)}
                <span>{unitOverview ? CurrencyParser.toLocaleString(unitOverview.arBalance) : 0}</span>
              </li>
              <li className="c-widget-list__item">
                {TranslationService.translateModule('UnitARBalancePassedDue', ModuleNamesList.UnitOverview)}
                <span>{unitOverview ? CurrencyParser.toLocaleString(unitOverview.arBalancePassedDue) : 0}</span>
              </li>
              <li className="c-widget-list__item">
                {TranslationService.translateModule('UnitDebtBalance', ModuleNamesList.UnitOverview)}
                <span>{unitOverview ? CurrencyParser.toLocaleString(unitOverview.debtCollectionBalance) : 0}</span>
              </li>
              <li className="c-widget-list__item">
                {TranslationService.translateModule('UnitCostAndInterest', ModuleNamesList.UnitOverview)}
                <span>{unitOverview ? CurrencyParser.toLocaleString(unitOverview.costAndInterest) : 0}</span>
              </li>
            </ul>
          </div>
        </>
      )}
    </article>
  );
};

const mapStateToProps = () => {
  const queryparams = RouteParamsService.getQueryParameters();

  return {
    caseId: queryparams.id,
  };
};

export default connect(mapStateToProps)(UnitOverview);
