import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RestApiClient from '../../core/api/RestApiClient';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';
import Loader from '../../core/components/Loading/Loader';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import TranslationService from '../../core/services/TranslationService';
import { emptyComponentTable } from '../../core/types/IComponentTable';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IStore } from '../../reducers/IStore';
import { Filter } from './components/Filter';
import ColumnsConfigurator from './helpers/ColumnsConfigurator';
import { FormValues } from './types/FormValues';
import { GetUnitIdSearchResultsRequest } from './types/GetUnitIdSearchResultsRequest';
import { GetUnitIdSearchResultsResponse } from './types/GetUnitIdSearchResultsResponse';
import { emptySearchRequest, SearchRequest } from './types/SearchRequest';
import { UnitIdSearchResultItem } from './types/UnitIdSearchResultItem';
import { UnitIdSearchResultsTable } from './types/UnitIdSearchResultsTable';
import { NavBarFilterOption } from 'types/NavBarFilterOption';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends IModuleProps, RouteComponentProps {
  currentCreditors: string[];
}

const UnitIdSearch = (props: Props) => {
  const [dataTable, setDataTable] = useState<UnitIdSearchResultsTable>({ ...emptyComponentTable });
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialRequestExecuted, setIsInitialRequestExecuted] = useState(false);
  const [searchRequest, setSearchRequest] = useState<SearchRequest>({ ...emptySearchRequest });
  const [totalAvailableResults, setTotalAvailableResults] = useState(0);

  const fetchData = async () => {
    const requestData: GetUnitIdSearchResultsRequest = {
      moduleInstanceId: props.module.id,
      unitId: searchRequest.unitId,
      pageSize: searchRequest.sizePerPage,
      pageNumber: searchRequest.currentPage,
      sortBy: searchRequest.sortBy,
      orderDirection: searchRequest.sortDirection,
    };
    setIsLoading(true);
    const data = await RestApiClient.post<GetUnitIdSearchResultsResponse>('modules/UnitIdSearchModule', requestData);
    data.table.values = data.table.values.map((item: UnitIdSearchResultItem, index: number) => ({ ...item, index }));
    if (data.table.values.length === 1) {
      const row: UnitIdSearchResultItem = data.table.values[0];
      props.history.push(
        `/creditor-account-summary?unitId=${row.unitNo}&creditorNo=${row.clientNo}&accountNo=${row.accountNo}&debtorNo=${row.debtorNumber}`
      );
    }
    setDataTable(data.table);
    setTotalAvailableResults(data.total);
    setIsLoading(false);
  };

  const onFilterChanged = (filterForm: FormValues) => {
    setSearchRequest({
      ...emptySearchRequest,
      unitId: filterForm.unitId,
    });
  };

  const onTableChanged = (type: any, { page, sizePerPage, sortField, sortOrder }: any) => {
    setSearchRequest({
      ...searchRequest,
      currentPage: page,
      sizePerPage: sizePerPage,
      sortBy: sortField ? sortField : searchRequest.sortBy,
      sortDirection: sortOrder ? sortOrder : searchRequest.sortDirection,
    });
  };

  const mapCurrentFolder = () => {
    if (
      props.routeParameters.filteredBy &&
      props.routeParameters.filteredByValue &&
      props.routeParameters.filteredBy.toString() === NavBarFilterOption.UnitId.toString()
    ) {
      setSearchRequest({
        ...emptySearchRequest,
        unitId: props.routeParameters.filteredByValue.toString(),
      });
    } else {
      setSearchRequest({ ...emptySearchRequest });
    }

    setIsInitialRequestExecuted(true);
  };

  useEffect(() => {
    mapCurrentFolder();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isInitialRequestExecuted) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [searchRequest]);

  useEffect(() => {
    if (isInitialRequestExecuted) {
      onTableChanged(undefined, { page: emptySearchRequest.currentPage, sizePerPage: emptySearchRequest.sizePerPage });
    }
    // eslint-disable-next-line
  }, [props.currentCreditors]);

  return (
    <article className="l-module">
      <header className="l-module__section l-module__section--head">
        <h1>{TranslationService.translateModule('UnitIdTitle', ModuleNamesList.UnitIdSearch)}</h1>
      </header>

      <div className="l-module__section l-module__section--filter">
        <Filter onFilter={onFilterChanged} isDataLoading={isLoading} formValues={{ unitId: searchRequest.unitId }} />
      </div>

      <section className="l-module__section mt-4">
        {isLoading && <Loader opacity={0.5} />}

        <BootstrapTableContainer
          remote={true}
          wrapperClasses="bt"
          paginationProps={{
            page: searchRequest.currentPage,
            sizePerPage: searchRequest.sizePerPage,
            totalSize: totalAvailableResults,
          }}
          classes="bt__table bt-table"
          keyField="index"
          data={dataTable.values}
          onTableChange={onTableChanged}
          columns={ColumnsConfigurator.getTableColumns(dataTable)}
        />
      </section>
    </article>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    currentCreditors: state.currentCreditors,
  };
};

export default connect(mapStateToProps)(withRouter(UnitIdSearch));
