import React from 'react';
import { CaseProgressType } from '../../../../core/Enums/CaseProgressType';
import CurrencyParser from '../../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../../core/helpers/DateTimeParser';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import TranslationService from '../../../../core/services/TranslationService';
import { IComponentTable } from '../../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../../core/types/IComponentTableColumn';
import { CaseProductType } from '../../../CustomerTransactionsList/types/CaseProductType';
import { UnitInvoice } from '../types/UnitInvoice';
import { UnitInvoicesColumns } from '../types/UnitInvoicesColumns';
import { Link } from 'react-router-dom';

const configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
  const currencyColumns = [UnitInvoicesColumns.Balance, UnitInvoicesColumns.Capital];
  const currencyColumnsNames = currencyColumns.map(
    (col) => UnitInvoicesColumns[col].charAt(0).toLowerCase() + UnitInvoicesColumns[col].slice(1)
  );
  const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
  const formatterFunc = (value: any) => CurrencyParser.toLocaleString(value);

  for (const col of cols) {
    col.formatter = formatterFunc;
    col.classes = () => 'bt-table__cell bt-table__cell--text-right';
    col.headerClasses = () => 'bt-table__header bt-table__header--text-right';
  }
};

const configureDateColumns = (columns: IComponentTableColumn[]) => {
  const dateColumns = [UnitInvoicesColumns.RegDate, UnitInvoicesColumns.DueDate];
  const currencyColumnsNames = dateColumns.map(
    (col) => UnitInvoicesColumns[col].charAt(0).toLowerCase() + UnitInvoicesColumns[col].slice(1)
  );
  const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
  const formatterFunc = (value: any) => DateTimeParser.toLocaleDateString(value);

  for (const col of cols) {
    col.formatter = formatterFunc;
  }
};

const statusIconRowData = (value: any, element: UnitInvoice) => {
  const mapping = {
    [CaseProgressType.PreLegal]: 'case-progress-icon case-progress-icon--pre-legal',
    [CaseProgressType.Reminder]: 'case-progress-icon case-progress-icon--reminder',
    [CaseProgressType.Legal]: 'case-progress-icon case-progress-icon--legal',
    [CaseProgressType.Surveillance]: 'case-progress-icon case-progress-icon--surveillance',
    [CaseProgressType.Invoice]: 'case-progress-icon case-progress-icon--invoice',
  };

  return <i className={mapping[element.product]} />;
};

const configureProductIconColumn = (columns: IComponentTableColumn[]) => {
  const productCol = [UnitInvoicesColumns.Product];
  const productColName = productCol.map(
    (col) => UnitInvoicesColumns[col].charAt(0).toLowerCase() + UnitInvoicesColumns[col].slice(1)
  );
  const col = columns.filter((col) => productColName.indexOf(col.dataField) >= 0)[0];

  if (col) {
    col.formatter = (value: any, element: UnitInvoice) =>
      TranslationService.translateModule(`ProductType_${CaseProductType[value]}`, ModuleNamesList.UnitCases);

    let statusIconColumn = {
      dataField: 'statusIcon',
      text: '',
      sort: false,
    } as IComponentTableColumn;

    statusIconColumn.formatter = statusIconRowData;
    statusIconColumn.headerClasses = () => 'bt-table__header bt-table__header--single-icon';
    statusIconColumn.classes = () => {
      return 'bt-table__cell bt-table__cell--text-center';
    };

    columns.splice(0, 0, statusIconColumn);
  }
};

const configureDocNoColumns = (columns: IComponentTableColumn[]) => {
  const dateColumns = [UnitInvoicesColumns.DocNo];
  const columnNames = dateColumns.map(
    (col) => UnitInvoicesColumns[col].charAt(0).toLowerCase() + UnitInvoicesColumns[col].slice(1)
  );
  const cols = columns.filter((col) => columnNames.indexOf(col.dataField) >= 0);

  for (const col of cols) {
    col.formatter = (value: any, element: any) => {
      if (element.product === CaseProgressType.Invoice) {
        return (
          <Link
            to={`invoice-details?invoiceNo=${value}&creditorNo=${element.creditorNo}&accountNo=${element.accountNo}`}
            className="font-color-1 font-weight-bold"
          >
            {value}
          </Link>
        );
      }

      return value;
    };
  }
};

const TableConfigurator = {
  getTableColumns: (table: IComponentTable<UnitInvoice>) => {
    let columns: IComponentTableColumn[] = [];
    if (table != null) {
      columns = [...table.columns];
      configureCurrencyColumns(columns);
      configureDateColumns(columns);
      configureProductIconColumn(columns);
      configureDocNoColumns(columns);
    }

    return columns;
  },
};

export default TableConfigurator;
