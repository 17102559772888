import * as React from 'react';
import { IMessagesListProps } from '../types/IMessagesListProps';
import { IMessage } from '../types/IMessage';
import Message from './Message';
import ModuleEventSubscriber from '../../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../../core/lists/EventsList';
import { IModuleEventSubscription } from '../../../core/types/IModuleEventSubscription';

class MessagesList extends React.Component<IMessagesListProps> {
  private messagesListContentRef = React.createRef<HTMLDivElement>();
  private moduleEvents: IModuleEventSubscription[] = [];

  public async componentDidMount() {
    this.registerModuleEvents();
    this.scrollMessagesContainerToBottom();
  }

  public componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  }

  public render() {
    return (
      <div className="m-messages-list" ref={this.messagesListContentRef}>
        <ul className="messages-list-content">
          {this.props.messages.map((message: IMessage, index: number) => {
            const isMessageSending = this.props.currentMessagesInSendingProcess.includes(message);
            const isMessageNotSend = this.props.messagesNotSend.includes(message);
            return (
              <Message
                message={message}
                key={index}
                isMessageSending={isMessageSending}
                isMessageNotSend={isMessageNotSend}
                sendMessageAgain={this.props.sendMessageAgain}
              />
            );
          })}
        </ul>
      </div>
    );
  }

  private scrollMessagesContainerToBottom = () => {
    if (this.messagesListContentRef.current !== null) {
      this.messagesListContentRef.current.scrollTop = this.messagesListContentRef.current.scrollHeight;
    }
  };

  private registerModuleEvents() {
    this.moduleEvents = [
      ...this.moduleEvents,
      { name: EventsList.MESSAGES_SEND_NEW_MESSAGE, callback: this.scrollMessagesContainerToBottom },
    ];
    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }
}

export default MessagesList;
