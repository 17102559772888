import InvoiceDetailsPaymentsApi from '../api/InvoiceDetailsPaymentsApi';
import { GetInvoiceDetailsPaymentsRequest } from '../types/GetInvoiceDetailsPaymentsRequest';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { InvoiceDetailsPaymentItem } from '../types/InvoiceDetialsPaymentItem';

class InvoiceDetailsPaymentsService {
  public async getInvoiceDetailsPayments(
    request: GetInvoiceDetailsPaymentsRequest
  ): Promise<IComponentTable<InvoiceDetailsPaymentItem>> {
    return await InvoiceDetailsPaymentsApi.getInvoiceDetailsPayments(request);
  }
}

export default new InvoiceDetailsPaymentsService();
