import React from 'react';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import TranslationService from '../../../../core/services/TranslationService';
import { CreditorAccountSummaryType } from '../types/CreditorAccountSummaryType';
import CurrencyTableRow from './CurrencyTableRow';
import Table from './Table';

type Props = {
  summary: CreditorAccountSummaryType;
};

export default (props: Props) => {
  const { summary } = props;

  return (
    <article className="l-section">
      <header className="l-section__header">
        <h1>{TranslationService.translateModule('DebtCollectionSummary', ModuleNamesList.CreditorAccountSummary)}</h1>
      </header>

      <Table
        striped={true}
        className="c-table"
        headers={[
          TranslationService.translateModule('Total', ModuleNamesList.CreditorAccountSummary),
          ...(summary.guarantorCasesCount
            ? [
                TranslationService.translateModule('MainCases', ModuleNamesList.CreditorAccountSummary),
                TranslationService.translateModule('GuarantorCases', ModuleNamesList.CreditorAccountSummary),
              ]
            : []),
        ]}
        key="table_3"
        firstHeaderEmpty={true}
      >
        <CurrencyTableRow
          uniqueKey="BalanceCapital"
          boldColumns={[0]}
          label={TranslationService.translateModule('BalanceCapital', ModuleNamesList.CreditorAccountSummary)}
          values={[...summary.debtCollectionSpecifications.map((d) => d['balanceCapital'])]}
        />
        <CurrencyTableRow
          uniqueKey="BalanceInterest"
          boldColumns={[0]}
          label={TranslationService.translateModule('BalanceInterest', ModuleNamesList.CreditorAccountSummary)}
          values={[...summary.debtCollectionSpecifications.map((d) => d['balanceInterest'])]}
        />
        <CurrencyTableRow
          uniqueKey="BalanceFee1"
          boldColumns={[0]}
          label={TranslationService.translateModule('BalanceFee', ModuleNamesList.CreditorAccountSummary)}
          values={[...summary.debtCollectionSpecifications.map((d) => d['balanceFee'])]}
        />
        <CurrencyTableRow
          uniqueKey="Sum"
          boldColumns={[0]}
          label={TranslationService.translateModule('Sum', ModuleNamesList.CreditorAccountSummary)}
          // eslint-disable-next-line
          values={[
            ...summary.debtCollectionSpecifications.filter((v) => v['sum'] != undefined).map((d) => d['sum'] as number),
          ]}
        />
      </Table>
    </article>
  );
};
