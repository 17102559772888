import React from 'react';
import { IModuleProps } from '../../core/types/IModuleProps';
// import CustomLabel from './components/CustomLabel';
import './_CaseStatisticsPortfolio.scss';
import { EventsList } from '../../core/lists/EventsList';
import { IModuleEventSubscription } from '../../core/types/IModuleEventSubscription';
import { IPortfolioChartData } from './types/IPortfolioChartData';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import CaseStatisticsPortfolioApiClient from './CaseStatisticsPortfolioApiClient';
import Loader from '../../core/components/Loading/Loader';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { PieChart } from 'react-minimal-pie-chart';
import TranslationService from '../../core/services/TranslationService';
import withAbortRequest, { AbortRequestPropsType } from '../../core/hoc/AbortRequest';

interface IProps extends IModuleProps, AbortRequestPropsType {
  locale: string;
}

interface IState {
  data: IPortfolioChartData[];
  loading: boolean;
}

class CaseStatisticsPortfolio extends React.Component<IProps, IState> {
  public state: IState = {
    loading: false,
    data: [],
  };
  private moduleEvents: IModuleEventSubscription[];

  public componentDidMount = async () => {
    this.registerModuleEvents();
    await this.loadData();
  };

  public componentWillUnmount() {
    ModuleEventSubscriber.unsubscribeEvents(this.moduleEvents);
  }

  public loadData = async () => {
    this.setState({
      loading: true,
    });
    const data = await CaseStatisticsPortfolioApiClient.GetData(this.props.cancelTokenSource.token);

    this.setState(() => {
      const portfolioData = data.filter((item) => {
        return item.value > 0;
      });

      return {
        loading: false,
        data: portfolioData,
      };
    });
  };

  public render() {
    return (
      <div className="module-box">
        <div className="module-box__content">
          <header
            className="module-box__header"
            dangerouslySetInnerHTML={{
              __html: TranslationService.translateModule('ChartHeader', ModuleNamesList.CaseStatisticsPortfolio),
            }}
          />
          <div className="module-box-content chart-portfolio">
            {this.state.loading ? (
              <Loader />
            ) : (
              <div className="chart">
                <PieChart data={this.state.data} lineWidth={34} labelPosition={115} />

                <ul className="chart-legend">
                  {this.state.data.reverse().map((elem: any, index: number) => {
                    return (
                      <li key={index}>
                        <span style={{ background: elem.color }}>{elem.textValue}</span>

                        {elem.subHeader}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private registerModuleEvents() {
    this.moduleEvents = [{ name: EventsList.CHANGED_CURRENT_CREDITOR, callback: this.loadData }];
    ModuleEventSubscriber.registerEvents(this.moduleEvents);
  }
}
export default withAbortRequest(CaseStatisticsPortfolio);
