import React from 'react';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormFieldset } from '../../../core/components/Forms/FormFieldset';
import { FormGroup } from '../../../core/components/Forms/FormGroup';
import { IDictionary } from '../../../core/types/IDictionary';
import { InviteUserService } from '../services/InviteUserService';
import { IUsersModuleCreditorVm } from '../types/IUsersModuleCreditorVm';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import * as yup from 'yup';
import InfoMessageService from '../../../core/services/InfoMessageService';
import { SimpleSelect } from '../../../core/components/Forms/SimpleSelect';
import TranslationService from '../../../core/services/TranslationService';

export interface IState {
  creditor: string;
  creditorOptions: {};
}

export interface IProps {
  creditors: IUsersModuleCreditorVm[];
  moduleId: number;
  onSubmit: () => void;
}

export interface IFormValues {
  email: string;
  roleId: string;
  creditor: string;
}

class InviteUser extends React.Component<IProps, IState> {
  state: IState = {
    creditor: '',
    creditorOptions: {},
  };

  initialFormValues = {
    email: '',
    roleId: '',
    creditor: '',
  } as IFormValues;

  validationMessages = {
    required: TranslationService.translate('ValidationMessageRequiredField'),
    email: TranslationService.translate('ValidationMessageIncorrentEmailFormat'),
    roleId: TranslationService.translate('ValidationMessageRoleIdNotSelected'),
  };

  validationSchema = yup.object().shape({
    creditor: yup.string().required(this.validationMessages.required),
    email: yup.string().email(this.validationMessages.email).required(this.validationMessages.required),
    roleId: yup.string().required(this.validationMessages.required),
  });

  componentDidMount() {
    this.handleCreditorsOptions();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.creditors !== this.props.creditors) {
      this.handleCreditorsOptions();
    }
  }

  render() {
    const roleOptions: IDictionary<string> = {};

    if (this.state.creditor) {
      const selectedCreditor = this.props.creditors.filter((c) => c.creditorId === this.state.creditor)[0];

      selectedCreditor.roles.forEach((r) => {
        roleOptions[r.roleId] = r.roleName;
      });
    }

    return (
      <div>
        <Formik
          initialValues={this.initialFormValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            this.handleSubmitFormik(values, { setSubmitting, resetForm });
          }}
          enableReinitialize={true}
          validationSchema={this.validationSchema}
          render={({ values, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
            <Form className="c-form">
              <FormFieldset
                label={TranslationService.translateModule('InviteUser', ModuleNamesList.Users)}
                render={() => (
                  <div className="c-form__row row">
                    <div className="col-12 col-sm-4 col-xl-3">
                      <FormGroup
                        label={TranslationService.translateModule('Creditor', ModuleNamesList.Users)}
                        name="creditor"
                        render={(name) => (
                          <SimpleSelect
                            id="creditors"
                            value={values.creditor || ''}
                            onChangeHandle={(option) => {
                              setFieldValue(name, option.key);

                              this.setState({
                                creditor: option.key,
                              });
                            }}
                            options={this.state.creditorOptions}
                            name={name}
                          />
                        )}
                      />
                    </div>

                    <div className="col-12 col-sm-4 col-xl-3">
                      <FormGroup
                        label={TranslationService.translateModule('InvitationEmail', ModuleNamesList.Users)}
                        name="email"
                        render={(name) => (
                          <>
                            <input
                              className="c-form-control"
                              name={name}
                              type="email"
                              value={values.email || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={TranslationService.translateModule('InvitationEmail', ModuleNamesList.Users)}
                            />

                            <ErrorMessage name={name} component="div" className="error error-message" />
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 col-sm-4 col-lg-4 col-xl-3">
                      <FormGroup
                        label={TranslationService.translateModule('Role', ModuleNamesList.Users)}
                        name="roleId"
                        render={(name) => (
                          <SimpleSelect
                            id="roles-list"
                            value={values.roleId || ''}
                            onChangeHandle={(option) => {
                              setFieldValue(name, option.key);
                            }}
                            options={roleOptions}
                            name={name}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              />

              <div className="row">
                <div className="col-12 col-sm-4 col-lg-4 col-xl-3 mt-2 mt-sm-0">
                  <Button
                    type="submit"
                    id="inviteCreditorBtn"
                    disabled={isSubmitting}
                    showLoader={isSubmitting}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTON_SIZE.MD}
                    label={TranslationService.translateModule('SendInvitation', ModuleNamesList.Users)}
                  />
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }

  handleSubmitFormik = async (values: IFormValues, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);

    const submitRoleId = values.roleId;
    const submitCreditorId = this.state.creditor;
    const submitEmail = values.email ? values.email : '';

    try {
      const responseInviteUserService = await InviteUserService.inviteUser(
        this.props.moduleId,
        submitCreditorId,
        submitEmail,
        submitRoleId as string
      );

      switch (responseInviteUserService.status) {
        case 1:
          InfoMessageService.success(
            TranslationService.translateModule('InvitationSentSuccess', ModuleNamesList.Users)
          );
          break;
        case 2:
          InfoMessageService.warning(TranslationService.translateModule('UserAlreadyInvited', ModuleNamesList.Users));
          break;
      }

      if (this.props.onSubmit) {
        this.props.onSubmit();
      }

      setSubmitting(false);
      resetForm(this.initialFormValues);
    } catch {
      InfoMessageService.error(TranslationService.translateModule('InvitationSentError', ModuleNamesList.Users));
    }
  };

  handleCreditorsOptions = () => {
    const creditorOptions = {};
    this.props.creditors.forEach(
      (creditor) => (creditorOptions[creditor.creditorId] = `${creditor.name} (${creditor.creditorId})`)
    );
    this.setState({
      creditorOptions,
    });
  };
}

export default InviteUser;
