import React from 'react';
import { CreditorAccountSummaryType } from '../types/CreditorAccountSummaryType';
import TranslationService from '../../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../../core/lists/ModuleNamesList';
import { UnitCasePaymentDetails } from '../types/UnitCasePaymentDetails';
import CurrencyParser from '../../../../core/helpers/CurrencyParser';
import Table from './Table';

const UnitCasePaymentDetailsTable = (props: { summary: CreditorAccountSummaryType }) => {
  return (
    <div className="cas__ucpd-table-wrapper">
      <Table
        striped={true}
        className="c-table"
        headers={[
          TranslationService.translateModule('DebtorName', ModuleNamesList.CreditorAccountSummary),
          TranslationService.translateModule('KID', ModuleNamesList.CreditorAccountSummary),
          TranslationService.translateModule('PaymentCollectionInfo', ModuleNamesList.CreditorAccountSummary),
          TranslationService.translateModule('AmountInclCost', ModuleNamesList.CreditorAccountSummary),
        ]}
        headersClassName=""
        key="table_4"
      >
        {props.summary.unitCasePaymentDetails.map((item: UnitCasePaymentDetails, index: number) => (
          <tr key={`UnitCasePaymentDetailsRow_${index}`}>
            <td>{item.debtorName}</td>
            <td>{item.kid}</td>
            <td>{props.summary.kontoNumber}</td>
            <td className="c-table__cell c-table__cell--text-right">
              {CurrencyParser.toLocaleString(item.totalAmount)}
            </td>
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default UnitCasePaymentDetailsTable;
