import * as React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import TranslationService from '../../../core/services/TranslationService';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { ICreditor } from '../types/ICreditor';

class CreditorsVerificationTableConfigurator {
  public getTableColumns = (table: IComponentTable<ICreditor>, openDetailsModal: (creditor: ICreditor) => void) => {
    let columns: IComponentTableColumn[] = [];
    if (table != null) {
      columns = [...table.columns];
      this.configureCPFirstname(columns);
      this.configureCPLastname(columns);
      this.configureCPPhonenumber(columns);
      this.configureCPEmail(columns);
      this.configureActions(columns, openDetailsModal);

      return columns;
    }
    return columns;
  };

  private configureCPFirstname = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'cPFirstName');
    if (column) {
      column.formatter = (value: any, creditor: ICreditor) => creditor.contactDetails.firstName;
    }
  };

  private configureCPLastname = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'cPLastName');
    if (column) {
      column.formatter = (value: any, creditor: ICreditor) => creditor.contactDetails.lastName;
    }
  };

  private configureCPPhonenumber = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'cPPhoneNumber');
    if (column) {
      column.formatter = (value: any, creditor: ICreditor) => creditor.contactDetails.phoneNumber;
    }
  };

  private configureCPEmail = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'cPEmail');
    if (column) {
      column.formatter = (value: any, creditor: ICreditor) => creditor.contactDetails.email;
    }
  };

  private configureActions = (columns: IComponentTableColumn[], openDetailsModal: (creditor: ICreditor) => void) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'actions');
    if (column) {
      column.sort = false;
      column.formatter = (value: any, creditor: ICreditor) => this.actionsRowData(value, creditor, openDetailsModal);
    }
  };

  private actionsRowData = (value: any, creditor: ICreditor, openDetailsModal: (creditor: ICreditor) => void) => {
    return (
      <Dropdown alignRight className="button-table">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {TranslationService.translateModule('Actions', ModuleNamesList.CreditorsVerification)}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              openDetailsModal(creditor);
            }}
          >
            <i className="fas fa-user-plus" />{' '}
            {TranslationService.translateModule('ActionsShowDetails', ModuleNamesList.CreditorsVerification)}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

export default new CreditorsVerificationTableConfigurator();
