import React from 'react';
import { IConversationsListProps } from '../types/IConversationsListProps';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { IMessage } from '../types/IMessage';
import { IConversationListElement } from '../types/IConversationListElement';
import TranslationService from '../../../core/services/TranslationService';
import { ModuleNamesList } from '../../../core/lists/ModuleNamesList';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import Loader from '../../../core/components/Loading/Loader';
import ConversationsListHeader from './ConversationsListHeader';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../../core/components/Button/Button';

interface IConversationsListState {
  searchValue: string;
  submittedSearchValue: string;
}

class ConversationsList extends React.Component<IConversationsListProps, IConversationsListState> {
  public state: IConversationsListState = {
    searchValue: '',
    submittedSearchValue: '',
  };

  private conversationsListContentRef = React.createRef<HTMLDivElement>();

  public changeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      searchValue: event.target.value,
    });
  };

  public handleSearchInputKeypress = (event: React.KeyboardEvent) => {
    if (event.charCode === 13) {
      this.filterConversations();
    }
  };

  public filterConversations = () => {
    this.setState({
      submittedSearchValue: this.state.searchValue,
    });

    this.props.filterConversationsList(this.state.searchValue);
  };

  public handleConversationListScroll = async (event: any) => {
    const touchedBottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (!this.props.isConversationsLimit && touchedBottom && !this.props.isConversationsLoading) {
      const scrollTopPosition = event.target.scrollTop;
      await this.props.fetchNextPage();
      this.conversationsListContentRef.current!.scrollTop = scrollTopPosition - 100;
    }
  };

  public render = () => {
    const classesNames = `
            ${this.props.staticHeight ? 'm-conversations--full-height' : ''} 
            ${this.props.currentConversationIsMobileOpened ? 'c-view' : ''}
            ${!!this.props.className ? this.props.className : ''}
        `;

    return (
      <article className={`l-module m-conversations ${classesNames}`}>
        <section className="l-module__section m-conversations__filters">
          <div className="input-group">
            <input
              type="text"
              name="search"
              id="searchMessages"
              className="form-control standard"
              value={this.state.searchValue}
              onChange={this.changeSearchValue}
              onKeyPress={this.handleSearchInputKeypress}
              placeholder={TranslationService.translate('Search')}
            />
            <div className="input-group-append">
              <button
                className="search-button btn btn-light conversations-search"
                onClick={this.filterConversations}
                type="submit"
              >
                <span className="fas fa-search" />
              </button>
            </div>
          </div>
        </section>

        <section className="l-module__section m-conversations__content">
          {this.props.conversationsTable && this.props.conversationsTable.values.length > 0 ? (
            <div className="m-conversations__list">
              <ConversationsListHeader
                changeSortType={this.props.changeSortType}
                orderDirection={this.props.orderDirection}
                orderedBy={this.props.orderedBy}
              />

              <div
                className={`m-conversations__list-body ${
                  !this.props.currentConversationIsMobileOpened && 'animated fadeIn'
                }`}
              >
                <div
                  className="conversations-list"
                  onScroll={this.handleConversationListScroll}
                  ref={this.conversationsListContentRef}
                >
                  <ul className="conversations-list-content">
                    {this.props.conversationsTable.values.map((element: IConversationListElement, index: number) => (
                      <li
                        key={index}
                        className={`${
                          this.props.currentConversation !== undefined &&
                          this.props.currentConversation.id === element.id &&
                          'active'
                        } ${this.props.answeredConversationsIds.indexOf(element.id) > -1 ? 'answered' : ''}`}
                        onClick={() => this.props.onRowClick(element)}
                      >
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-md-2 col-sm-2 mb-1">
                              <b className={element.isOverdue ? 'date-overdue' : ''}>
                                {DateTimeParser.toLocaleDateString(element.dueDate)}
                              </b>
                            </div>
                            <div className="col-12 col-sm-10">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <label className="light">
                                    {TranslationService.translateModule(
                                      'ConversationListDebtorLabel',
                                      ModuleNamesList.Messages
                                    )}
                                    :
                                  </label>
                                  <b>
                                    {element.debtor.firstName} {element.debtor.lastName} / {element.debtor.number}
                                  </b>
                                </div>
                                <div className="col-12 col-md-3 text-nowrap">
                                  <label className="light d-inline-block d-md-none">
                                    {TranslationService.translateModule(
                                      'MessagesTableCaseReference',
                                      ModuleNamesList.Messages
                                    )}
                                    :
                                  </label>
                                  <b>
                                    {element.caseDetails.caseNumber}{' '}
                                    {element.caseDetails.referenceNumber !== '' ? '/' : ''}{' '}
                                    {element.caseDetails.referenceNumber}
                                  </b>
                                </div>
                                <div className="col-12 col-md-3 text-nowrap">
                                  <label className="light d-inline-block d-md-none">
                                    {TranslationService.translateModule(
                                      'MessagesTableCategory',
                                      ModuleNamesList.Messages
                                    )}
                                    :
                                  </label>
                                  <b>{element.category}</b>
                                </div>
                                <div className="col-12 col-md-6">
                                  <label className="nowrappable">
                                    <b>{element.topMessage.senderDetails}</b>:{' '}
                                    {this.getMessageContent(element.topMessage)}
                                  </label>
                                </div>
                                <div className="col-auto ml-auto">
                                  {this.props.answeredConversationsIds.indexOf(element.id) > -1 && (
                                    <>
                                      <i className="far fa-check-circle answered-icon" />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            !this.props.isConversationsLoading && (
              <div className="alert alert-info mt-3 animated fadeIn" role="alert">
                {this.state.submittedSearchValue
                  ? TranslationService.translateModule('NoFilterResults', ModuleNamesList.Messages)
                  : TranslationService.translateModule('NotMessagesInCurrentFolder', ModuleNamesList.Messages)}
              </div>
            )
          )}
        </section>

        <section className="l-module__section m-conversations__footer">
          {this.props.enableAddNewConversation && !this.props.isConversationsLoading && (
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              className="c-btn--icon-only"
              size={BUTTON_SIZE.MD}
              label={TranslationService.translateModule('AddNewMessage', ModuleNamesList.Messages)}
              icon={{ className: 'fas fa-plus', position: '' }}
              onClick={this.props.onAddNewConversation}
            />
          )}
        </section>

        {this.props.isConversationsLoading && <Loader cssClass="mt-3" />}
      </article>
    );
  };

  private getMessageContent = (message: IMessage) => {
    return message.content.slice(0, 35);
  };
}

export default ConversationsList;
