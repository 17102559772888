import * as React from 'react';
import AutoOnboardingApiClient from '../../core/api/AutoOnboardingApiClient';
import { Status } from '../../core/api/Enums/Status';
import AlertMessages from '../../core/components/AlertMessages/AlertMessages';
import { Card } from '../../core/components/Card/Card';
import { CardContent } from '../../core/components/Card/components/CardContent';
import { CardHeader, CardHeaderVariants } from '../../core/components/Card/components/CardHeader';
import Loader from '../../core/components/Loading/Loader';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { AutoOnboardingTokenStatus, IAutoOnboardingTokenDataModel } from '../../core/types/IAutoOnboardingTokenData';
import { INumberDictionary } from '../../core/types/IDictionary';
import { IModuleProps } from '../../core/types/IModuleProps';
import StepList from '../StepListWizard/StepList';
import { IConfirmations } from '../StepListWizard/types/IConfirmations';
import { IStepListItem } from '../StepListWizard/types/IStepListItem';
import { StepIconsCss } from '../StepListWizard/types/StepIconCss';
import Agreement from './components/Agreement';
import CompanyForm from './components/CompanyForm';
import Confirmation from './components/Confirmation';
import FinancialsForm from './components/FinancialsForm';
import Services from './components/Services';
import Summary from './components/Summary';
import CreditorRegistrationApiClient from './CreditorRegistrationApiClient';
import { CreditorRegistrationSteps } from './enums/CreditorRegistrationSteps';
import { AutoOnboardingStatus } from './types/AutoOnboardingStatus';
import { emptyCompany, ICompanyForm } from './types/ICompanyForm';
import { ICreditorAccountancyInformation } from './types/ICreditorAccountancyInformation';
import { ICreditorRegistrationState } from './types/ICreditorRegistrationState';

class CreditorRegistration extends React.Component<IModuleProps> {
  public state: ICreditorRegistrationState = {
    companyForm: emptyCompany,
    componentIsLoading: false,
    configuration: {
      confirmations: {
        byEmail: true,
        byPhone: true,
      },
      userEmail: '',
      userPhone: '',
      isBrregEnabled: false,
      isCredRegisterWithoutAOTokenDisabled: true,
      userCreditorRegistrationEnabled: true,
      disableEnableRegisterNewCompanyButton: false,
    },
    currentStep: CreditorRegistrationSteps.CompanyDetails,
    currentStepIndex: 0,
    errorMessages: [],
    isLoading: true,
    stepsList: [],
    autoOnboardingContext: false,
    tokenStatus: undefined,
    notAllowedTokenStatus: [AutoOnboardingStatus.Created, AutoOnboardingStatus.Done],
  };

  public componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });

    const queryString = this.props.routeParameters;
    await this.loadInstanceSettings();
    const token = queryString['token'];
    if (token) {
      await this.loadTokenData(token as string);
    }

    const templateCode = queryString['code'];
    if (templateCode) {
      this.setState({
        companyForm: { ...this.state.companyForm, templateCode },
      });
    }

    this.setState({
      isLoading: false,
    });
  };

  public isInAutoOnboardingTokenContext = () => {
    const queryString = this.props.routeParameters;
    const token = queryString['token'];

    return !!token;
  };

  public getAutoOnboardingToken = () => {
    const queryString = this.props.routeParameters;
    return queryString['token'];
  };

  public loadInstanceSettings = async () => {
    const instanceSettings = await CreditorRegistrationApiClient.GetModuleSettings(this.props.module.id);
    const stepsList = this.getStepComponentsList(instanceSettings.confirmations);
    this.setState({
      ...this.state,
      stepsList,
      configuration: instanceSettings,
      isLoading: false,
    });
  };

  public loadTokenData = async (token: string) => {
    const tokenData = await AutoOnboardingApiClient.GetOnboardingInitialData(token);
    const tokenDataModel = JSON.parse(tokenData.data) as IAutoOnboardingTokenDataModel;
    const creditor = tokenDataModel.creditor;

    if (tokenData && creditor) {
      this.setState({
        companyForm: {
          ...emptyCompany,
          organizationNumber: tokenDataModel.creditor.organizationNumber || emptyCompany.organizationNumber,
          organizationName: tokenDataModel.creditor.companyName || emptyCompany.organizationName,
          industryCode: tokenDataModel.creditor.legalType ? tokenDataModel.creditor.legalType.split(' ')[0] : '',
          address: {
            street: tokenDataModel.creditor.address
              ? tokenDataModel.creditor.address.street
              : emptyCompany.address.street,
            address2: '',
            postalCode: tokenDataModel.creditor.address
              ? tokenDataModel.creditor.address.postalCode
              : emptyCompany.address.postalCode,
            city: tokenDataModel.creditor.address ? tokenDataModel.creditor.address.city : emptyCompany.address.city,
          },
          generalInformation: {
            ...emptyCompany.generalInformation,
            email: tokenDataModel.creditor.email || emptyCompany.contactDetails.email,
            phoneNumber: tokenDataModel.creditor.phoneNumber || emptyCompany.contactDetails.phoneNumber,
          },
          contactDetails: {
            ...emptyCompany.contactDetails,
            firstName: tokenDataModel.user.firstName || emptyCompany.contactDetails.firstName,
            lastName: tokenDataModel.user.lastName || emptyCompany.contactDetails.lastName,
            phoneNumber: tokenDataModel.creditor.phoneNumber || emptyCompany.contactDetails.phoneNumber,
            email: tokenDataModel.creditor.email || emptyCompany.contactDetails.email,
          },
          accountancyInformation: {
            ...emptyCompany.accountancyInformation,
            vatLiable: !!tokenDataModel.creditor.vatNo || emptyCompany.accountancyInformation.vatLiable,
          },
          templateCode: tokenDataModel.feedback.agreement || emptyCompany.templateCode,
          feedbackUrl: tokenDataModel.feedback.feedbackUrl || emptyCompany.feedbackUrl,
          refId: tokenDataModel.feedback.refId || emptyCompany.refId,
        },
        autoOnboardingContext: true,
        tokenStatus: tokenData.status,
      });
    }
  };

  public resetWizard = () => {
    sessionStorage.removeItem('CompanyForm');
    sessionStorage.removeItem('CompanyFormFilled');

    sessionStorage.removeItem('AgreementForm');
    sessionStorage.removeItem('AgreementFormFilled');

    sessionStorage.removeItem('FinancialsForm');
    sessionStorage.removeItem('FinancialsFormFilled');

    this.setState({
      ...this.state,
      currentStep: CreditorRegistrationSteps.CompanyDetails,
      currentStepIndex: 0,
      companyForm: emptyCompany,
    });
  };

  public setCompanyDetails = (companyForm: ICompanyForm) => {
    this.setState({
      ...this.state,
      companyForm,
    });
    this.goToNextStep();
  };

  public setCompanyExternalData = async (orgNo: string) => {
    this.setState({ componentIsLoading: true });

    try {
      const companyFormStatus = await CreditorRegistrationApiClient.GetCompanyDataFromExternalProvider(
        this.props.module.id,
        orgNo
      );

      if (companyFormStatus.status === Status.Error) {
        InfoMessageService.warning(
          TranslationService.translateModule(
            'CompanyWasNotFoundInExternalProvider',
            ModuleNamesList.CreditorRegistration
          )
        );
      } else {
        this.setState({ companyForm: companyFormStatus.data });
      }
    } finally {
      this.setState({ componentIsLoading: false });
    }
  };

  public setFinancialsData = (accountancyInformation: ICreditorAccountancyInformation) => {
    this.setState({
      ...this.state,
      companyForm: {
        ...this.state.companyForm,
        accountancyInformation,
      },
    });
    this.goToNextStep();
  };

  public render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    if (
      (this.state.tokenStatus && this.state.notAllowedTokenStatus.indexOf(this.state.tokenStatus) >= 0) ||
      (!this.state.tokenStatus && this.state.configuration.isCredRegisterWithoutAOTokenDisabled)
    ) {
      return (
        <div className="animated fadeIn">
          <div className="register-user mt-4 text-center">
            <Card>
              <CardContent>
                <div className="alert alert-info" role="alert">
                  {TranslationService.translateModule(
                    'CreditorRegistrationAutoOnBoardingOnly',
                    ModuleNamesList.CreditorRegistration
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      );
    } else if (this.state.configuration.userCreditorRegistrationEnabled) {
      return (
        <div className="animated fadeIn">
          <div className="register-user mt-4">
            {this.state.errorMessages.length > 0 && (
              <AlertMessages
                messages={this.state.errorMessages}
                translate={true}
                moduleName={ModuleNamesList.CreditorRegistration}
                alertClass="alert-danger"
              />
            )}

            <Loader opacity={0.5} isLoaded={!this.state.componentIsLoading} />

            <Card class="mb-4">
              <>
                <CardHeader variant={CardHeaderVariants.Secondary}>
                  <StepList center={true} stepList={this.state.stepsList} currentStep={this.state.currentStepIndex} />
                </CardHeader>
                <CardContent>{this.getStepComponent()}</CardContent>
              </>
            </Card>
          </div>
        </div>
      );
    } else {
      return (
        <div className="animated fadeIn">
          <div className="register-user mt-5 text-center">
            <Card>
              <CardContent>
                <h2>
                  {TranslationService.translateModule(
                    'CreditorRegistrationDisabled',
                    ModuleNamesList.CreditorRegistration
                  )}
                </h2>
              </CardContent>
            </Card>
          </div>
        </div>
      );
    }
  }

  private goToNextStep = async () => {
    const nextStepIndex = this.state.currentStepIndex + 1;
    await this.goToStep(nextStepIndex);
  };

  private backToPrevStep = async () => {
    const nextStepIndex = this.state.currentStepIndex - 1;
    await this.goToStep(nextStepIndex);
  };

  private goToStep = async (stepIndex: number) => {
    const nextStep = parseInt(Object.keys(this.state.stepsList)[stepIndex], 10) as CreditorRegistrationSteps;
    let goToNextStep: boolean = true;
    if (nextStep === CreditorRegistrationSteps.Summary) {
      goToNextStep =
        (await this.saveCreditor()) &&
        (!this.isInAutoOnboardingTokenContext() ||
          (this.isInAutoOnboardingTokenContext() &&
            (await AutoOnboardingApiClient.UpdateOnboardingTokenStatus(
              this.getAutoOnboardingToken() as string,
              AutoOnboardingTokenStatus.Done
            ))));
    }

    if (goToNextStep) {
      this.setState({
        ...this.state,
        currentStep: nextStep,
        currentStepIndex: stepIndex,
        errorMessages: [],
      });
    }
  };

  private saveCreditor = async (): Promise<boolean> => {
    this.setState({ componentIsLoading: true });
    const status = await CreditorRegistrationApiClient.CreateNewCreditor(this.state.companyForm, this.props.module.id);
    this.setState({
      componentIsLoading: false,
      errorMessages: status.messages !== null && status.messages !== undefined ? status.messages : [],
    });

    if (status.status === Status.Warning) {
      InfoMessageService.displayActionStatus(status);
    }

    if (status.status === Status.Success) {
      this.setState({
        companyForm: {
          ...this.state.companyForm,
          generalInformation: {
            ...this.state.companyForm.generalInformation,
            creditorNumber: status.data.creditorNumber,
          },
        },
      });
      if (this.state.companyForm.feedbackUrl) {
        let feedbackUrl = this.assembleFeedbackUrl(this.state);
        window.open(feedbackUrl, '_self');
      }
    }
    return status.status !== Status.Error;
  };

  private assembleFeedbackUrl = (state: ICreditorRegistrationState): string => {
    if (state.companyForm.refId) {
      // feedbackUrl which will be used by customer will end on '/finance' so we don't need slash before '?Ref'. It is specified in feature description.
      return `${state.companyForm.feedbackUrl}?Ref=${state.companyForm.organizationNumber}&creditorNumber=${state.companyForm.generalInformation.creditorNumber}&RefID=${state.companyForm.refId}`;
    }
    return `${state.companyForm.feedbackUrl}?Ref=${state.companyForm.organizationNumber}&creditorNumber=${state.companyForm.generalInformation.creditorNumber}`;
  };

  private getStepComponentsList = (confirmations: IConfirmations): INumberDictionary<IStepListItem> => {
    const stepComponents: INumberDictionary<IStepListItem> = [];

    stepComponents[CreditorRegistrationSteps.CompanyDetails] = {
      iconCss: StepIconsCss.Company,
      text: TranslationService.translateModule('CompanyStepTitle', ModuleNamesList.CreditorRegistration),
    };
    stepComponents[CreditorRegistrationSteps.Financials] = {
      iconCss: StepIconsCss.Financial,
      text: TranslationService.translateModule('FinancialStepTitle', ModuleNamesList.CreditorRegistration),
    };
    stepComponents[CreditorRegistrationSteps.Agreement] = {
      iconCss: StepIconsCss.Agreement,
      text: TranslationService.translateModule('AgreementStepTitle', ModuleNamesList.CreditorRegistration),
    };

    if (confirmations.byEmail || confirmations.byPhone) {
      stepComponents[CreditorRegistrationSteps.Confirmation] = {
        iconCss: StepIconsCss.Confirmation,
        text: TranslationService.translateModule('ConfirmationStepTitle', ModuleNamesList.CreditorRegistration),
      };
    }

    stepComponents[CreditorRegistrationSteps.Summary] = {
      iconCss: StepIconsCss.Welcome,
      text: TranslationService.translateModule('SummaryStepTitle', ModuleNamesList.CreditorRegistration),
    };

    return stepComponents;
  };

  private getStepComponent = () => {
    switch (this.state.currentStep) {
      case CreditorRegistrationSteps.CompanyDetails:
        return (
          <CompanyForm
            onCompanyDataFilled={this.setCompanyDetails}
            configuration={this.state.configuration}
            companyForm={this.state.companyForm}
            onFetchCompanyData={(orgNo) => this.setCompanyExternalData(orgNo)}
            prefilledFields={this.state.autoOnboardingContext}
          />
        );
      case CreditorRegistrationSteps.Financials:
        return (
          <FinancialsForm
            onFinancialsDataFilled={this.setFinancialsData}
            backToPrevStep={this.backToPrevStep}
            accountancyInformation={this.state.companyForm.accountancyInformation}
            prefilledFields={this.state.autoOnboardingContext}
          />
        );
      case CreditorRegistrationSteps.Services:
        return <Services servicesUIds={['not', 'implemented', 'yet']} backToPrevStep={this.backToPrevStep} />;
      case CreditorRegistrationSteps.Agreement:
        return (
          <Agreement
            backToPrevStep={this.backToPrevStep}
            goToNextStep={this.goToNextStep}
            companyForm={this.state.companyForm}
          />
        );
      case CreditorRegistrationSteps.Confirmation:
        return (
          <Confirmation
            backToPrevStep={this.backToPrevStep}
            goToNextStep={this.goToNextStep}
            configuration={this.state.configuration}
            moduleInstanceid={this.props.module.id}
          />
        );
      case CreditorRegistrationSteps.Summary:
        const isDisabledCreateNewCreditor =
          (this.state.tokenStatus &&
            this.state.notAllowedTokenStatus.indexOf(this.state.tokenStatus) >= 0 &&
            this.state.configuration.isCredRegisterWithoutAOTokenDisabled) ||
          !this.state.configuration.disableEnableRegisterNewCompanyButton;
        return (
          <Summary
            companyForm={this.state.companyForm}
            resetWizard={this.resetWizard}
            isDisabledCreateNewCreditor={isDisabledCreateNewCreditor as boolean}
          />
        );
    }
  };
}

export default CreditorRegistration;
