import { IComponentTable } from '../../../core/types/IComponentTable';
import { InvoiceDetailsPaymentItem } from '../types/InvoiceDetialsPaymentItem';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import { InvoiceDetailsPaymentsColumns } from './InvoiceDetailsPaymentsColumns';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import DateTimeParser from '../../../core/helpers/DateTimeParser';

class InvoiceDetailsPaymentsTableConfigurator {
  public getColumns = (invoicesListTable: IComponentTable<InvoiceDetailsPaymentItem>) => {
    let columns: IComponentTableColumn[] = [];
    if (invoicesListTable != null) {
      columns = [...invoicesListTable.columns];
      this.configureCurrencyColumns(columns);
      this.configureDateColumns(columns);

      return columns;
    }
    return columns;
  };

  private configureCurrencyColumns = (columns: IComponentTableColumn[]) => {
    const currencyColumns = [InvoiceDetailsPaymentsColumns.Amount];
    const currencyColumnsNames = currencyColumns.map(
      (col) => InvoiceDetailsPaymentsColumns[col].charAt(0).toLowerCase() + InvoiceDetailsPaymentsColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return CurrencyParser.toLocaleString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };

  private configureDateColumns = (columns: IComponentTableColumn[]) => {
    const dateColumns = [InvoiceDetailsPaymentsColumns.Date];
    const currencyColumnsNames = dateColumns.map(
      (col) => InvoiceDetailsPaymentsColumns[col].charAt(0).toLowerCase() + InvoiceDetailsPaymentsColumns[col].slice(1)
    );
    const cols = columns.filter((col) => currencyColumnsNames.indexOf(col.dataField) >= 0);
    const formatterFunc = (value: any, row: any) => {
      return DateTimeParser.toLocaleDateString(value);
    };

    for (const col of cols) {
      col.formatter = formatterFunc;
    }
  };
}

export default new InvoiceDetailsPaymentsTableConfigurator();
