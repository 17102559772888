import InvoiceDetailsActivitiesApi from '../api/InvoiceDetailsActivitiesApi';
import { GetInvoiceDetailsActivitiesRequest } from '../types/GetInvoiceDetailsActivitiesRequest';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IndexedInvoiceDetailsActivityItem } from '../types/InvoiceDetailsActivityItem';

class InvoiceDetailsActivitiesService {
  public async getInvoiceDetailsActivities(
    request: GetInvoiceDetailsActivitiesRequest
  ): Promise<IComponentTable<IndexedInvoiceDetailsActivityItem>> {
    const table = await InvoiceDetailsActivitiesApi.getInvoiceDetailsActivities(request);

    return {
      ...table,
      values: [...table.values.map((val, index) => ({ ...val, id: index }))],
    };
  }
}

export default new InvoiceDetailsActivitiesService();
