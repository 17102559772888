import CustomerTransactionsListApi from '../api/CustomerTransactionsListApi';
import { ICustomerTransactionsListRequest } from '../types/ICustomerTransactionsListRequest';
import { ICustomerTransactionsSummary } from '../types/ICustomerTransactionsSummary';

class CustomerTransactionsListService {
  public async getCustomerTransactions(
    request: ICustomerTransactionsListRequest
  ): Promise<ICustomerTransactionsSummary> {
    return await CustomerTransactionsListApi.getCustomerTransactions(request);
  }
  public async getCustomerTransactionsAsFile(request: ICustomerTransactionsListRequest): Promise<Blob> {
    return await CustomerTransactionsListApi.getCustomerTransactionsAsFile(request);
  }
}

export default new CustomerTransactionsListService();
