import * as React from 'react';
import { IComponentTable } from '../../../core/types/IComponentTable';
import { IComponentTableColumn } from '../../../core/types/IComponentTableColumn';
import DateTimeParser from '../../../core/helpers/DateTimeParser';
import { ITransferableInvoice } from '../types/ITransferableInvoice';
import CurrencyParser from '../../../core/helpers/CurrencyParser';
import { Link } from 'react-router-dom';

class TransferInvoicesTableConfigurator {
  public getTableColumns = (casesListTable: IComponentTable<ITransferableInvoice>) => {
    let columns: IComponentTableColumn[] = [];
    if (casesListTable != null) {
      columns = [...casesListTable.columns];
      this.configureDueDateRowDataColumn(columns);
      this.configureReminderDueRowDataColumn(columns);
      this.configureDecimalColumns(columns);
      this.configureInvoiceLinkColumn(columns);

      return columns;
    }
    return columns;
  };

  private configureDueDateRowDataColumn = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'dueDate');
    if (column) {
      column.formatter = this.parseDataRow;
    }
  };

  private configureReminderDueRowDataColumn = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'reminderDueDate');
    if (column) {
      column.formatter = this.parseDataRow;
    }
  };

  private configureInvoiceLinkColumn = (columns: IComponentTableColumn[]) => {
    const column = columns.find((x: IComponentTableColumn) => x.dataField === 'invoiceNo');
    if (column) {
      column.formatter = (value: any, element: ITransferableInvoice) => {
        return (
          <Link
            to={`invoice-details?invoiceNo=${element.invoiceNo}&creditorNo=${element.clientNo}&accountNo=${element.accountNo}`}
            className="font-color-1 font-weight-bold"
          >
            {value}
          </Link>
        );
      };
    }
  };

  private configureDecimalColumns = (columns: IComponentTableColumn[]) => {
    const dataFields = ['amount', 'balance'];
    const cols: IComponentTableColumn[] = columns.filter(
      (x: IComponentTableColumn) => dataFields.indexOf(x.dataField) >= 0
    );

    for (const col of cols) {
      col.formatter = this.decimalAmountRowData;
      col.classes = () => 'bt-table__cell bt-table__cell--text-right';
      col.headerClasses = () => 'bt-table__header bt-table__header--text-right';
    }
  };

  private parseDataRow = (value: any) => DateTimeParser.toLocaleDateString(value);

  private decimalAmountRowData = (value: number) => CurrencyParser.toLocaleString(value);
}

export default new TransferInvoicesTableConfigurator();
